/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
export function Tooltip({
  message, children
}) {
  return (
    <div className="relative flex flex-col items-center group">
      {children}
      <div className="absolute bottom-0 flex flex-col items-center hidden mb-6 group-hover:flex">
        <span className="relative w-auto z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-text-color shadow-sm rounded-sm border border-text-color">{message}</span>
        <div className="w-3 h-3 -mt-2 rotate-45 border border-text-color bg-text-color" />
      </div>
    </div>
  );
}
