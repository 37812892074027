import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageWrapper from '../components/wrappers/PageWrapper';
import AdminSearchContextProvider from '../context/AdminSearch';
import SelectedItemsContextProvider from '../context/SelectedItems';
import BillsPayment from '../pages/admin/bills-payment/BillsPayment';
import CreateSubwallets from '../pages/admin/create-subwallets/CreateSubwallets';

import AdminDashboard from '../pages/admin/Dashboard';
import ManageAccounts from '../pages/admin/manage-accounts/ManageAccounts';
import ManageTransactions from '../pages/admin/manage-transactions/ManageTransactions';
import SendMoney from '../pages/admin/send-money/SendMoney';
import WalletsManagement from '../pages/admin/wallets-management/WalletsManagement';
import SettingsSecurity from '../pages/admin/Settings/SettingsSecurity';
import SettingsProfile from '../pages/admin/Settings/SettingsProfile';
import SettingsDocuments from '../pages/admin/Settings/SettingsDocuments';
import SettingsNotifications from '../pages/admin/Settings/SettingsNotifications';
import SettingsDeveloperKeys from '../pages/admin/Settings/SettingsDeveloperKeys';
import SettingsBusiness from '../pages/admin/Settings/SettingsBusiness';

function AdminRoutes({ Logout }) {
  return (
    <AdminSearchContextProvider>
      <SelectedItemsContextProvider>
        <Routes>
          <Route path="/dashboard" exact element={<PageWrapper logout={Logout}><AdminDashboard /></PageWrapper>} />
          <Route path="/manage-accounts" exact element={<PageWrapper logout={Logout}><ManageAccounts /></PageWrapper>} />
          <Route path="/manage-transactions" exact element={<PageWrapper logout={Logout}><ManageTransactions /></PageWrapper>} />
          <Route path="/wallets-management" exact element={<PageWrapper logout={Logout}><WalletsManagement /></PageWrapper>} />
          <Route path="/create-subwallet" exact element={<PageWrapper logout={Logout}><CreateSubwallets /></PageWrapper>} />
          <Route path="/bills-payment" exact element={<PageWrapper logout={Logout}><BillsPayment /></PageWrapper>} />
          <Route path="/send-money" exact element={<PageWrapper logout={Logout}><SendMoney /></PageWrapper>} />
          <Route path="/settings-profile" exact element={<PageWrapper logout={Logout}><SettingsProfile /></PageWrapper>} />
          <Route path="/settings-business" exact element={<PageWrapper logout={Logout}><SettingsBusiness /></PageWrapper>} />
          <Route path="/settings-security" exact element={<PageWrapper logout={Logout}><SettingsSecurity /></PageWrapper>} />
          <Route path="/settings-documents" exact element={<PageWrapper logout={Logout}><SettingsDocuments /></PageWrapper>} />
          <Route path="/settings-notifications" exact element={<PageWrapper logout={Logout}><SettingsNotifications /></PageWrapper>} />
          <Route path="/settings-developerkeys" exact element={<PageWrapper logout={Logout}><SettingsDeveloperKeys /></PageWrapper>} />
          <Route path="*" element={<PageWrapper logout={Logout}><h1>Page not found</h1></PageWrapper>} />
        </Routes>
      </SelectedItemsContextProvider>
    </AdminSearchContextProvider>
  );
}

AdminRoutes.propTypes = {
  Logout: PropTypes.func
};

AdminRoutes.defaultProps = {
  Logout: () => { }
};

export default AdminRoutes;
