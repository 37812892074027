import { Tab } from '@headlessui/react';
import React, { useState, useEffect } from 'react';
// import { useCookies } from 'react-cookie';
import { useMutation } from 'react-query';
import handleFetch from '../../api/handleFetch';
import CustomTab from '../../components/common/CustomTab';
import Footer from '../../components/common/Footer';
import Loading from '../../components/common/Loading';
import SectionHeader from '../../components/common/SectionHeader';
import SelectInput from '../../components/inputs/SelectInput';
import TextInput from '../../components/inputs/TextInput';
import Button from '../../components/layouts/Button';
import FormHeader from '../../components/layouts/FormHeader';
import bills from '../../data/bills';
import networkProviders from '../../data/networkProviders';
import { formatCurrency, isNumber } from '../../utils/general';
import notification from '../../utils/notification';

function BillsPayment() {
  const [bill, setBill] = useState({ ...bills[0] });
  // const [cookies] = useCookies();

  const [bundleType, setBundleType] = useState({
    label: '',
    value: ''
  });

  const [dataBundleProvider, setDataBundleProvider] = useState({
    label: '',
    value: ''
  });

  const [dataBundles, setDataBundles] = useState([]);

  const [filteredDataBundle, setFilteredDataBundle] = useState([]);

  const [buyAirtimeForm, setBuyAirtimeForm] = useState({
    phoneNumber: '',
    pin: '',
    network: '',
    amount: 0
  });

  const [buyDataBundlesForm, setBuyDataBundlesForm] = useState({
    packageId: 0,
    phoneNumber: '',
    network: '',
    pin: '',
    amount: 0
  });

  // Form Change
  const handleNetworkSelect = (value, name) => {
    setBuyAirtimeForm((state) => ({ ...state, [name]: value }));
  };

  const filterDataBundle = (value) => {
    setDataBundleProvider(value);
    setFilteredDataBundle(
      dataBundles.filter((el) => el.label.includes(value.value))
    );
  };

  const handleBuyAirtimeFormChange = (e) => {
    const { name, value } = e.target;
    setBuyAirtimeForm((state) => ({ ...state, [name]: value }));
  };

  const handleBuyDataBundlesFormChange = (e) => {
    const { name, value } = e.target;
    setBuyDataBundlesForm((state) => ({ ...state, [name]: value }));
  };

  // Validations
  const validateBuyAirtimeForm = () => {
    if (!buyAirtimeForm?.network) return 'Network Provider is required';
    if (!buyAirtimeForm?.phoneNumber) return 'Phone Number is required';
    if (!isNumber(buyAirtimeForm?.phoneNumber)) {
      return 'Phone Number must be numeric only';
    }
    if (
      buyAirtimeForm?.phoneNumber.length !== 11
      && buyAirtimeForm?.phoneNumber.length !== 13
    ) {
      return 'Valid Phone Number is required';
    }
    if (!buyAirtimeForm?.amount || buyAirtimeForm?.amount < 1) {
      return 'Amount is required';
    }
    if (!buyAirtimeForm?.pin) return 'Pin is required';
    if (!isNumber(buyAirtimeForm?.pin) || buyAirtimeForm.pin.length !== 4) {
      return 'Pin must be numeric only and not more than or less than 4 digits';
    }

    return false;
  };

  const validateBuyDataBundleForm = () => {
    if (!bundleType) return 'Select a Data Bundle';

    if (
      buyDataBundlesForm?.phoneNumber.length !== 11
      && buyDataBundlesForm?.phoneNumber.length !== 13
    ) {
      return 'Valid Phone Number is required';
    }

    if (!buyDataBundlesForm?.pin) return 'Pin is required';
    if (!isNumber(buyDataBundlesForm?.pin) || buyDataBundlesForm.pin.length !== 4) {
      return 'Pin must be numeric only and not more than or less than 4 digits';
    }

    return false;
  };

  // Mutations

  const buyAirtimeMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        notification({
          title: 'Success',
          message: res?.message,
          type: 'success'
        });

        setBuyAirtimeForm({
          amount: 0,
          network: '',
          phoneNumber: '',
          pin: ''
        });
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const buyDataBundleMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        notification({
          title: 'Success',
          message: res?.message,
          type: 'success'
        });

        setBuyDataBundlesForm({
          amount: 0,
          network: '',
          packageId: '',
          phoneNumber: '',
          pin: ''
        });
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const getDataBundlesMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        const bundles = res?.data;

        const bundlesBuild = [];

        // value: PackageId , Amount , Network

        bundles.map((y) => bundlesBuild.push({
          label: `${y.billerName} - ${formatCurrency(y.amount)}`,
          value: `${y.packageId}||${y.amount}||${y.network}`
        }));

        setDataBundles(bundlesBuild);

        setFilteredDataBundle(
          dataBundles.filter((el) => el.label.includes(dataBundleProvider.value))
        );
      }
    },
    onError: () => {
      setDataBundles([]);
    }
  });

  // Submit Actions
  const buyAirtime = (e) => {
    e.preventDefault();

    const error = validateBuyAirtimeForm();

    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }

    buyAirtimeMutation.mutate({
      endpoint: 'adminBuyAirtime',
      body: {
        ...buyAirtimeForm,
        amount: +buyAirtimeForm.amount,
        network: buyAirtimeForm?.network?.value
      },
      method: 'POST',
      auth: true
    });
  };

  const buyDataBundle = (e) => {
    e.preventDefault();

    const error = validateBuyDataBundleForm();

    // value: PackageId , Amount , Network
    const extra = bundleType?.value.split('||');

    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }

    buyDataBundleMutation.mutate({
      endpoint: 'adminBuyData',
      body: {
        ...buyDataBundlesForm,
        packageId: +extra[0],
        amount: +extra[1],
        network: extra[2]
      },
      method: 'POST',
      auth: true
    });
  };

  // Use effects
  useEffect(() => {
    getDataBundlesMutation.mutate({
      endpoint: 'adminGetDataBundles',
      auth: true,
      method: 'GET'
    });
  }, []);

  return (
    <>
      {(buyAirtimeMutation.isLoading
        || getDataBundlesMutation.isLoading
        || buyDataBundleMutation.isLoading) && (
        <Loading />
      )}
      <div className="py-10 px-5 w-full md:px-10">
        <SectionHeader title="Bills Payment" />
        <div style={{ minHeight: 'calc(70vh - 72px)' }} className="mt-10">
          <CustomTab hideMenu>
            <Tab.Panel
              className="w-full m-0 flex justify-center items-start"
              style={{ minHeight: 'calc(70vh - 60px)' }}
            >
              <div className="w-full max-w-sm p-5 bg-white rounded-lg">
                <FormHeader header="Bill Payment" />
                <SelectInput
                  className="mb-5"
                  label="Select Bills"
                  name="bill_type"
                  options={bills}
                  value={bill}
                  onChange={(e) => setBill(e)}
                />
                {bill.value === 'AIRTIME' && (
                  <form autoComplete="off" onSubmit={buyAirtime}>
                    <SelectInput
                      className="mb-5"
                      label="Select Network Provider"
                      name="network"
                      options={networkProviders}
                      value={buyAirtimeForm?.network}
                      onChange={(e) => handleNetworkSelect(e, 'network')}
                    />
                    <TextInput
                      className="mb-5"
                      label="Phone Number"
                      name="phoneNumber"
                      value={buyAirtimeForm?.phoneNumber}
                      onChange={handleBuyAirtimeFormChange}
                    />
                    <TextInput
                      className="mb-5"
                      label="Enter Amount"
                      name="amount"
                      type="number"
                      value={buyAirtimeForm?.amount}
                      onChange={handleBuyAirtimeFormChange}
                    />
                    <TextInput
                      className="mb-5"
                      label="Enter Pin"
                      name="pin"
                      value={buyAirtimeForm?.pin}
                      onChange={handleBuyAirtimeFormChange}
                    />
                    <Button type="submit" paddingY="py-3" className="w-full">
                      Continue
                    </Button>
                    <p className="w-full text-primary text-xs text-center pt-3">
                      {/* formatCurrency(accountBalance) */}
                    </p>
                  </form>
                )}
                {bill.value === 'DATABUNDLE' && (
                  <>
                    <SelectInput
                      className="mb-5"
                      label="Select Network Provider"
                      name="dataBundleProviders"
                      options={networkProviders}
                      value={dataBundleProvider}
                      onChange={(e) => filterDataBundle(e)}
                    />

                    <form autoComplete="off" onSubmit={buyDataBundle}>
                      <SelectInput
                        className="mb-5"
                        label="Select Data Bundles"
                        name="network"
                        options={filteredDataBundle}
                        value={bundleType}
                        onChange={(e) => setBundleType(e)}
                      />
                      <TextInput
                        className="mb-5"
                        label="Phone Number"
                        name="phoneNumber"
                        value={buyDataBundlesForm?.phoneNumber}
                        onChange={handleBuyDataBundlesFormChange}
                      />
                      <TextInput
                        className="mb-5"
                        label="Enter Pin"
                        name="pin"
                        value={buyDataBundlesForm?.pin}
                        onChange={handleBuyDataBundlesFormChange}
                      />
                      <Button type="submit" paddingY="py-3" className="w-full">
                        Continue
                      </Button>
                      <p className="w-full text-primary text-xs text-center pt-3">
                        {/* formatCurrency(accountBalance) */}
                      </p>
                    </form>
                  </>
                )}
              </div>
            </Tab.Panel>
          </CustomTab>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default BillsPayment;
