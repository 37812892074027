/* eslint-disable react/no-array-index-key */
import React, { useState, useRef } from 'react';
import { Tab } from '@headlessui/react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { useMutation } from 'react-query';
import { useCookies } from 'react-cookie';
import Footer from '../../../components/common/Footer';
import SectionHeader from '../../../components/common/SectionHeader';
import SelectInput from '../../../components/inputs/SelectInput';
import TextInput from '../../../components/inputs/TextInput';
import Button from '../../../components/layouts/Button';
import FormHeader from '../../../components/layouts/FormHeader';
import CountDown from '../../../components/layouts/CountDown';
import CustomOtpInput from '../../../components/inputs/CustomOtpInput';
import SuccessModal from '../../../components/common/SuccessModal';
import notification from '../../../utils/notification';
import handleFetch from '../../../api/handleFetch';
import Loading from '../../../components/common/Loading';
import securityQuestion from '../../../data/securityQuestion';
import CustomTabWithAction from '../../../components/common/CustomTabWithAction';
import Modal from '../../../components/common/Modal';

const menus = [
  { title: 'Change Password', enableAction: false },
  { title: 'Create Transaction PIN', enableAction: false },
  { title: 'Change Transaction PIN', enableAction: false },
  { title: 'Reset Transaction PIN', enableAction: true },
  { title: 'Change Security Question,', enableAction: true }
];

function SettingsSecurity() {
  const [code, setCode] = useState('');
  const [cookies] = useCookies();
  const [securitycode, setSecurityCode] = useState('');
  const [message, setMessage] = useState('');

  const [showform, setShowForm] = useState(1);
  const [securityForm, setSecurityForm] = useState(1);
  const [currentPassword, setCurrentPassword] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [selectedTab, setSelectedTab] = useState('');

  const [passwordForm, setPasswordForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const [pinForm, setPinForm] = useState({
    pin: '',
    confirmPin: ''
  });

  const [resetPinForm, setResetPinForm] = useState({
    newPin: '',
    confirmPin: ''
  });

  const [changeTransactionPinForm, setChangeTransactionPinForm] = useState({
    securityAnswer: '',
    currentPin: '',
    newPin: '',
    confirmNewPin: ''
  });

  const [changeSecurityQuestionForm, setChangeSecurityQuestionForm] = useState({
    securityQuestion: '',
    securityAnswer: '',
    confirmSecurityAnswer: ''
  });

  const childFunc = useRef(null);

  const handleChange = (otp) => {
    setCode(otp);
  };

  const handleSelect = (value, name) => {
    setChangeSecurityQuestionForm((state) => ({ ...state, [name]: value }));
  };

  const handleChangeSecurityQuestionForm = (e) => {
    const { name, value } = e.target;
    setChangeSecurityQuestionForm((state) => ({ ...state, [name]: value }));
  };

  const handleSecurityChange = (otp) => {
    setSecurityCode(otp);
  };

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordForm((state) => ({ ...state, [name]: value }));
  };

  const handleCreatePinChange = (e) => {
    const { name, value } = e.target;
    setPinForm((state) => ({ ...state, [name]: value }));
  };

  const handlePinChange = (e) => {
    const { name, value } = e.target;
    setResetPinForm((state) => ({ ...state, [name]: value }));
  };

  const handleTransactionPinChange = (e) => {
    const { name, value } = e.target;
    setChangeTransactionPinForm((state) => ({ ...state, [name]: value }));
  };

  const validatePassword = () => {
    if (!passwordForm?.currentPassword) return 'Current password is required';
    if (!passwordForm?.newPassword) return 'New password is required';
    if (passwordForm?.currentPassword === passwordForm?.newPassword) {
      return 'Current password cannot be the same as new password';
    }
    if (!passwordForm?.confirmPassword) return 'Confirm password is required';
    if (passwordForm?.newPassword !== passwordForm?.confirmPassword) {
      return 'Passwords do not match';
    }
    return false;
  };

  const validateTransactionPinChange = () => {
    if (!changeTransactionPinForm?.securityAnswer) {
      return 'Answer for security Question is required';
    }
    if (!changeTransactionPinForm?.currentPin) return 'Current Pin is required';
    if (!changeTransactionPinForm?.newPin) return 'New Pin is required';
    if (
      changeTransactionPinForm?.currentPin === changeTransactionPinForm?.newPin
    ) {
      return 'Current pin cannot be the same as new pin';
    }
    if (!changeTransactionPinForm?.confirmNewPin) {
      return 'Confirm Pin is required';
    }
    if (passwordForm?.newPin !== passwordForm?.confirmNewPin) {
      return 'Pin do not match';
    }
    return false;
  };

  const validateChangeSecurityQuestion = () => {
    if (!changeSecurityQuestionForm?.securityQuestion) {
      return 'Security Question is required';
    }
    if (!changeSecurityQuestionForm?.securityAnswer) {
      return 'Answer for security Question is required';
    }
    if (!changeSecurityQuestionForm?.confirmSecurityAnswer) {
      return 'Confirm Security Quesion Answer is required';
    }
    if (
      changeSecurityQuestionForm?.securityAnswer
      !== changeSecurityQuestionForm?.confirmSecurityAnswer
    ) {
      return 'Answers do not match';
    }
    return false;
  };

  const validatePinreset = () => {
    if (!resetPinForm?.newPin) return 'New pin is required';
    if (!resetPinForm?.confirmPin) return 'Confirm pin is required';
    if (
      resetPinForm?.newPin.length !== 4
      || resetPinForm?.confirmPin.length !== 4
    ) {
      return 'Pin must be numeric only and not more than or less than 4 digits';
    }
    if (resetPinForm?.newPin !== resetPinForm?.confirmPin) {
      return 'Pin do not match';
    }
    return false;
  };

  const validateCreatePin = () => {
    if (!pinForm?.pin) return 'Pin is required';
    if (!pinForm?.confirmPin) return 'Confirm pin is required';
    if (pinForm?.pin.length !== 4 || pinForm?.confirmPin.length !== 4) {
      return 'Pin must be numeric only and not more than or less than 4 digits';
    }
    if (pinForm?.pin !== pinForm?.confirmPin) return 'Pin do not match';
    return false;
  };

  // TODO: Add regex check for numbers
  const changePasswordMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        setIsOpen(!isOpen);

        setMessage(res?.message);

        setPasswordForm({
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
        });
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const changeSecurityQuestionMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        setIsOpen(!isOpen);

        setMessage(res?.message);

        setChangeSecurityQuestionForm({
          confirmSecurityAnswer: '',
          securityAnswer: '',
          securityQuestion: ''
        });
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const createPinMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        setIsOpen(!isOpen);

        setMessage(res?.message);

        setPinForm({
          pin: '',
          confirmPin: ''
        });
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const changeTransactionPinMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        setIsOpen(!isOpen);

        setMessage(res?.message);

        setChangeTransactionPinForm({
          securityAnswer: '',
          currentPin: '',
          newPin: '',
          confirmNewPin: ''
        });
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const initiatePinresetMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      notification({
        title: 'Success',
        message: res?.message || 'Otp has been sent to your email',
        type: 'success'
      });
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const initiateChangeSecurityQuestionMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      notification({
        title: 'Success',
        message: res?.message || 'Otp has been sent to your email',
        type: 'success'
      });
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const validatePinresetOtpMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      setCode('');

      notification({
        title: 'Success',
        message: res?.message || 'Otp successfully verified',
        type: 'success'
      });

      setShowForm(showform + 1);
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const validateSecurityOtpMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      setCode('');

      notification({
        title: 'Success',
        message: res?.message || 'Otp successfully verified',
        type: 'success'
      });

      setSecurityForm(securityForm + 1);
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const verifyPasswordMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      notification({
        title: 'Success',
        message: res?.message || 'Password successfully verified',
        type: 'success'
      });
      setShowForm(showform + 1);
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const resetTransactionPinMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        setIsOpen(!isOpen);

        setMessage(res?.message);

        setResetPinForm({
          confirmPin: '',
          newPin: ''
        });

        setShowForm(1);
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const resendOtp = () => {
    initiatePinresetMutation.mutate({
      endpoint: 'initiatePinreset',
      method: 'GET',
      auth: true
    });

    childFunc.current();
  };

  const initiateChangeSecurityQuestion = () => {
    initiateChangeSecurityQuestionMutation.mutate({
      endpoint: 'initiateChangeSecurityQuestion',
      method: 'GET',
      auth: true
    });

    childFunc.current();
  };

  const changePassword = (e) => {
    e.preventDefault();

    const error = validatePassword();

    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }

    changePasswordMutation.mutate({
      endpoint: 'changePassword',
      body: {
        ...passwordForm
      },
      method: 'POST',
      auth: true
    });
  };

  const createPin = (e) => {
    e.preventDefault();

    const error = validateCreatePin();

    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }

    createPinMutation.mutate({
      endpoint: 'setTransactionPin',
      body: {
        ...pinForm
      },
      method: 'POST',
      auth: true
    });
  };

  const changeTransactionPin = (e) => {
    e.preventDefault();

    const error = validateTransactionPinChange();

    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }

    changeTransactionPinMutation.mutate({
      endpoint: 'changeTransactionPin',
      body: {
        ...changeTransactionPinForm
      },
      method: 'POST',
      auth: true
    });
  };

  const submitSecurityQuestion = (e) => {
    e.preventDefault();

    const error = validateChangeSecurityQuestion();

    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }

    changeSecurityQuestionMutation.mutate({
      endpoint: 'changeSecurityQuestion',
      body: {
        ...changeSecurityQuestionForm,
        securityQuestion: changeSecurityQuestionForm?.securityQuestion?.value
      },
      method: 'POST',
      auth: true
    });
  };

  const submitOtp = (e) => {
    e.preventDefault();

    if (!code && code?.length !== 4) {
      notification({
        title: 'Form Error',
        message: 'Please fill in Otp code',
        type: 'danger'
      });
      return;
    }

    validatePinresetOtpMutation.mutate({
      endpoint: 'validatePinresetOtp',
      method: 'GET',
      param: code,
      auth: true
    });
  };

  const submitSecurityOtp = (e) => {
    e.preventDefault();

    if (!securitycode && securitycode?.length !== 4) {
      notification({
        title: 'Form Error',
        message: 'Please fill in Otp code',
        type: 'danger'
      });
      return;
    }

    validateSecurityOtpMutation.mutate({
      endpoint: 'validatePinresetOtp',
      method: 'GET',
      param: securitycode,
      auth: true
    });
  };

  const verifyPassword = (e) => {
    e.preventDefault();

    if (!currentPassword) {
      notification({
        title: 'Form Error',
        message: 'Current Password is required',
        type: 'danger'
      });
      return;
    }

    verifyPasswordMutation.mutate({
      endpoint: 'verifyPassword',
      method: 'GET',
      param: currentPassword,
      auth: true
    });
  };

  const resetTransactionPin = (e) => {
    e.preventDefault();

    const error = validatePinreset();

    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }

    resetTransactionPinMutation.mutate({
      endpoint: 'resetPin',
      body: {
        ...resetPinForm
      },
      method: 'POST',
      auth: true
    });
  };

  return (
    <>
      {(changePasswordMutation.isLoading
        || initiatePinresetMutation.isLoading
        || validatePinresetOtpMutation.isLoading
        || verifyPasswordMutation.isLoading
        || resetTransactionPinMutation.isLoading
        || createPinMutation.isLoading
        || changeTransactionPinMutation.isLoading
        || initiateChangeSecurityQuestionMutation.isLoading
        || validateSecurityOtpMutation.isLoading
        || changeSecurityQuestionMutation.isLoading) && <Loading />}
      <div className="py-10 px-5 w-full md:px-10">
        <SectionHeader title="Security Settings" />
        <div style={{ minHeight: 'calc(70vh - 72px)' }} className="mt-10">
          <CustomTabWithAction
            menus={menus}
            clickAction={(e) => {
              setSelectedTab(e.target.name);
              setIsOtp(true);
            }}
          >
            <Tab.Panel
              className="w-full h-full m-0 flex justify-center items-start"
              style={{ minHeight: 'calc(70vh - 60px)' }}
            >
              <div className="w-full max-w-sm p-5 bg-white rounded-lg">
                <FormHeader header={menus[0].title} />
                <form onSubmit={changePassword}>
                  <TextInput
                    className="mb-5"
                    label="Current Password"
                    type="password"
                    name="currentPassword"
                    value={passwordForm?.currentPassword}
                    onChange={handlePasswordChange}
                  />
                  <TextInput
                    className="mb-5"
                    label="Create New Password"
                    type="password"
                    name="newPassword"
                    value={passwordForm?.newPassword}
                    onChange={handlePasswordChange}
                  />
                  <TextInput
                    className="mb-5"
                    label="Confirm New Password"
                    name="confirmPassword"
                    type="password"
                    value={passwordForm?.confirmPassword}
                    onChange={handlePasswordChange}
                  />
                  <Button type="submit" paddingY="py-3" className="w-full">
                    Submit
                  </Button>
                </form>
              </div>
            </Tab.Panel>
            <Tab.Panel
              className="w-full h-full m-0 flex justify-center items-start"
              style={{ minHeight: 'calc(70vh - 60px)' }}
            >
              <div className="w-full max-w-sm p-5 bg-white rounded-lg">
                <FormHeader header={menus[1].title} />
                <form onSubmit={createPin}>
                  <TextInput
                    className="mb-5"
                    label="Create Pin"
                    type="password"
                    name="pin"
                    value={pinForm?.pin}
                    onChange={handleCreatePinChange}
                  />
                  <TextInput
                    className="mb-5"
                    label="Confirm Pin"
                    type="password"
                    name="confirmPin"
                    value={pinForm?.confirmPin}
                    onChange={handleCreatePinChange}
                  />
                  <Button type="submit" paddingY="py-3" className="w-full">
                    Submit
                  </Button>
                </form>
              </div>
            </Tab.Panel>
            <Tab.Panel
              className="w-full h-full m-0 flex justify-center items-start"
              style={{ minHeight: 'calc(70vh - 60px)' }}
            >
              <div className="w-full max-w-sm p-5 bg-white rounded-lg">
                <FormHeader header={menus[2].title} />
                <form onSubmit={changeTransactionPin}>
                  <TextInput
                    className="mb-5"
                    label="Provide answer to security Question"
                    name="securityAnswer"
                    value={changeTransactionPinForm?.securityAnswer}
                    onChange={handleTransactionPinChange}
                  />
                  <TextInput
                    className="mb-5"
                    label="Enter Current PIN"
                    name="currentPin"
                    value={changeTransactionPinForm?.currentPin}
                    onChange={handleTransactionPinChange}
                  />
                  <TextInput
                    className="mb-5"
                    label="Create New PIN"
                    name="newPin"
                    value={changeTransactionPinForm?.newPin}
                    onChange={handleTransactionPinChange}
                  />
                  <TextInput
                    className="mb-5"
                    label="Confirm New PIN"
                    name="confirmNewPin"
                    value={changeTransactionPinForm?.confirmNewPin}
                    onChange={handleTransactionPinChange}
                  />
                  <Button type="submit" paddingY="py-3" className="w-full">
                    Submit
                  </Button>
                </form>
              </div>
            </Tab.Panel>
            <Tab.Panel
              className="w-full h-full m-0 flex justify-center items-start"
              style={{ minHeight: 'calc(70vh - 60px)' }}
            >
              <div className="w-full max-w-sm p-5 bg-white rounded-lg">
                <FormHeader header={menus[3].title} />
                {showform === 1 && (
                  <form onSubmit={submitOtp}>
                    <div className="flex flex-col gap-3 justify-center items-center mb-4">
                      <p className="text-xs border border-primary text-text-color rounded-full py-2 px-4 text-center bg-light-primary w-2/3 mb-2">
                        Enter the OTP Received
                      </p>
                      <div className="flex justify-center">
                        <CustomOtpInput value={code} onchange={handleChange} />
                        <div className="w-10 h-10 flex justify-center items-center">
                          {code.length === 4 && (
                            <BsCheckCircleFill className="text-bg-green text-base" />
                          )}
                        </div>
                      </div>
                      <CountDown childFunc={childFunc} />
                    </div>
                    <Button type="submit" paddingY="py-3" className="w-full">
                      Submit
                    </Button>
                    <button
                      type="button"
                      className="w-full text-text-color text-xs text-center pt-6"
                      onClick={resendOtp}
                    >
                      Resend OTP
                    </button>
                  </form>
                )}
                {showform === 2 && (
                  <form onSubmit={verifyPassword}>
                    <TextInput
                      className="mb-5"
                      label="Enter your current password"
                      type="password"
                      name="currentPassword"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                    <Button type="submit" paddingY="py-3" className="w-full">
                      Submit
                    </Button>
                  </form>
                )}
                {showform === 3 && (
                  <form onSubmit={resetTransactionPin}>
                    <TextInput
                      className="mb-5"
                      label="Create new PIN"
                      name="newPin"
                      value={resetPinForm.newPin}
                      onChange={handlePinChange}
                    />
                    <TextInput
                      className="mb-5"
                      label="Confirm new PIN"
                      name="confirmPin"
                      value={resetPinForm.confirmPin}
                      onChange={handlePinChange}
                    />
                    <Button type="submit" paddingY="py-3" className="w-full">
                      Submit
                    </Button>
                  </form>
                )}
              </div>
            </Tab.Panel>
            <Tab.Panel
              className="w-full m-0 flex justify-center items-start"
              style={{ minHeight: 'calc(70vh - 60px)' }}
            >
              <div className="w-full max-w-sm p-5 bg-white rounded-lg h-full">
                <FormHeader header={menus[4].title} />
                {securityForm === 1 && (
                  <form onSubmit={submitSecurityOtp}>
                    <div className="flex flex-col gap-3 justify-center items-center mb-4">
                      <p className="text-xs border border-primary text-text-color rounded-full py-2 px-4 text-center bg-light-primary w-2/3 mb-2">
                        Enter the OTP Received
                      </p>
                      <div className="flex justify-center">
                        <CustomOtpInput
                          value={securitycode}
                          onchange={handleSecurityChange}
                        />
                        <div className="w-10 h-10 flex justify-center items-center">
                          {securitycode.length === 4 && (
                            <BsCheckCircleFill className="text-bg-green text-base" />
                          )}
                        </div>
                      </div>
                      <CountDown childFunc={childFunc} />
                    </div>
                    <Button type="submit" paddingY="py-3" className="w-full">
                      Submit
                    </Button>
                    <button
                      type="button"
                      className="w-full text-text-color text-xs text-center pt-6"
                      onClick={initiateChangeSecurityQuestion}
                    >
                      Resend OTP
                    </button>
                  </form>
                )}
                {securityForm === 2 && (
                  <form onSubmit={submitSecurityQuestion}>
                    <SelectInput
                      className="mb-5"
                      label="Select Security Question"
                      name="securityQuestion"
                      value={changeSecurityQuestionForm?.securityQuestion}
                      options={securityQuestion}
                      onChange={(e) => handleSelect(e, 'securityQuestion')}
                    />
                    <TextInput
                      className="mb-5"
                      label="Answer"
                      name="securityAnswer"
                      value={changeSecurityQuestionForm?.securityAnswer}
                      onChange={handleChangeSecurityQuestionForm}
                    />
                    <TextInput
                      className="mb-5"
                      label="Confirm Answer"
                      name="confirmSecurityAnswer"
                      value={changeSecurityQuestionForm?.confirmSecurityAnswer}
                      onChange={handleChangeSecurityQuestionForm}
                    />
                    <Button type="submit" paddingY="py-3" className="w-full">
                      Submit
                    </Button>
                  </form>
                )}
              </div>
            </Tab.Panel>
          </CustomTabWithAction>
        </div>
        <Footer />
        {isOpen && (
          <SuccessModal
            onClose={openModal}
            title={cookies?.data?.userDetail?.businessname}
            message={message}
          />
        )}
        {isOtp && (
          <Modal onClose={() => setIsOtp(false)}>
            <div className="text-center p-4">
              <FormHeader header="Do you wish to proceed?" />
              <p className="mb-6 text-text-color">
                Choose
                {' '}
                <span className="font-black">Yes</span>
                {' '}
                to receive OTP for
                processing request
              </p>
              <div className="flex flex-col gap-6 mb-5 lg:flex-row-reverse">
                <Button
                  type="button"
                  paddingY="py-3"
                  className="w-full lg:w-1/2"
                  onClick={() => {
                    if (selectedTab === menus[3].title) {
                      resendOtp();
                    } else {
                      initiateChangeSecurityQuestion();
                    }

                    setIsOtp(false);
                  }}
                >
                  Yes
                </Button>
                <Button
                  type="button"
                  paddingY="py-3"
                  className="w-full bg-card-red lg:w-1/2"
                  onClick={() => setIsOtp(false)}
                >
                  No
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}

export default SettingsSecurity;
