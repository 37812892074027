import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BsFillCaretDownFill } from 'react-icons/bs';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import '../../assets/styles/sidebar.scss';

function Header({ username }) {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = (status) => {
    setShowMenu(status);

    const menu = document.getElementsByClassName('page-sidebar');

    if (status) {
      menu[0].classList.add('showMenu');
    } else {
      menu[0].classList.remove('showMenu');
    }
  };

  return (
    <header className="fixed z-40 w-full bg-white shadow-header">
      <div className="max-w-8xl mx-auto">
        <div className="w-full h-20 px-5 flex justify-between items-center">
          <div className="flex gap-4 items-center">
            <h1 className="font-black text-base md:text-lg">
              <span className="text-primary">SourceOne</span>
              &nbsp;Finance Manager
            </h1>
          </div>

          <div className="text-base flex">
            <div className="profile-box">
              Welcome&nbsp;
              <span className="font-extrabold flex items-center">
                {username}
                <BsFillCaretDownFill className="text-primary ml-2" />
              </span>
            </div>
            <div className="hamburger">
              {showMenu ? (
                <button
                  type="button"
                  className="border p-2 hover:text-primary"
                  onClick={() => toggleMenu(false)}
                >
                  <AiOutlineCloseCircle className="text-lg text-primary" />
                </button>
              ) : (
                <button
                  type="button"
                  className="border p-2 hover:text-primary"
                  onClick={() => toggleMenu(true)}
                >
                  <GiHamburgerMenu className="text-lg text-primary" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  username: PropTypes.string
};

Header.defaultProps = {
  username: ''
};

export default Header;
