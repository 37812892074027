import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import { HiOutlineChevronLeft } from 'react-icons/hi';

import Footer from '../../components/common/Footer';
import Loading from '../../components/common/Loading';
import SignupSuccess from '../../components/layouts/SignupSuccess';
import BusinessForm from './BusinessForm';
import ProfileForm from './ProfileForm';
import notification from '../../utils/notification';
import handleFetch from '../../api/handleFetch';

function Index() {
  const [success, setSuccess] = useState(false);
  const [form, setForm] = useState({ profileType: 2 });
  const [formCount, setFormCount] = useState(0);
  const [otherCategory, setOtherCategory] = useState({
    label: '',
    value: ''
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Financial Manager - Signup';
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((state) => ({ ...state, [name]: value }));
  };

  const handleSelect = (value, name) => {
    setForm((state) => ({ ...state, [name]: value }));
  };

  const handleOtherCategory = (value) => {
    setOtherCategory(value);
  };

  const loginMutation = useMutation(handleFetch, {
    onSuccess: () => {
      setSuccess(true);
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const validateProfile = () => {
    if (!form?.email) return 'Email address is required';
    if (!/^([a-zA-Z0-9_\-&.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,10})$/.test(form?.email)) {
      return 'Please enter a valid email';
    }
    if (!form?.password) return 'Password is required';
    if (form?.password?.length < 8) return 'Password must contain 8 or more characters';
    if (form?.password !== form?.confirmPassword) return 'Password mismatch';
    if (!form?.bvn) return 'BVN is required';
    if (!/^([0-9]{11})$/.test(form?.bvn)) {
      return 'Please enter a valid BVN';
    }
    if (!form?.securityQuestion?.value) return 'Security question is required';
    if (!form?.securityAnswer) return 'Security question answer is required';
    return false;
  };

  const processProfile = (e) => {
    e.preventDefault();
    const error = validateProfile();
    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }
    setFormCount(1);
  };

  const validateBusiness = () => {
    if (!form?.firstName) return 'First name is required';
    if (!form?.lastName) return 'Last name is required';
    if (!form?.businessname) return 'Business name is required';
    if (!form?.rcNumber) return 'RC Number is required';
    if (!form?.businessCategory?.value) return 'Business Category is required';
    if (form.businessCategory?.value === 'Others' && !otherCategory.value) return 'Other Business Category is required';
    if (!form?.businessType?.value) return 'Business type is required';
    if (!form?.phoneNumber) return 'Phone number is required';
    if (!form?.contactAddress) return 'Contact address is required';
    if (!form?.state?.value) return 'State is required';
    if (!form?.lga?.value) return 'L.G.A is required';
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validateBusiness();
    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }
    loginMutation.mutate({
      endpoint: 'signup',
      body: {
        ...form,
        securityQuestion: form?.securityQuestion?.value,
        businessCategory: form?.businessCategory?.value === 'Others' ? otherCategory.value : form?.businessCategory?.value,
        businessType: form?.businessType?.value,
        state: form?.state?.value,
        lga: form?.lga?.value
      },
      method: 'POST',
      auth: false
    });
  };

  const { isLoading } = loginMutation;

  return (
    <div className="w-full bg-main-bg">
      {isLoading && <Loading />}

      <div style={{ minHeight: 'calc(100vh - 72px)' }} className="w-full flex justify-center items-center pt-16 pb-10 px-5">
        <div className="w-full max-w-md">
          {formCount === 1 && (
            <button
              type="button"
              onClick={() => setFormCount(0)}
              className="flex items-center text-sm mb-1 hover:text-primary"
            >
              <HiOutlineChevronLeft className="mr-1.5" />
              Back
            </button>
          )}
          <div className="w-full p-5 bg-white rounded-lg">
            {formCount === 0 && (
              <ProfileForm
                form={form}
                handleChange={handleChange}
                handleSelect={handleSelect}
                handleSubmit={processProfile}
              />
            )}

            {formCount === 1 && (
              <BusinessForm
                form={form}
                otherCategory={otherCategory}
                handleChange={handleChange}
                handleSelect={handleSelect}
                handleSubmit={handleSubmit}
                handleOtherCategory={handleOtherCategory}
              />
            )}

            <div className="flex justify-center text-xs text-text-color">
              <Link to="/">Already have an Account?</Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      {success && <SignupSuccess name={form?.businessname} />}
    </div>
  );
}

export default Index;
