import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Link, useParams, useNavigate } from 'react-router-dom';
import handleFetch from '../../api/handleFetch';
import Footer from '../../components/common/Footer';
import Loading from '../../components/common/Loading';
import FormHeader from '../../components/layouts/FormHeader';
import notification from '../../utils/notification';
import Clap from '../../assets/svgs/clap.svg';

function VerifyEmail() {
  const navigate = useNavigate();

  const { userId } = useParams();
  const [isVerified, setIsVerified] = useState(false);

  if (userId.length < 1 || userId == null) {
    navigate('/');
  }

  // FormChange

  // Validations

  // Mutations

  const verifyEmailMutation = useMutation(handleFetch, {
    onSuccess: () => {
      setIsVerified(true);
    },
    onError: (err) => {
      console.log(err);
      notification({
        title: 'Verification Failed',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });

      setIsVerified(false);
    }
  });

  // Submit Actions

  useEffect(() => {
    verifyEmailMutation.mutate({
      endpoint: 'verifyEmail',
      method: 'GET',
      param: userId
    });
  }, []);

  return (
    <div className="w-full h-screen bg-main-bg fixed top-0 left-0 overlay z-40 overflow-auto">
      {verifyEmailMutation.isLoading && <Loading />}
      <div
        style={{ minHeight: 'calc(100vh - 72px)' }}
        className="w-full flex justify-center items-center p-5 pt-10 relative"
      >
        {!verifyEmailMutation.isLoading && (
          <div className="w-full max-w-sm p-5 bg-white rounded-lg">
            {isVerified ? (
              <div className="text-center p-4">
                <div className="flex justify-center w-full mb-6">
                  <img src={Clap} alt="" className="text-lg w-14 h-14" />
                </div>
                <FormHeader header="Email Verification" />
                <p className="mb-6 text-text-color">
                  Your email has been verified successfully, kindly proceed to
                  login
                </p>
                <Link
                  type="button"
                  className="mt-6 w-full py-3 rounded-sm bg-primary text-white hover:bg-opacity-70"
                  to="/"
                >
                  Login
                </Link>
              </div>
            ) : (
              <div>
                <FormHeader header="Email Verification" />
                <p className="mb-6 text-text-color text-center">
                  Unfortunately your email could not be verified. Kindly login
                  to the portal and reinitiate verification process via the
                  dashboard
                </p>

                <Link
                  type="button"
                  className="mb-8 mt-4 text-center w-full py-3 rounded-sm bg-primary text-white hover:bg-opacity-70"
                  to="/"
                >
                  Login
                </Link>

                <div className="flex justify-between text-xs text-text-color">
                  <Link to="/signup">New to this Admin Portal?</Link>
                  <Link to="/">Return Home</Link>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default VerifyEmail;
