export default [
  { label: 'Who is your father\'s best friend', value: 'Who is your father\'s best friend' },
  { label: 'Who is your favourite author', value: 'Who is your favourite author' },
  { label: 'Who is your favourite musician', value: 'Who is your favourite musician' },
  { label: 'Name of School', value: 'Name of school' },
  { label: 'Mother\'s maiden name', value: 'Mother\'s maiden name' },
  { label: 'What primary school did you attend?', value: 'What primary school did you attend' },
  { label: 'What is your house address number and street name', value: '' },
  { label: 'Your pet\'s name', value: 'Your pet\'s name' },
  { label: 'Name of favourite lecturer', value: 'Name of favourite lecturer' },
  { label: 'What\'s your nickname', value: 'What\'s your nickname' }
];
