/* eslint-disable react/no-children-prop */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import {
  IoIosArrowDown,
  IoIosArrowForward,
  IoMdArrowDropright
} from 'react-icons/io';
// import { IoClose } from 'react-icons/io5';
import { FiMenu } from 'react-icons/fi';
import { HiOutlineLogout } from 'react-icons/hi';
import { VscDebugStackframeDot } from 'react-icons/vsc';
import '../../assets/styles/sidebar.scss';

import menuList from '../../configs/sidebarMenu';
import superadminMenu from '../../configs/superadminMenu';
import useClickOutsideBox from '../../hooks/useClickOutsideBox';
import useGetQuery from '../../hooks/useGetQuery';

function Sidebar({ logout }) {
  const wrapperRef = useRef(null);
  const [cookies] = useCookies();
  const [showMenu, setShowMenu] = useState(false);

  useClickOutsideBox(wrapperRef, () => setShowMenu(false));

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div ref={wrapperRef}>
      <div
        className={`${
          showMenu ? 'hidden' : ''
        } fixed cursor-pointer top-3 left-6 z-30 lg:hidden`}
      >
        <FiMenu
          onClick={toggleMenu}
          className="transition bg-white w-10 h-auto p-1.5 border rounded-md text-gray-900 hover:text-white hover:bg-primary"
        />
      </div>

      <nav
        className={`pb-20 z-30 page-sidebar fixed w-60 bg-white overflow-auto h-screen ${
          showMenu ? 'show' : ''
        }`}
      >
        {/*
          <div className="fixed cursor-pointer top-3 left-44 lg:hidden">
            <IoClose
              onClick={toggleMenu}
              className="transition w-10 h-auto p-1.5 rounded-md border text-white hover:bg-white hover:bg-opacity-20"
            />
          </div>
        */}
        <div className="sidebar-menu text-text-color text-sm flex flex-col justify-between pb-10">
          <ul className="menu-items">
            {cookies?.data?.userDetail?.profileType === 'Admin'
              ? superadminMenu.map((item) => (
                <MenuItem
                  props={item}
                  toggleMenu={toggleMenu}
                  key={item.title}
                  children={[item.children]}
                />
              ))
              : menuList?.map((item) => (
                <MenuItem
                  props={item}
                  toggleMenu={toggleMenu}
                  key={item.title}
                  children={[item.children]}
                />
              ))}
            <li className="cursor-pointer" onClick={logout} role="presentation">
              <HiOutlineLogout />
              <span className="title">Logout</span>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

function MenuItem({
  props: {
    title, href, link, icon, children, childrenBase
  },
  toggleMenu
}) {
  const { pathname } = useLocation();
  const [isShowingSub, setIsShowingSub] = useState(false);

  const { data, status } = useGetQuery({
    queryKey: ['products'],
    endpoint: 'product',
    enabled: !!childrenBase
  });

  return (
    <div className="relative">
      {children || childrenBase ? (
        <li
          className={`${
            pathname === link ? 'active' : ''
          } justify-between cursor-pointer`}
          onClick={() => setIsShowingSub(!isShowingSub)}
          role="presentation"
        >
          <span className="flex">
            {icon}
            <span
              className={children || childrenBase ? 'has-sub-menu pointer' : ''}
            >
              <span className="title">{title}</span>
            </span>
          </span>
          {isShowingSub ? <IoIosArrowDown /> : <IoIosArrowForward />}
        </li>
      ) : (
        <>
          {href && (
            <a href={href} target="_blank" rel="noreferrer">
              <li className="">
                {icon}
                <span className="title">{title}</span>
              </li>
            </a>
          )}
          {link && (
            <Link to={link} onClick={toggleMenu}>
              <li className={`${pathname?.includes(link) ? 'active' : ''} `}>
                {icon}
                <span className="title">{title}</span>
              </li>
            </Link>
          )}
        </>
      )}

      {children && isShowingSub && (
        <ul className={`submenu ${isShowingSub ? 'show' : ''} list-disc`}>
          {children?.map((child) => (
            <Link to={child?.link} onClick={toggleMenu} key={child?.title}>
              <li className={`${pathname === child?.link ? 'active' : ''} `}>
                <IoMdArrowDropright />
                {child?.title}
              </li>
            </Link>
          ))}
        </ul>
      )}

      {childrenBase && isShowingSub && (
        <ul className={`${isShowingSub ? 'show' : ''} list-disc`}>
          {childrenBase && status === 'loading' && <li>Loading...</li>}
          {data?.data?.map((child) => (
            <Link
              to={`${childrenBase}/${child?.slug}`}
              onClick={toggleMenu}
              key={child?.id}
            >
              <li
                className={`${
                  pathname === `${childrenBase}/${child?.slug}` ? 'active' : ''
                } `}
              >
                <VscDebugStackframeDot />
                {child?.name}
              </li>
            </Link>
          ))}
        </ul>
      )}
    </div>
  );
}

MenuItem.propTypes = {
  props: PropTypes.shape({
    title: PropTypes.string,
    href: PropTypes.string,
    link: PropTypes.string,
    childrenBase: PropTypes.string,
    icon: PropTypes.node,
    children: PropTypes.arrayOf(
      PropTypes.shape({ title: PropTypes.string, link: PropTypes.string })
    )
  }),
  toggleMenu: PropTypes.func
};

MenuItem.defaultProps = {
  props: {
    title: '',
    href: '',
    link: '',
    childrenBase: '',
    icon: '',
    children: [{ title: '', link: '' }]
  },
  toggleMenu: () => {}
};

export default Sidebar;
