import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineUpload } from 'react-icons/ai';

function CustomFileUpload({
  onSubmit, onChange, className, label, name, btnLabel, isRequired
}) {
  const openFile = () => {
    document.getElementById(name).click();
  };
  return (
    <div className={`${className} mb-5`}>
      <label htmlFor={name} className="block text-xs text-text-color border-b border-border-color pb-1.5">
        {label}
        {isRequired && (
          <span className="text-card-red">&#42; </span>
        )}
      </label>
      <form onSubmit={onSubmit}>
        <div className="py-3 relative">
          <input id={name} name={name} type="file" onChange={onChange} className="block text-exs w-full file:mr-4 file:py-0.5 file:px-3 file:rounded-sm file:border-0 file:text-exs file:bg-transparent file:border-0 hover:file:bg-blue-100 file:mr-20" />

          <button type="button" className="absolute bottom-2 px-3 py-1 bg-light-primary border border-primary rounded-sm text-text-color" onClick={openFile}>
            <div className="flex justify-center items-center gap-2">
              <AiOutlineUpload className="text-primary text-lg" />
              {' '}
              <span className="text-exs">{btnLabel || 'Upload Document' }</span>
            </div>
          </button>
        </div>
        <div className="text-right">
          <button type="submit" className="px-3 py-1 bg-primary border border-primary rounded-sm text-white hover:bg-opacity-70">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

CustomFileUpload.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  btnLabel: PropTypes.string,
  isRequired: PropTypes.bool
};

CustomFileUpload.defaultProps = {
  onChange: () => { },
  onSubmit: () => { },
  className: '',
  label: '',
  name: '',
  btnLabel: '',
  isRequired: false
};

export default CustomFileUpload;
