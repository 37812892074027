import React from 'react';
import PropTypes from 'prop-types';

function TableLight({ children, headers, headerStyle }) {
  return (
    <table className="table w-full overflow-x-auto overflow-x-contain" style={{ borderRadius: '2rem' }}>
      <thead className="bg-white">
        <tr className="text-left font-arial text-text-color font-black">
          {headers.map((header) => (
            <th className={`border-b-2 border-b-main-bg py-4 pl-4 ${headerStyle}`}>{ header }</th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white dark:bg-slate-800">
        {children}
      </tbody>
    </table>
  );
}

TableLight.propTypes = {
  children: PropTypes.node,
  headers: PropTypes.arrayOf(PropTypes.string),
  headerStyle: PropTypes.string
};

TableLight.defaultProps = {
  children: 'Table',
  headers: '',
  headerStyle: ''
};

export default TableLight;
