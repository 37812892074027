/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, Fragment } from 'react';
import { Disclosure, Transition, Popover } from '@headlessui/react';
import {
  FiPlus,
  FiRefreshCw,
  FiEdit2,
  FiTrash2,
  FiSearch
} from 'react-icons/fi';
import ModalButton from '../../../components/layouts/ModalButton';
import Table from '../../../components/common/Table';
import Modal from '../../../components/common/Modal';
import TextInput from '../../../components/inputs/TextInput';
import SelectInput from '../../../components/inputs/SelectInput';
import Button from '../../../components/layouts/Button';
import CheckBox from '../../../components/inputs/CheckBox';
import states from '../../../data/nigeriaStates';
import getLga from '../../../data/lgaData';
import SectionHeader from '../../../components/common/SectionHeader';
import Footer from '../../../components/common/Footer';
import FormHeader from '../../../components/layouts/FormHeader';
import businessType from '../../../data/businessType';
import businessCategory from '../../../data/businessCategory';

const options = [
  { id: 0, name: 'Update' },
  { id: 1, name: 'Reactivate' },
  { id: 2, name: 'Deactivate' }
];

const access = [
  'Access Management', 'Transaction Management', 'Wallet Management',
  'Bills Payment', 'Reports', 'Settings'
];

const users = [
  {
    id: 0,
    firstname: 'Julia',
    lastname: 'Ramsay',
    email: 'julia.ramsay@ekofasttech.com',
    lastUpdated: '20:11:2020/14:05:23PM'
  },
  {
    id: 1,
    firstname: 'Norvel',
    lastname: 'Newby',
    email: 'norvel.newby@ekofasttech.com',
    lastUpdated: '20:11:2020/14:05:23PM'
  },
  {
    id: 2,
    firstname: 'Kristen',
    lastname: 'Farquarhson',
    email: 'kristen.farquahson@ekofasttech.com',
    lastUpdated: '20:11:2020/14:05:23PM'
  }
];

const merchants = [
  {
    id: 0,
    merchant_name: 'CampusRunz Nigeria Limited',
    contact_person: 'Julia Ramsay',
    email: 'julia.ramsay@ekofasttech.com',
    lastUpdated: '20:11:2020/14:05:23PM'
  },
  {
    id: 1,
    merchant_name: 'Eko Fast Technology Limited',
    contact_person: 'Norvel Newby',
    email: 'norvel.newby@ekofasttech.com',
    lastUpdated: '20:11:2020/14:05:23PM'
  },
  {
    id: 2,
    merchant_name: 'Abuja Food Tech Company',
    contact_person: 'Kristen Farquarhson',
    email: 'kristen.farquahson@ekofasttech.com',
    lastUpdated: '20:11:2020/14:05:23PM'
  }
];

function ManageAccounts() {
  // const [selectedOption, setSelectedOption] = useState(options[0]);
  const [isOpen, setIsOpen] = useState(false);
  const [isAddForm, setIsAddForm] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [lgas, setLgas] = useState([]);

  const handleSelectState = (value) => {
    setLgas(getLga(value?.value)?.map((item) => ({ label: item, value: item })));
  };

  /*
  const onSelectChange = (e) => {
    setSelectedOption(options[e?.target?.value]);
  }; */

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const openAddForm = (e) => {
    e.stopPropagation();
    setIsAddForm(!isAddForm);
  };

  return (
    <div className="py-10 px-5 w-full md:px-10 relative">
      <SectionHeader title="Manage Accounts" />
      <div className="accordion" style={{ minHeight: 'calc(70vh - 72px)' }}>
        <Disclosure as="div" className="w-full mb-3">
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`${
                  open ? 'bg-primary text-white' : 'bg-white text-text-color'
                } flex justify-between mt-3 px-4 py-3 w-full shadow-dashboard-card text-sm`}
              >
                <span>Account Management</span>
                <ModalButton open={open} onClick={openAddForm}>
                  <FiPlus className="text-light-green text-xl font-black" />
                  Add a User
                </ModalButton>
              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform h-0 opacity-0"
                enterTo="transform h-fit opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform h-0 opacity-100"
                leaveTo="transform h-fit opacity-0"
              >
                <Disclosure.Panel>
                  <div className="shadow-sm overflow-x-hidden overflow-x-auto">
                    <Table
                      headers={[
                        'Firstname',
                        'Lastname',
                        'Email Address',
                        'Last Updated',
                        'Action'
                      ]}
                      stats={{ count: 3, currentCount: 3, totalCount: 3 }}
                    >
                      {users.map((user) => (
                        <tr className="text-text-color">
                          <td className="border-b border-border-color py-1.5 px-4">
                            {user.firstname}
                          </td>
                          <td className="border-b border-border-color py-1.5 px-4">
                            {user.lastname}
                          </td>
                          <td className="border-b border-border-color py-1.5 px-4">
                            {user.email}
                          </td>
                          <td className="border-b border-border-color py-1.5 px-4">
                            {user.lastUpdated}
                          </td>
                          <td className="border-b border-border-color py-1.5 px-4">
                            <Popover className="relative">
                              <Popover.Button
                                className="rounded-sm bg-primary text-xs text-white font-black px-2 py-1 justify-center transition ease-in-out outline-0 border-primary items-center hover:bg-opacity-70 focus:bg-opacity-70 disabled:bg-opacity-30 disabled:text-white"
                              >
                                Select Action
                              </Popover.Button>

                              <Popover.Panel className="absolute z-10 w-full">
                                <div className="bg-white border border-border-color flex flex-col w-full px-2 py-1 gap-1">
                                  {options.map((option) => (
                                    <button
                                      type="button"
                                      className="rounded-sm bg-light-gray text-xs text-black font-black px-2 py-1 justify-center transition ease-in-out outline-0 border-primary items-center hover:bg-primary hover:text-white focus:bg-opacity-70 disabled:bg-opacity-30 disabled:text-white"
                                    >
                                      {option.name}
                                    </button>
                                  ))}
                                </div>
                              </Popover.Panel>
                            </Popover>
                          </td>
                        </tr>
                      ))}
                    </Table>
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="w-full mb-3">
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`${
                  open ? 'bg-primary text-white' : 'bg-white text-text-color'
                } flex justify-between mt-3 px-4 py-3 w-full shadow-dashboard-card text-sm`}
              >
                <span>Access Management</span>
              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform h-0 opacity-0"
                enterTo="transform h-fit opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform h-0 opacity-100"
                leaveTo="transform h-fit opacity-0"
              >
                <Disclosure.Panel>
                  <div className="shadow-sm bg-white overflow-hidden flex gap-2.5">
                    <div className="flex-none w-72 ml-4 mt-2 mb-4">
                      <div className="">
                        <form method="GET">
                          <div className="relative">
                            <label
                              htmlFor="search"
                              className="text-text-color text-xs"
                            >
                              Search for Name
                            </label>
                            <input
                              type="search"
                              className="w-full my-1 py-3 pl-2 text-xs text-text-color bg-white outline-none border-b border-border-color"
                              placeholder=""
                              autoComplete="off"
                            />
                            <span className="absolute inset-y-0 top-4 right-0 flex items-center pl-2">
                              <button
                                type="button"
                                className="p-1 bg-white focus:outline-none text-text-color focus:shadow-outline"
                              >
                                <FiSearch className="w-4 h-4" />
                              </button>
                            </span>
                          </div>
                        </form>
                      </div>
                      <div>
                        <form action="submit">
                          <div className="block">
                            <label htmlFor="module" className="font-black text-xs text-text-color">Select Module</label>
                            {access.map((acc) => (
                              <CheckBox
                                className="mt-2 text-text-color text-xs"
                                label={acc}
                                checked={isChecked}
                                onChange={() => setIsChecked(!isChecked)}
                              />
                            ))}
                          </div>
                          <div className="flex mb-2 mt-6 justify-between gap-4">
                            <button type="button" className="bg-primary text-xs text-white font-black px-2 py-1.5 justify-center transition ease-in-out border border-primary items-center hover:bg-opacity-70 focus:bg-opacity-70 disabled:bg-opacity-30 disabled:text-white w-1/2">Assign Module</button>
                            <button type="button" className="bg-light-gray text-xs text-while font-black px-2 py-1.5 justify-center transition ease-in-out border border-border-color items-center hover:bg-opacity-70 focus:bg-opacity-70 disabled:bg-opacity-30 disabled:text-white w-1/2">Cancel</button>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="w-full overflow-x-hidden overflow-x-auto">
                      <table className="border-collapse table-auto w-full overflow-x-auto overflow-x-contain">
                        <thead className="bg-light-gray">
                          <tr className="text-black text-left font-black">
                            <th className="border-b py-3 pl-3">
                              <FiRefreshCw className="font-black" />
                            </th>
                            <th className="border-b py-3 pl-3">Name</th>
                            <th className="border-b py-3 pl-3">Privileges</th>
                            <th className="border-b py-3 pl-3">Last Updated</th>
                            <th className="border-b py-3 pl-3">Action</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white dark:bg-slate-800">
                          <tr className="text-text-color">
                            <td className="border-b border-border-color py-1.5 pl-3">
                              <button
                                type="button"
                                className="px-1 py-1.5 bg-custom-dark"
                              >
                                <FiEdit2 className="font-black text-white" />
                              </button>
                            </td>
                            <td className="border-b border-border-color py-1.5 pl-3">
                              Julia Ramsay
                            </td>
                            <td className="border-b border-border-color py-1.5 pl-3">
                              Access Management, Wallet Management, Reports
                            </td>
                            <td className="border-b border-border-color py-1.5 pl-3">
                              20:11:2020/14:05:23PM
                            </td>
                            <td className="border-b border-border-color py-1.5 pl-3">
                              <button
                                type="button"
                                className="px-1 py-1.5 text-center hover:text-red-600"
                              >
                                <FiTrash2 className="font-black" />
                              </button>
                            </td>
                          </tr>
                          <tr className="text-text-color">
                            <td colSpan="5" className="p-3 pl-4">
                              Showing
                              {' '}
                              <span>3</span>
                              {' '}
                              to
                              {' '}
                              <span>3</span>
                              {' '}
                              of
                              {' '}
                              <span>3</span>
                              {' '}
                              entries
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="w-full mb-3">
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`${
                  open ? 'bg-primary text-white' : 'bg-white text-text-color'
                } flex justify-between mt-3 px-4 py-3 w-full shadow-dashboard-card text-sm`}
              >
                <span>Merchant Account Management</span>
                <ModalButton open={open} onClick={openAddForm}>
                  <FiPlus className="text-light-green text-xl font-black" />
                  Add a User
                </ModalButton>
              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform h-0 opacity-0"
                enterTo="transform h-fit opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform h-0 opacity-100"
                leaveTo="transform h-fit opacity-0"
              >
                <Disclosure.Panel>
                  <div className="shadow-sm  no-scrollbar overflow-x-hidden overflow-x-auto">
                    <Table
                      headers={[
                        'Merchant Name',
                        'Contact Person',
                        'Email Address',
                        'Last Updated',
                        'Action'
                      ]}
                      stats={{ count: 3, currentCount: 3, totalCount: 3 }}
                    >
                      {merchants.map((merchant) => (
                        <tr className="text-text-color">
                          <td className="border-b border-border-color py-1.5 px-4">
                            <button type="button" onClick={openModal} className="hover:underline">
                              {merchant.merchant_name}
                            </button>
                          </td>
                          <td className="border-b border-border-color py-1.5 px-4">
                            {merchant.contact_person}
                          </td>
                          <td className="border-b border-border-color py-1.5 px-4">
                            {merchant.email}
                          </td>
                          <td className="border-b border-border-color py-1.5 px-4">
                            {merchant.lastUpdated}
                          </td>
                          <td className="border-b border-border-color py-1.5 px-4">
                            <Popover className="relative">
                              <Popover.Button
                                className="rounded-sm bg-primary text-xs text-white font-black px-2 py-1 justify-center transition ease-in-out outline-0 border-primary items-center hover:bg-opacity-70 focus:bg-opacity-70 disabled:bg-opacity-30 disabled:text-white"
                              >
                                Select Action
                              </Popover.Button>

                              <Popover.Panel className="absolute z-10 w-full">
                                <div className="bg-white border border-border-color flex flex-col w-full px-2 py-1 gap-1">
                                  {options.map((option) => (
                                    <button
                                      type="button"
                                      className="rounded-sm bg-light-gray text-xs text-black font-black px-2 py-1 justify-center transition ease-in-out outline-0 border-primary items-center hover:bg-primary hover:text-white focus:bg-opacity-70 disabled:bg-opacity-30 disabled:text-white"
                                    >
                                      {option.name}
                                    </button>
                                  ))}
                                </div>
                              </Popover.Panel>
                            </Popover>
                          </td>
                        </tr>
                      ))}
                    </Table>
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
      {isOpen && (
        <Modal onClose={openModal}>
          <FormHeader header="Business Profile" />
          <form>
            <TextInput
              className="mb-5"
              label="Business Name"
              name="businessname"
              value=""
            />
            <TextInput
              className="mb-5"
              label="RC Number"
              name="RCNumber"
            />
            <SelectInput
              className="mb-5"
              label="Business Category"
              name="business_category"
              options={businessCategory}
            />
            {/*
            {form?.businessCategory?.value == 'Others' && (
              <TextInput
                className="mb-5"
                label="Other Business Category"
                name="businessCategory"
                value={otherCategory?.value}
                onChange={(e) => handleOtherCategory({ label: e.target.value, value: e.target.value }, 'businessCategory')}
              />
            )} */}
            <SelectInput
              className="mb-5"
              label="Business Type"
              name="business_type"
              options={businessType}
            />
            <TextInput
              className="mb-5"
              label="Phone Number"
              name="phone"
            />
            <TextInput
              className="mb-5"
              label="Contact Address"
              name="address"
            />
            <SelectInput
              className="mb-5"
              label="State"
              name="state"
              options={states || []}
              onChange={(e) => handleSelectState(e, 'state')}
            />
            <SelectInput
              className="mb-5"
              label="LGA"
              name="lga"
              options={lgas || []}
            />
            <CheckBox
              className="mb-5 text-text-color"
              label="Terms of Use and Privacy policy"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
            />
            <Button type="submit" paddingY="py-3" className="w-full">Continue</Button>
          </form>
        </Modal>
      )}
      {isAddForm && (
        <Modal onClose={openAddForm}>
          <FormHeader header="Create Profile" />
          <form>
            <TextInput
              className="mb-5"
              label="First Name"
              name="firstname"
              value=""
            />
            <TextInput
              className="mb-5"
              label="Last Name"
              name="lastname"
            />
            <TextInput
              className="mb-5"
              label="Email Address"
              name="email"
            />
            <Button type="submit" paddingY="py-3" className="w-full">Create Account</Button>
          </form>
        </Modal>
      )}
      <Footer />
    </div>
  );
}

export default ManageAccounts;

// TODO: Export table to a separate component
// TODO: Export button within table to a separate component
// TODO: Work on second dropdown
