import React from 'react';
import PropTypes from 'prop-types';

function CustomCheckBox({
  name,
  value,
  checked,
  onChange,
  label,
  sublabel,
  className,
  type
}) {
  return (
    <div className={`${className || ''} relative mb-5`}>
      <label htmlFor={name} className="block text-xs text-text-color">
        {label}
      </label>
      <div className="flex justify-between border-b border-border-color py-2">
        <span className="text-text-color">{sublabel}</span>
        <input
          type={type}
          className="bg-gray-200 hover:bg-gray-300 cursor-pointer w-5 h-5 border-3 border-amber-500 focus:outline-none rounded-lg"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

CustomCheckBox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
  sublabel: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string
};

CustomCheckBox.defaultProps = {
  className: '',
  name: 'checkbox',
  onChange: () => {},
  checked: false,
  label: '',
  sublabel: '',
  type: 'checkbox',
  value: ''
};

export default CustomCheckBox;
