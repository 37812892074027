import React from 'react';
import PropTypes from 'prop-types';

function StatsCardCharts({
  title, children, stats, statColor
}) {
  return (
    <div className="bg-white rounded-lg shadow-card w-64">
      <div className="p-4 border-b">
        <p className="text-xs capitalize text-text-color font-black">{ title }</p>
      </div>

      <div className="p-4 flex gap-4 justify-between items-end w-full">
        <div className="flex flex-col">
          <span className="font-black text-lg">{ stats[0]}</span>
          <span className={`text-exs ${statColor}`}>{ stats[1]}</span>
        </div>
        <div>
          {children}
        </div>
      </div>
    </div>
  );
}

StatsCardCharts.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  stats: PropTypes.arrayOf(PropTypes.string),
  statColor: PropTypes.string
};

StatsCardCharts.defaultProps = {
  title: 'Stats',
  children: '',
  stats: [0, 0],
  statColor: 'text-card-red'
};

export default StatsCardCharts;
