import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import Clap from '../../assets/svgs/clap.svg';
import FormHeader from '../layouts/FormHeader';
import Button from '../layouts/Button';

function SuccessModal({ onClose, message, title }) {
  return (
    <Modal onClose={onClose}>
      <div className="text-center p-4">
        <div className="flex justify-center w-full mb-6">
          <img src={Clap} alt="" className="text-lg w-14 h-14" />
        </div>
        <FormHeader header={title} />
        <p className="mb-6 text-text-color">{ message }</p>
        <Button type="button" paddingY="py-3" className="w-full" onClick={onClose}>Continue</Button>
      </div>
    </Modal>
  );
}

SuccessModal.propTypes = {
  onClose: PropTypes.func,
  message: PropTypes.string,
  title: PropTypes.string
};

SuccessModal.defaultProps = {
  onClose: () => { },
  message: 'Your request has been successfully processed',
  title: 'Hello #Business Name'
};

export default SuccessModal;
