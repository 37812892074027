import React from 'react';

import { BiHome } from 'react-icons/bi';
import { BsFileEarmarkText } from 'react-icons/bs';
import { GiReceiveMoney, GiTakeMyMoney } from 'react-icons/gi';
import { RiSettings3Line, RiWallet3Line } from 'react-icons/ri';
import { VscGraph } from 'react-icons/vsc';

const sidebarMenu = [
  {
    title: 'Dashboard',
    link: '/admin/dashboard',
    icon: <BiHome className="icon" />
  },
  {
    title: 'Manage Accounts',
    link: '/admin/manage-accounts',
    icon: <VscGraph className="icon" />
  },
  {
    title: 'Manage Transactions',
    link: '/admin/manage-transactions',
    icon: <BsFileEarmarkText className="icon" />
  },
  {
    title: 'Wallets Management',
    link: '',
    icon: <RiWallet3Line className="icon" />,
    children: [
      { title: 'Manage Wallets', link: '/admin/wallets-management' },
      { title: 'Create Sub-Wallet', link: '/admin/create-subwallet' }
    ]
  },
  {
    title: 'Bills Payment',
    link: '/admin/bills-payment',
    icon: <GiReceiveMoney className="icon" />
  },
  {
    title: 'Send Money',
    link: '/admin/send-money',
    icon: <GiTakeMyMoney className="icon" />
  },
  {
    title: 'Settings',
    link: '',
    icon: <RiSettings3Line className="icon" />,
    children: [
      { title: 'Profile', link: '/admin/settings-profile' },
      { title: 'Business', link: '/admin/settings-business' },
      { title: 'Security', link: '/admin/settings-security' },
      { title: 'Documents', link: '/admin/settings-documents' },
      { title: 'Notifications', link: '/admin/settings-notifications' },
      { title: 'Developer Keys', link: '/admin/settings-developerkeys' }
    ]
  }
];

export default sidebarMenu;

// Possible menu item:
//
// {
//   link: '/admin/dashboard'
//   title: 'FAQs',
//   extra: 'add-btn',
//   icon: <HiOutlineFolderOpen className="icon" />,
//   childrenBase: '/admin/faqs',
//   children: [
//     { title: 'WEMA Bank', link: '/admin/faqs/wemabank' }
//   ]
// }

// Usage:
// childrenBase: only when you want to get the submenu items from a dymanic source
