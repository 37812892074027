import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

function SelectInput({
  className, name, label, value, onChange, disabled, options,
  readOnly, onBlur, onKeyDown, onKeyPress, multiple, placeholder
}) {
  return (
    <div className={`${className} relative`}>
      <label htmlFor={name} className="text-xs text-text-color mb-1">{label}</label>
      <Select
        className="select"
        classNamePrefix="react-select"
        name={name}
        id={name}
        value={value}
        isMulti={multiple}
        isSearchable
        onChange={onChange}
        isDisabled={disabled}
        options={options || []}
        readOnly={readOnly}
        onBlur={onBlur}
        disabled={disabled}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        placeholder={placeholder || ''}
      />
    </div>
  );
}

SelectInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyPress: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ),
  readOnly: PropTypes.bool,
  multiple: PropTypes.bool,
  placeholder: PropTypes.string
};

SelectInput.defaultProps = {
  className: '',
  name: '',
  onBlur: () => { },
  onChange: () => { },
  onKeyDown: () => { },
  onKeyPress: () => { },
  disabled: false,
  label: '',
  value: '',
  options: [],
  readOnly: false,
  multiple: false,
  placeholder: ''
};

export default SelectInput;
