import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useMutation } from 'react-query';
import Footer from '../../../components/common/Footer';
import SectionHeader from '../../../components/common/SectionHeader';
import SettingsOutline from '../../../components/common/SettingsOutline';
import ProfilePic from '../../../assets/images/placeholder.png';
import TextInput from '../../../components/inputs/TextInput';
import handleFetch from '../../../api/handleFetch';
import notification from '../../../utils/notification';
import Loading from '../../../components/common/Loading';

function SettingsDeveloperKeys() {
  const [cookies, setCookie] = useCookies(['data']);
  const [webHook, setWebHook] = useState(cookies?.data?.userDetail?.webHookUrl);

  const copyValue = (value) => {
    navigator.clipboard.writeText(value);
  };

  const updateWebhookMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        notification({
          title: 'Success',
          message: `${res?.message}`,
          type: 'success'
        });

        setCookie('data', {
          ...cookies?.data,
          userDetail: { ...cookies?.data?.userDetail, webHookUrl: webHook }
        });
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const updateWebhook = (e) => {
    e.preventDefault();

    updateWebhookMutation.mutate({
      endpoint: 'updateWebhook',
      body: {
        webHook
      },
      method: 'PUT',
      auth: true
    });
  };

  return (
    <div className="p-10 w-full">
      {updateWebhookMutation.isLoading && <Loading />}
      <SectionHeader title="Developer Keys Settings" />
      <SettingsOutline header="Developer Keys" imgSrc={ProfilePic}>
        {(cookies?.data?.userDetail?.isEmailVerified && cookies?.data?.userDetail?.profileStatus === 'Verified') ? (
          <form>
            <TextInput
              className="w-full mb-5"
              label="Public Key"
              name="publicKey"
              value={cookies?.data?.userDetail?.publicKey}
              enableAction
              callAction={() => copyValue(cookies?.data?.userDetail?.publicKey)}
              actionName="Copy"
            />

            <TextInput
              className="w-full mb-5"
              label="Secret Key"
              name="secretKey"
              value={cookies?.data?.userDetail?.secretKey}
              enableAction
              callAction={() => copyValue(cookies?.data?.userDetail?.secretKey)}
              actionName="Copy"
            />

            <TextInput
              className="w-full mb-5"
              label="Web Hook"
              name="webHook"
              value={webHook}
              enableAction
              onChange={(e) => setWebHook(e.target.value)}
              callAction={updateWebhook}
              actionName="Update"
            />
          </form>
        ) : (
          <p>
            Kindly update your document on the document tab to enable us verify your profile
          </p>
        )}
      </SettingsOutline>
      <Footer />
    </div>
  );
}

export default SettingsDeveloperKeys;
