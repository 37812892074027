import React, {
  createContext, useEffect, useMemo, useState
} from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';

import Sidebar from '../common/Sidebar';
import Header from '../common/Header';

const SearchContext = createContext();

function PageWrapper({ logout, children }) {
  const location = useLocation();
  const [search, setSearch] = useState('');
  const [cookies] = useCookies(['data']);

  const searchVal = useMemo(() => ({ search, setSearch }), [search]);

  useEffect(() => {
    setSearch('');
  }, [location]);

  return (
    <>
      <Header
        username={
          cookies?.data?.userDetail?.profileType === 'Admin'
            ? cookies?.data?.userDetail?.lastName
            : cookies.data?.userDetail?.accountName
        }
      />
      <div className="bg-main-bg w-full h-full min-h-screen relative pt-20">
        <Sidebar logout={logout} />
        <SearchContext.Provider value={searchVal}>
          <div className="w-full text-textColor lg:pl-60">
            <div className="w-full max-w-screen-2xl">{children}</div>
          </div>
        </SearchContext.Provider>
      </div>
    </>
  );
}

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  logout: PropTypes.func
};

PageWrapper.defaultProps = {
  logout: () => {}
};

export default PageWrapper;
