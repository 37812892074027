import React from 'react';
import Footer from '../../../components/common/Footer';
import SectionHeader from '../../../components/common/SectionHeader';
import SettingsOutline from '../../../components/common/SettingsOutline';
import CustomCheckBox from '../../../components/inputs/CustomCheckBox';
import ProfilePic from '../../../assets/images/placeholder.png';

function SettingsNotifications() {
  return (
    <div className="p-10 w-full">
      <SectionHeader title="Notifications Settings" />
      <SettingsOutline header="Notifications" imgSrc={ProfilePic}>
        <form>
          <CustomCheckBox
            name="pushNotification"
            label="Push Notification"
            sublabel="Receive Push notification for your transactions"
            value=""
          />

          <CustomCheckBox
            name="emailNotification"
            sublabel="Receive Email notification for your transactions"
            label="Email Notification"
            value=""
          />

          <CustomCheckBox
            name="smsNotification"
            label="SMS Notification"
            sublabel="Receive SMS notification for your transactions"
            value=""
          />
        </form>
      </SettingsOutline>
      <Footer />
    </div>
  );
}

export default SettingsNotifications;
