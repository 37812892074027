import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useMutation } from 'react-query';
import Footer from '../../../components/common/Footer';
import SectionHeader from '../../../components/common/SectionHeader';
import SettingsOutline from '../../../components/common/SettingsOutline';
import TextInput from '../../../components/inputs/TextInput';
import ProfilePic from '../../../assets/images/placeholder.png';
import SelectInput from '../../../components/inputs/SelectInput';
import states from '../../../data/nigeriaStates';
import getLgas from '../../../data/lgaData';
import handleFetch from '../../../api/handleFetch';
import notification from '../../../utils/notification';
import Loading from '../../../components/common/Loading';

function SettingsProfile() {
  const [cookies, setCookies] = useCookies(['data']);
  const [lgas, setLgas] = useState([]);

  const [profileForm, setProfileForm] = useState({
    address: cookies?.data?.userDetail?.contactAddress,
    state: { label: cookies?.data?.userDetail?.state, value: cookies?.data?.userDetail?.state },
    lga: { label: cookies?.data?.userDetail?.lga, value: cookies?.data?.userDetail?.lga }
  });

  const handleSelect = (value, name) => {
    setProfileForm((state) => ({ ...state, [name]: value }));
  };

  const handleSelectState = (value, name) => {
    handleSelect(value, name);
    handleSelect({ label: '', value: '' }, 'lga');
    setLgas(getLgas(value?.value)?.map((item) => ({ label: item, value: item })));
  };

  // Validations
  const validateProfileForm = () => {
    if (!profileForm.state) return 'State is required';
    if (!profileForm.lga) return 'LGA is required';
    if (!profileForm.address) return 'Address is required';
    return false;
  };

  const updateProfileMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        notification({
          title: 'Success',
          message: `${res?.message}`,
          type: 'success'
        });

        setCookies('data', {
          ...cookies?.data,
          userDetail: {
            ...cookies?.data?.userDetail,
            contactAddress: profileForm.address,
            lga: profileForm.lga.value,
            state: profileForm.state.value
          }
        });
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const updateProfile = (e) => {
    e.preventDefault();

    const error = validateProfileForm();

    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }

    updateProfileMutation.mutate({
      endpoint: 'updateProfile',
      body: {
        ...profileForm,
        state: profileForm?.state.value,
        lga: profileForm?.lga.value
      },
      method: 'POST',
      auth: true
    });
  };

  return (
    <div className="p-10 w-full">
      {updateProfileMutation.isLoading && (
        <Loading />
      )}
      <SectionHeader title="Profile Settings" />
      <SettingsOutline header="Business Profile Details" imgSrc={ProfilePic}>
        <form onSubmit={updateProfile}>
          <div className="flex flex-col gap-6 mb-5 lg:flex-row">
            <TextInput
              className="w-full lg:w-1/2"
              label="Business Name"
              name="businessname"
              value={cookies?.data?.userDetail.businessname}
              disabled
            />
            <TextInput
              className="w-full lg:w-1/2"
              label="Daily Limit"
              name="dailyTransactionLimit"
              value={cookies?.data?.userDetail?.dailyTransactionLimit}
              type="number"
              disabled
            />
          </div>
          <div className="flex flex-col gap-6 mb-5 lg:flex-row">
            <TextInput
              className="w-full lg:w-1/2"
              label="Email"
              name="email"
              value={cookies?.data?.userDetail?.email}
              disabled
            />
            <TextInput
              className="w-full lg:w-1/2"
              label="Phone Number"
              name="phoneNumber"
              value={cookies?.data?.userDetail?.phoneNumber}
              disabled
            />
          </div>
          <div className="flex flex-col gap-6 mb-5 lg:flex-row">
            <SelectInput
              className="w-full lg:w-1/2"
              label="State"
              name="state"
              value={profileForm?.state}
              options={states || []}
              onChange={(e) => handleSelectState(e, 'state')}
            />
            <SelectInput
              className="w-full lg:w-1/2"
              label="L.G.A"
              name="lga"
              value={profileForm?.lga}
              options={lgas || []}
              onChange={(e) => handleSelect(e, 'lga')}
            />
          </div>
          <div className="flex flex-col gap-6 mb-5 lg:flex-row">
            <TextInput
              className="w-full"
              label="Address"
              name="address"
              value={profileForm.address}
              onChange={(e) => setProfileForm({ ...profileForm, address: e.target.value })}
            />
          </div>
          <div className="flex flex-col gap-6 mb-5 lg:flex-row">
            <div className="w-full lg:w-1/2">
              <button className="text-sm bg-primary text-white px-6 py-2.5 rounded  flex justify-center items-center transition ease-in-out hover:bg-opacity-70 disabled:border-0 w-full disabled:cursor-default disabled:bg-opacity-30 disabled:bg-primary disabled:text-white" type="submit">Edit Profile</button>
            </div>
          </div>
        </form>
      </SettingsOutline>
      <Footer />
    </div>
  );
}

export default SettingsProfile;
