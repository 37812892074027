/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import { useMutation } from 'react-query';
import { useCookies } from 'react-cookie';
import Footer from '../../../components/common/Footer';
import SectionHeader from '../../../components/common/SectionHeader';
import SelectInput from '../../../components/inputs/SelectInput';
import TextInput from '../../../components/inputs/TextInput';
import Button from '../../../components/layouts/Button';
import FormHeader from '../../../components/layouts/FormHeader';
import CustomTab from '../../../components/common/CustomTab';
import handleFetch from '../../../api/handleFetch';
import Loading from '../../../components/common/Loading';
import SuccessModal from '../../../components/common/SuccessModal';
import notification from '../../../utils/notification';
import banks from '../../../data/banks';
import { formatCurrency } from '../../../utils/general';

const menus = [
  'To a CampusRunz Contact', 'To Multiple CampusRunz Contacts', 'To a Bank Account',
  'To Multiple Bank Accounts'
];

function SendMoney() {
  const [cookies] = useCookies();
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [walletDataList, setWalletDataList] = useState([]);
  const [confirmName, setConfirmName] = useState('');

  const [walletData, setWalletData] = useState({
    totalSales: 0,
    totalBalance: 0,
    totalCount: 0,
    totalTransaction: 0,
    wallets: []
  });

  const [fundSubwalletForm, setFundSubwalletForm] = useState({
    accountNumber: '',
    amount: 0,
    pin: ''
  });

  const [transferToBankForm, setTransferToBankForm] = useState({
    pin: '',
    destinationAccountNumber: '',
    bankCode: '',
    amount: 0,
    narration: ''
  });

  // FormChange
  const setConfirmNameChange = (value) => {
    setConfirmName(
      walletDataList.filter((w) => w.value === value)[0]?.label
    );
  };

  const handleFundSubwalletFormChange = (e) => {
    const { name, value } = e.target;
    setFundSubwalletForm((state) => ({ ...state, [name]: value }));

    if (name === 'accountNumber') {
      setConfirmNameChange(value);
    }
  };

  const handleTransferToBankFormChange = (e) => {
    const { name, value } = e.target;
    setTransferToBankForm((state) => ({ ...state, [name]: value }));
  };

  const handleBankSelect = (value, name) => {
    setTransferToBankForm((state) => ({ ...state, [name]: value }));
  };

  // Validations
  const validateFundSubwalletForm = () => {
    if (!fundSubwalletForm?.accountNumber) return 'Wallet/Account Number is required';
    if (!fundSubwalletForm?.amount || fundSubwalletForm?.amount < 1) return 'Amount is required';
    if (!fundSubwalletForm?.pin) return 'Pin is required';
    return false;
  };

  const validateTransferToBankForm = () => {
    if (!transferToBankForm?.bankCode) return 'Please select a Bank';
    if (!transferToBankForm?.destinationAccountNumber) return 'Account Number is required';
    if (!transferToBankForm?.pin) return 'Pin is required';
    if (!transferToBankForm?.amount || transferToBankForm?.amount < 1) return 'Amount is required';

    return false;
  };

  // Mutations
  const getWalletsMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        setWalletData(res?.data);

        const wallets = res?.data?.wallets;

        if (wallets?.length > 0) {
          const walletBuild = [];

          wallets.map((y) => walletBuild.push({
            label: `${y.accountName} - ${y.bankName}`,
            value: y.accountNumber
          }));

          setWalletDataList(walletBuild);
        }
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const fundSubwalletMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        setIsOpen(!isOpen);

        setMessage(res?.message);

        setFundSubwalletForm({
          accountNumber: '',
          amount: 0,
          pin: ''
        });

        setConfirmName('');
      }

      getWalletsMutation.mutate({
        endpoint: 'getWallets',
        auth: true,
        method: 'GET'
      });
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const transferToBankMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        setIsOpen(!isOpen);

        setMessage(res?.message);

        setTransferToBankForm({
          amount: 0,
          bankCode: '',
          destinationAccountNumber: '',
          narration: '',
          pin: ''
        });
      }

      getWalletsMutation.mutate({
        endpoint: 'getWallets',
        auth: true,
        method: 'GET'
      });
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  // Submit actions
  const fundSubwallet = (e) => {
    e.preventDefault();

    const error = validateFundSubwalletForm();

    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }

    fundSubwalletMutation.mutate({
      endpoint: 'fundSubwallet',
      body: {
        ...fundSubwalletForm,
        amount: +fundSubwalletForm.amount
      },
      method: 'POST',
      auth: true
    });
  };

  const transferToBank = (e) => {
    e.preventDefault();

    const error = validateTransferToBankForm();

    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }

    transferToBankMutation.mutate({
      endpoint: 'transferToBank',
      body: {
        ...transferToBankForm,
        amount: +transferToBankForm.amount,
        bankCode: transferToBankForm?.bankCode?.value
      },
      method: 'POST',
      auth: true
    });
  };

  // Use Effects
  useEffect(() => {
    getWalletsMutation.mutate({
      endpoint: 'getWallets',
      auth: true,
      method: 'GET'
    });
  }, []);

  return (
    <>
      {(getWalletsMutation.isLoading
        || fundSubwalletMutation.isLoading
        || transferToBankMutation.isLoading) && <Loading />}
      <div className="py-10 px-5 w-full md:px-10">
        <SectionHeader title="Send Money" />
        <div style={{ minHeight: 'calc(70vh - 72px)' }} className="mt-10">
          <CustomTab menus={menus}>
            <Tab.Panel className="w-full h-full m-0 flex justify-center items-start" style={{ minHeight: 'calc(70vh - 60px)' }}>
              <div className="w-full max-w-sm p-5 bg-white rounded-lg h-full">
                <FormHeader header="Send to a CampusRunz Contact" />
                <form onSubmit={fundSubwallet} autoComplete="off">
                  <TextInput
                    className="mb-5"
                    label="Wallet/Account Number"
                    name="accountNumber"
                    enableDataList
                    listName="subwallets"
                    dataLists={walletDataList}
                    value={fundSubwalletForm.accountNumber}
                    onChange={handleFundSubwalletFormChange}
                  />
                  <TextInput
                    className="mb-5"
                    label="Confirm Name"
                    name="confirmName"
                    value={confirmName}
                    disabled
                  />
                  <TextInput
                    className="mb-5"
                    label="Enter Amount"
                    name="amount"
                    type="number"
                    value={fundSubwalletForm.amount}
                    onChange={handleFundSubwalletFormChange}
                  />
                  <TextInput
                    className="mb-5"
                    label="Enter Pin"
                    name="pin"
                    value={fundSubwalletForm.pin}
                    onChange={handleFundSubwalletFormChange}
                  />
                  <Button type="submit" paddingY="py-3" className="w-full">Continue</Button>
                  <p className="w-full text-primary text-xs text-center pt-3">
                    {formatCurrency(walletData?.totalBalance)}
                  </p>
                </form>
              </div>
            </Tab.Panel>
            <Tab.Panel className="w-full h-full m-0 flex justify-center items-start" style={{ minHeight: 'calc(70vh - 60px)' }}>
              <div className="w-full max-w-sm p-5 bg-white rounded-lg h-full">
                <FormHeader header="Multiple CampusRunz Contacts" />
                <p className="text-center">
                  <span className="text-card-red">**</span>
                  {' '}
                  Sorry this functionality does not exist yet.
                  You will be notified when this feature is ready for use.
                  Thanks for your consideration
                </p>
                {/*
              <form>
                <SelectInput
                  className="mb-5"
                  label="Wallet/Account Number"
                  name="account_no"
                  options={[
                  ]}
                />
                <TextInput
                  className="mb-5"
                  label="Confirm Name"
                  name="name"
                  value=""
                />
                <TextInput
                  className="mb-5"
                  label="Enter Amount"
                  name="amount"
                  value=""
                />
                <TextInput
                  className="mb-5"
                  label="Add Note"
                  name="note"
                  value=""
                />
                <Button type="submit" paddingY="py-3" className="w-full">Continue</Button>
                <p className="w-full text-primary text-xs text-center pt-3">
                    {formatCurrency(walletData?.totalBalance)}
                </p>
              </form>
                */}
              </div>
            </Tab.Panel>
            <Tab.Panel className="w-full h-full m-0 flex justify-center items-start" style={{ minHeight: 'calc(70vh - 60px)' }}>
              <div className="w-full max-w-sm p-5 bg-white rounded-lg h-full">
                <FormHeader header="Send to a Bank Account" />
                <form autoComplete="off" onSubmit={transferToBank}>
                  <SelectInput
                    className="mb-5"
                    label="Select Bank"
                    name="bankCode"
                    options={banks}
                    value={transferToBankForm?.bankCode}
                    onChange={(e) => handleBankSelect(e, 'bankCode')}
                  />
                  <TextInput
                    className="mb-5"
                    label="Account Number"
                    name="destinationAccountNumber"
                    value={transferToBankForm?.destinationAccountNumber}
                    onChange={handleTransferToBankFormChange}
                  />
                  <TextInput
                    className="mb-5"
                    label="Amount"
                    type="number"
                    name="amount"
                    value={transferToBankForm?.amount}
                    onChange={handleTransferToBankFormChange}
                  />
                  <TextInput
                    className="mb-5"
                    label="Pin"
                    name="pin"
                    value={transferToBankForm?.pin}
                    onChange={handleTransferToBankFormChange}
                  />
                  <TextInput
                    className="mb-5"
                    label="Add Note"
                    name="narration"
                    value={transferToBankForm?.narration}
                    onChange={handleTransferToBankFormChange}
                  />
                  <Button type="submit" paddingY="py-3" className="w-full">Continue</Button>
                  <p className="w-full text-primary text-xs text-center pt-3">
                    {formatCurrency(walletData?.totalBalance)}
                  </p>
                </form>
              </div>
            </Tab.Panel>
            <Tab.Panel className="w-full h-full m-0 flex justify-center items-start" style={{ minHeight: 'calc(70vh - 60px)' }}>
              <div className="w-full max-w-sm p-5 bg-white rounded-lg h-full">
                <FormHeader header="Send to Multiple Bank Accounts" />
                <p className="text-center">
                  <span className="text-card-red">**</span>
                  {' '}
                  Sorry this functionality does not exist yet.
                  You will be notified when this feature is ready for use.
                  Thanks for your consideration
                </p>
                {/*
              <form>
                <SelectInput
                  className="mb-5"
                  label="Wallet/Account Number"
                  name="account_no"
                  options={[
                  ]}
                />
                <TextInput
                  className="mb-5"
                  label="Confirm Name"
                  name="name"
                  value=""
                />
                <TextInput
                  className="mb-5"
                  label="Enter Amount"
                  name="amount"
                  value=""
                />
                <TextInput
                  className="mb-5"
                  label="Add Note"
                  name="note"
                  value=""
                />
                <Button type="submit" paddingY="py-3" className="w-full">Continue</Button>
                <p className="w-full text-primary text-xs text-center pt-3">
                    {formatCurrency(walletData?.totalBalance)}
                </p>
              </form>
                */}
              </div>
            </Tab.Panel>
          </CustomTab>
        </div>
        <Footer />
        {isOpen && (
          <SuccessModal
            onClose={() => setIsOpen(!isOpen)}
            title={cookies?.data?.userDetail?.businessname}
            message={message}
          />
        )}
      </div>
    </>
  );
}

export default SendMoney;
