import React, { useState } from 'react';
import { Tab } from '@headlessui/react';
import { useMutation } from 'react-query';
import CustomTab from '../../../components/common/CustomTab';
import SectionHeader from '../../../components/common/SectionHeader';
import FormHeader from '../../../components/layouts/FormHeader';
import TextInput from '../../../components/inputs/TextInput';
import Button from '../../../components/layouts/Button';
import { isNumber } from '../../../utils/general';
import handleFetch from '../../../api/handleFetch';
import notification from '../../../utils/notification';
import Loading from '../../../components/common/Loading';

function CreateSubwallets() {
  const [generateWalletForm, setGenerateWalletForm] = useState({
    email: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    bvn: '',
    dateOfBirth: ''
  });

  // Form change
  const handleCreateSubwalletFormChange = (e) => {
    const { name, value } = e.target;
    setGenerateWalletForm((state) => ({ ...state, [name]: value }));
  };

  // Validations
  const validateCreateSubwalletForm = () => {
    if (!generateWalletForm?.firstName) return 'First Name is required';
    if (!generateWalletForm?.lastName) return 'Last Name is required';
    if (!generateWalletForm?.bvn) return 'BVN is required';
    if (!isNumber(generateWalletForm.bvn)) return 'BVN must be numeric only';
    if (generateWalletForm?.bvn.length !== 11) return 'BVN should be 11 digits';
    if (!generateWalletForm?.email) return 'Email is required';
    if (!generateWalletForm?.dateOfBirth) return 'Phone Number is required';

    return false;
  };

  // Mutations
  const generateWalletMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        notification({
          title: 'Success',
          message: res?.message,
          type: 'success'
        });

        setGenerateWalletForm({
          bvn: '',
          dateOfBirth: '',
          email: '',
          lastName: '',
          firstName: '',
          phoneNumber: ''
        });
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  // Submit Actions
  const generateWallet = (e) => {
    e.preventDefault();

    const error = validateCreateSubwalletForm();

    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }

    generateWalletMutation.mutate({
      endpoint: 'generateWallet',
      body: {
        ...generateWalletForm
      },
      method: 'POST',
      auth: true
    });
  };

  return (
    <>
      {generateWalletMutation.isLoading && <Loading />}
      <div>
        <div className="py-10 px-5 w-full md:px-10">
          <SectionHeader title="Create Subwallet" />
          <div style={{ minHeight: 'calc(70vh - 72px)' }} className="mt-10">
            <CustomTab hideMenu>
              <Tab.Panel
                className="w-full m-0 flex justify-center items-start"
                style={{ minHeight: 'calc(70vh - 60px)' }}
              >
                <div className="w-full max-w-sm p-5 bg-white rounded-lg">
                  <FormHeader header="Create Subwallet" />

                  <form autoComplete="off" onSubmit={generateWallet}>
                    <TextInput
                      className="mb-5"
                      label="First Name"
                      name="firstName"
                      value={generateWalletForm?.firstName}
                      onChange={handleCreateSubwalletFormChange}
                    />
                    <TextInput
                      className="mb-5"
                      label="Last Name"
                      name="lastName"
                      value={generateWalletForm?.lastName}
                      onChange={handleCreateSubwalletFormChange}
                    />
                    <TextInput
                      className="mb-5"
                      label="Bank Verification Number (BVN)"
                      name="bvn"
                      value={generateWalletForm?.bvn}
                      onChange={handleCreateSubwalletFormChange}
                    />
                    <TextInput
                      className="mb-5"
                      label="Email"
                      name="email"
                      value={generateWalletForm?.email}
                      onChange={handleCreateSubwalletFormChange}
                    />
                    <TextInput
                      className="mb-5"
                      label="Phone Number"
                      name="phoneNumber"
                      value={generateWalletForm?.phoneNumber}
                      onChange={handleCreateSubwalletFormChange}
                    />
                    <TextInput
                      className="mb-5"
                      label="Date of Birth"
                      type="date"
                      name="dateOfBirth"
                      value={generateWalletForm?.dateOfBirth}
                      onChange={handleCreateSubwalletFormChange}
                    />
                    <Button type="submit" paddingY="py-3" className="w-full">
                      Continue
                    </Button>
                  </form>
                </div>
              </Tab.Panel>
            </CustomTab>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateSubwallets;
