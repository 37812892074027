import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';

import Footer from '../components/common/Footer';
import Loading from '../components/common/Loading';
import SelectInput from '../components/inputs/SelectInput';
import TextInput from '../components/inputs/TextInput';
import Button from '../components/layouts/Button';

function AddBusiness({ onClose }) {
  const loading = false;
  const titles = ['Business', 'Company', 'Contact'];
  const [form, setForm] = useState({});
  const [formCount, setFormCount] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((state) => ({ ...state, [name]: value }));
  };

  const handleNext = (e) => {
    e.preventDefault();

    setFormCount(formCount + 1);
  };

  const goBack = () => {
    setFormCount(formCount - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="w-full h-screen bg-main-bg fixed top-0 left-0 overlay z-40 overflow-auto">
      {loading && <Loading />}

      <div
        style={{ minHeight: 'calc(100vh - 72px)' }}
        className="w-full flex justify-center items-center p-5 pt-10 relative"
      >
        <button type="button" className="absolute top-5 right-5" onClick={onClose}>
          <MdClose className="bg-primary w-10 h-auto text-white" />
        </button>
        <div className="w-full max-w-sm p-5 bg-white rounded-lg">
          <div className="flex justify-between text-base mb-10 text-title-color">
            {titles?.map((item, i) => (
              <h1
                key={item}
                className={`border-yellow-200 pb-1 ${i === formCount ? 'border-b-4 font-extrabold' : ''}`}
              >
                {`${item} ${i === formCount ? 'Details' : ''}`}
              </h1>
            ))}
          </div>

          {formCount === 0 && (
            <form className="mb-5" onSubmit={handleNext}>
              <TextInput
                className="mb-5"
                label="Business Name"
                name="businessname"
                value={form?.businessname}
                onChange={handleChange}
              />
              <TextInput
                className="mb-5"
                label="RC Number"
                name="RCNumber"
                value={form?.RCNumber}
                onChange={handleChange}
              />
              <TextInput
                className="mb-5"
                label="Phone Number"
                name="phone"
                value={form?.phone}
                onChange={handleChange}
              />
              <TextInput
                className="mb-5"
                label="Email Address"
                name="email"
                value={form?.email}
                onChange={handleChange}
              />
              <TextInput
                className="mb-5"
                label="Contact Address"
                name="contactAddress"
                value={form?.contactAddress}
                onChange={handleChange}
              />
              <SelectInput
                className="mb-5"
                label="Parish/Province/State"
                name="state"
                value={form?.state}
                options={[]}
                onChange={handleChange}
              />
              <Button type="submit" paddingY="py-3" className="w-full">Continue</Button>
            </form>
          )}

          {formCount === 1 && (
            <form className=" mb-5" onSubmit={handleNext}>
              <TextInput
                className="mb-5"
                label="Director's Name"
                name="directorName"
                value={form?.directorName}
                onChange={handleChange}
              />
              <TextInput
                className="mb-5"
                label="Director's BVN"
                name="directorBvn"
                value={form?.directorBvn}
                onChange={handleChange}
              />
              <TextInput
                className="mb-5"
                label="Director's Contact Address"
                name="directorAddress"
                value={form?.directorAddress}
                onChange={handleChange}
              />
              <SelectInput
                className="mb-5"
                label="Bank"
                name="bankName"
                options={[]}
                value={form?.bankName}
                onChange={handleChange}
              />
              <TextInput
                className="mb-5"
                label="Account Name"
                name="accountName"
                value={form?.accountName}
                onChange={handleChange}
              />
              <TextInput
                className="mb-5"
                label="Account Number"
                name="accountNo"
                value={form?.accountNo}
                onChange={handleChange}
              />
              <Button type="submit" paddingY="py-3" className="w-full">Continue</Button>
              <Button onClick={goBack} paddingY="py-3" className="w-full mt-5">Back</Button>
            </form>
          )}

          {formCount === 2 && (
            <form className=" mb-5" onSubmit={handleSubmit}>
              <TextInput
                className="mb-5"
                label="Contact Person's First Name"
                name="contactFirstname"
                value={form?.email}
                onChange={handleChange}
              />
              <TextInput
                className="mb-5"
                label="Contact Person's Last Name"
                name="contactLastname"
                value={form?.email}
                onChange={handleChange}
              />
              <TextInput
                className="mb-5"
                label="Phone Number"
                name="contactPhone"
                value={form?.email}
                onChange={handleChange}
              />
              <TextInput
                className="mb-5"
                label="Email Address"
                name="contactEmail"
                value={form?.email}
                onChange={handleChange}
              />
              <Button type="submit" paddingY="py-3" className="w-full">Submit Details</Button>
              <Button onClick={goBack} paddingY="py-3" className="w-full mt-5">Back</Button>
            </form>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}

AddBusiness.propTypes = {
  onClose: PropTypes.func
};

AddBusiness.defaultProps = {
  onClose: () => {}
};

export default AddBusiness;
