import React from 'react';

import PropTypes from 'prop-types';

function Button({
  children, className, onClick,
  disabled, fontSize, type,
  bgColor, textColor,
  paddingY, paddingX, border
}) {
  return (
    <button
      className={`${fontSize} ${bgColor} ${textColor} ${paddingX} ${paddingY} rounded ${border ? 'border border-primary' : ''
      } flex justify-center items-center transition ease-in-out hover:bg-opacity-70 disabled:border-0 ${className} disabled:cursor-default disabled:bg-opacity-30 disabled:bg-primary disabled:text-white`}
      type={type === 'submit' ? 'submit' : 'button'}
      onClick={onClick}
      disabled={disabled}
    >

      {children}

    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  fontSize: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  paddingY: PropTypes.string,
  paddingX: PropTypes.string,
  border: PropTypes.bool
};

Button.defaultProps = {
  children: 'Button',
  className: '',
  type: 'button',
  onClick: () => { },
  disabled: false,
  fontSize: 'text-sm',
  bgColor: 'bg-primary',
  textColor: 'text-white',
  paddingY: 'py-2',
  paddingX: 'px-6',
  border: false
};

export default Button;
