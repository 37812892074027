import React from 'react';
import PropTypes from 'prop-types';

function FormSubheader({ header }) {
  return (
    <div className="flex justify-center md:justify-start text-sm mb-10 text-title-color">
      <h1 className="border-bg-yellow pb-1 border-b-4 font-extrabold">
        {header}
      </h1>
    </div>
  );
}

FormSubheader.propTypes = {
  header: PropTypes.string
};

FormSubheader.defaultProps = {
  header: ''
};

export default FormSubheader;
