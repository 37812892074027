import React from 'react';
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';

function CustomOtpInput({ value, onchange, numInputs }) {
  return (
    <OtpInput
      value={value}
      onChange={onchange}
      numInputs={numInputs}
      isInputNum
      separator={<span className="px-2" />}
      inputStyle={{
        width: '40px',
        height: '40px',
        margin: '0',
        borderBottom: '2px solid rgba(209, 209, 209)',
        fontSize: '16px',
        fontFamily: 'Arial',
        fontWeight: '900'
      }}
      focusStyle={{
        width: '40px',
        height: '40px',
        outline: 'none',
        margin: '0',
        borderBottom: '2px solid rgba(3, 169, 245)',
        background: 'transparent',
        fontSize: '16px',
        fontFamily: 'Arial',
        fontWeight: '900'
      }}
    />
  );
}

CustomOtpInput.propTypes = {
  value: PropTypes.string,
  onchange: PropTypes.func,
  numInputs: PropTypes.number
};

CustomOtpInput.defaultProps = {
  value: '',
  numInputs: 4,
  onchange: () => { }
};

export default CustomOtpInput;
