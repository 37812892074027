import React, { useEffect } from 'react';

import Banner from '../assets/images/404-banner.png';

function LostPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'WEMA WIKI - 404 Page';
  }, []);

  return (
    <div className="w-full h-screen bg-main-bg">
      <div className="max-w-7xl mx-auto px-4">
        <div className="w-full h-full">
          <center className="text-primary font-bold text-2xl pt-20">Looks like you&apos;re lost</center>
          <div className="max-w-xl mx-auto mt-10">
            <img src={Banner} alt="" className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LostPage;
