import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Loading from './Loading';

function Modal({
  onClose, loading, children, modalBodyStyle
}) {
  return (
    <div className="w-full h-screen bg-primary/30 backdrop-opacity-10 fixed top-0 left-0 no-scrollbar overlay z-40 overflow-auto">
      {loading && <Loading />}
      <div
        style={{ minHeight: 'calc(100vh - 72px)' }}
        className="w-full flex flex-row-reverse justify-center items-start p-2 mt-10 pt-10 relative gap-2.5 mb:p-5"
      >
        <div className="relative">
          <button type="button" className="absolute top-0" onClick={onClose}>
            <AiOutlineCloseCircle className="w-10 h-auto text-white" />
          </button>
        </div>
        <div className={`${modalBodyStyle || 'w-full max-w-sm p-5'} bg-white rounded`}>
          {children}
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  modalBodyStyle: PropTypes.string
};

Modal.defaultProps = {
  onClose: () => { },
  loading: false,
  children: 'Modal',
  modalBodyStyle: ''
};

export default Modal;
