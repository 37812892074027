export default [
  { label: 'Citigroup', value: '23150005' },
  { label: 'Access Bank Plc', value: '44150149' },
  { label: 'Diamond Bank Plc', value: '63150162' },
  { label: 'Ecobank Nigeria Plc', value: '50150311' },
  { label: 'Enterprise Bank', value: '84150015' },
  { label: 'Equitorial Trust Bank Limited', value: '40150101' },
  { label: 'Fidelity Bank Plc', value: '70150003' },
  { label: 'First Bank of Nigeria Plc', value: '11152303' },
  { label: 'First City Monument Bank Plc', value: '214150018' },
  { label: 'Finbank Plc', value: '85151275' },
  { label: 'Guaranty Trust Bank Plc', value: '58152052' },
  { label: 'Keystone Bank', value: '82150017' },
  { label: 'Mainstreet Bank', value: '14150030' },
  { label: 'ECOBank', value: '56080016' },
  { label: 'Polaris Bank Plc', value: '76151006' },
  { label: 'Stanbic-Ibtc Bank Plc', value: '221159522' },
  { label: 'Standard Chartered Bank Nigeria Ltd', value: '68150057' },
  { label: 'Sterling Bank Plc', value: '232150029' },
  { label: 'United Bank for Africa Plc', value: '33154282' },
  { label: 'Union Bank of Nigeria Plc', value: '32156825' },
  { label: 'Unity Bank Plc', value: '215082334' },
  { label: 'Wema Bank Plc', value: '35150103' },
  { label: 'Zenith Bank Plc', value: '57150013' }
];
