import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import handleFetch from '../../api/handleFetch';
import Footer from '../../components/common/Footer';
import Loading from '../../components/common/Loading';
import TextInput from '../../components/inputs/TextInput';
import Button from '../../components/layouts/Button';
import FormHeader from '../../components/layouts/FormHeader';
import notification from '../../utils/notification';

function ResetPassword() {
  const navigate = useNavigate();
  const { email } = useParams();

  const [resetPasswordForm, setResetPasswordForm] = useState(
    {
      email,
      otp: '',
      newPassword: '',
      confirmPassword: ''
    }
  );

  // FormChange

  const handleResetPasswordFormChange = (e) => {
    const { name, value } = e.target;
    setResetPasswordForm((state) => ({ ...state, [name]: value }));
  };

  // Validations

  const validateResetPasswordForm = () => {
    if (!resetPasswordForm.otp) return 'Otp is required';
    if (!resetPasswordForm.newPassword) return 'New Password is required';
    if (!resetPasswordForm.confirmPassword) return 'Confirm Passwprd is required';
    if (resetPasswordForm.newPassword !== resetPasswordForm.confirmPassword) return 'Passwords must be a match';
    return false;
  };

  // Mutations

  const resetPasswordMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        notification({
          title: 'Success',
          message: `${res?.message}`,
          type: 'success'
        });

        navigate('/');
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  // Submit Actions

  const resetPassword = (e) => {
    e.preventDefault();

    const error = validateResetPasswordForm();

    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }

    resetPasswordMutation.mutate({
      endpoint: 'resetPassword',
      body: {
        ...resetPasswordForm
      },
      method: 'POST'
    });
  };

  return (
    <div className="w-full h-screen bg-main-bg fixed top-0 left-0 overlay z-40 overflow-auto">
      {resetPasswordMutation.isLoading && (
        <Loading />
      )}
      <div
        style={{ minHeight: 'calc(100vh - 72px)' }}
        className="w-full flex justify-center items-center p-5 pt-10 relative"
      >
        <div className="w-full max-w-sm p-5 bg-white rounded-lg">
          <FormHeader header="Reset Password" />

          <form className="mb-5" onSubmit={resetPassword}>
            <TextInput
              className="mb-5"
              label="Email"
              name="email"
              value={resetPasswordForm?.email}
              disabled
            />
            <TextInput
              className="mb-5"
              label="Otp"
              name="otp"
              value={resetPasswordForm?.otp}
              onChange={handleResetPasswordFormChange}
            />
            <TextInput
              className="mb-5"
              label="New Password"
              name="newPassword"
              type="password"
              value={resetPasswordForm?.newPassword}
              onChange={handleResetPasswordFormChange}
            />
            <TextInput
              className="mb-5"
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={resetPasswordForm?.confirmPassword}
              onChange={handleResetPasswordFormChange}
            />
            <Button type="submit" paddingY="py-3" className="w-full">Submit</Button>
          </form>
          <div className="flex justify-between text-xs text-text-color">
            <Link to="/signup">New to this Admin Portal?</Link>
            <Link to="/">Return Home</Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ResetPassword;
