export default [
  { label: 'Information Technology', value: 'Information Category' },
  { label: 'E-Commerce', value: 'E-Commerce' },
  { label: 'Education', value: 'Education' },
  { label: 'Mobile Money Operator', value: 'Mobile Money Operator' },
  { label: 'Financial Services', value: 'Financial Services' },
  { label: 'Manufacturing', value: 'Manufacturing' },
  { label: 'Leisure, Travel and Tourism', value: 'Leisure, Travel and Tourism' },
  { label: 'Fashion', value: 'Fashion' },
  { label: 'Events', value: 'Events' },
  { label: 'Charities and NGO', value: 'Charities and NGO' },
  { label: 'Others', value: 'Others' }
];
