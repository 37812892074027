import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageWrapper from '../components/wrappers/PageWrapper';
import Dashboard from '../pages/superadmin/Dashboard';
import Merchants from '../pages/superadmin/Merchants';
import Transactions from '../pages/superadmin/Transactions';
import BillsPayment from '../pages/superadmin/BillsPayment';
import SendMoney from '../pages/superadmin/SendMoney';
import SingleMerchant from '../pages/superadmin/SingleMerchant';

function SuperAdminRoutes({ Logout }) {
  return (
    <Routes>
      <Route
        path="/dashboard"
        exact
        element={(
          <PageWrapper logout={Logout}>
            <Dashboard />
          </PageWrapper>
        )}
      />
      <Route
        path="/merchants"
        exact
        element={(
          <PageWrapper logout={Logout}>
            <Merchants />
          </PageWrapper>
        )}
      />
      <Route
        path="/transactions"
        exact
        element={(
          <PageWrapper logout={Logout}>
            <Transactions />
          </PageWrapper>
        )}
      />
      <Route
        path="/bills-payment"
        exact
        element={(
          <PageWrapper logout={Logout}>
            <BillsPayment />
          </PageWrapper>
        )}
      />
      <Route
        path="/send-money"
        exact
        element={(
          <PageWrapper logout={Logout}>
            <SendMoney />
          </PageWrapper>
        )}
      />
      <Route
        path="/merchants/:merchantId"
        exact
        element={(
          <PageWrapper logout={Logout}>
            <SingleMerchant />
          </PageWrapper>
        )}
      />
      <Route
        path="*"
        element={(
          <PageWrapper logout={Logout}>
            <h1>Page not found</h1>
          </PageWrapper>
        )}
      />
    </Routes>
  );
}

SuperAdminRoutes.propTypes = {
  Logout: PropTypes.func
};

SuperAdminRoutes.defaultProps = {
  Logout: () => {}
};

export default SuperAdminRoutes;
