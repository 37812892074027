import React from 'react';
import PropTypes from 'prop-types';

import TextInput from '../../components/inputs/TextInput';
import Button from '../../components/layouts/Button';
import SelectInput from '../../components/inputs/SelectInput';
import securityQuestion from '../../data/securityQuestion';

function ProfileForm({
  form, handleChange, handleSelect, handleSubmit
}) {
  return (
    <>
      <div className="flex justify-center mb-10 text-title-color">
        <h1 className="font-extrabold text-lg border-b-4 border-yellow-200">Create Account</h1>
      </div>

      <form className=" mb-5" onSubmit={handleSubmit}>
        <TextInput
          className="mb-5"
          label="Email Address"
          name="email"
          value={form?.email}
          onChange={handleChange}
        />
        <TextInput
          className="mb-5"
          label="Password"
          type="password"
          name="password"
          value={form?.password}
          onChange={handleChange}
        />
        <TextInput
          className="mb-5"
          label="Confirm Password"
          type="password"
          name="confirmPassword"
          value={form?.confirmPassword}
          onChange={handleChange}
        />
        <TextInput
          className="mb-5"
          label="BVN"
          name="bvn"
          value={form?.bvn}
          onChange={handleChange}
        />
        <SelectInput
          className="mb-5"
          label="Select Security Question"
          name="securityQuestion"
          value={form?.securityQuestion}
          options={securityQuestion}
          onChange={(e) => handleSelect(e, 'securityQuestion')}
        />
        <TextInput
          className="mb-5"
          label="Answer"
          name="securityAnswer"
          value={form?.securityAnswer}
          onChange={handleChange}
        />

        <Button type="submit" paddingY="py-3" className="w-full mt-10">Continue</Button>
      </form>
    </>
  );
}

ProfileForm.propTypes = {
  form: PropTypes.shape({
    email: PropTypes.string,
    bvn: PropTypes.string,
    password: PropTypes.string,
    confirmPassword: PropTypes.string,
    securityQuestion: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
    securityAnswer: PropTypes.string
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired
};

export default ProfileForm;
