const prefix = '/api';

const endpoints = {
  signup: `${prefix}/Onboarding/portal/Register`,
  login: `${prefix}/Onboarding/portal/Login`,
  generateWallet: `${prefix}/Onboarding/portal/Generate-Wallet`,
  getWallets: `${prefix}/Onboarding/portal/Getwallets`,
  initiatePinreset: `${prefix}/Onboarding/Portal/Initiate-PinReset`,
  initiateChangeSecurityQuestion: `${prefix}/Onboarding/Portal/Initiate-ChangeSecurityQuestion`,
  validatePinresetOtp: `${prefix}/Onboarding/Portal/Validate-PinReset-otp`,
  verifyPassword: `${prefix}/Onboarding/Portal/verify-Password`,
  setTransactionPin: `${prefix}/Onboarding/Portal/Set-TransactionPin`,
  changeTransactionPin: `${prefix}/Onboarding/Portal/Change-TransactionPin`,
  resetPin: `${prefix}/Onboarding/Portal/reset-pin`,
  changePassword: `${prefix}/Onboarding/portal/Change-Password`,
  changeSecurityQuestion: `${prefix}/Onboarding/portal/ChangeSecurityQuestion`,
  getTransactions: `${prefix}/Transaction/Portal/Get-Transactions`,
  fundSubwallet: `${prefix}/Transaction/Portal/Fund-Subwallet`,
  transferToBank: `${prefix}/Transaction/Portal/Transfer-to-Bank`,
  buyAirtime: `${prefix}/Transaction/Portal/Buy-Airtime`,
  buyDataBundles: `${prefix}/Transaction/Portal/Buy-DataBundle`,
  getDataBundles: `${prefix}/Transaction/Portal/Get-DataBundles`,
  checkAccountBalance: `${prefix}/Transaction/Portal/CheckAccount-Balance`,
  forgotPassword: `${prefix}/Onboarding/portal/Forgot-Password`,
  resetPassword: `${prefix}/Onboarding/portal/Reset-Password`,
  verifyEmail: `${prefix}/Onboarding/portal/verify-email`,
  resendVerificationEmail: `${prefix}/Onboarding/portal/resend-verificationEmail`,
  updateProfile: `${prefix}/Onboarding/portal/update-profile`,
  uploadDocument: `${prefix}/Onboarding/portal/upload-document`,
  updateWebhook: `${prefix}/Onboarding/portal/Update_Webhook`,
  adminGetMerchants: `${prefix}/Admin/Get-Merchants`,
  adminGetMerchant: `${prefix}/Admin/merchant`,
  adminGetTransactions: `${prefix}/Admin/Get-Transactions`,
  adminGetAllTransactions: `${prefix}/Admin/Get-All-Transactions`,
  adminUpdateStatus: `${prefix}/Admin/Update-Status`,
  adminBuyAirtime: `${prefix}/Admin/Buy-Airtime`,
  adminBuyData: `${prefix}/Admin/Buy-Data`,
  adminGetDataBundles: `${prefix}/Admin/Get-DataBundles`,
  adminTransferToMerchantWallet: `${prefix}/Admin/Transfer-To-Merchant-Wallet`,
  adminTransferToBank: `${prefix}/Admin/Transfer-To-Bank`,
  adminGetBanks: `${prefix}/Admin/Get-Banks`,
  adminDashboardStats: `${prefix}/Admin/Dashboard/stats`
};

export default endpoints;
