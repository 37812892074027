import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@headlessui/react';
import { BsArrowRight } from 'react-icons/bs';

function HorizontalTab({
  children, menus, hideMenu, defaultIndex
}) {
  return (
    <Tab.Group defaultIndex={defaultIndex}>
      {!hideMenu && (
        <div className="text-text-color inline-block md:hidden mb-4 m-4">
          Scroll for more
          <BsArrowRight className="ml-2 inline" />
        </div>
      )}
      <div className="overflow-hidden">
        <Tab.List className="w-full bg-light-gray flex gap-10 justify-center py-4 border-b border-b-2 mb-2 no-scrollbar overflow-x-auto overflow-x-contain">
          {!hideMenu && menus.map((menu, index) => (
            <Tab index={index} as={Fragment}>
              {({ selected }) => (
                <button
                  type="button"
                  className={`${selected ? 'border-bg-yellow' : 'border-b-light-gray'} border-b-4 pb-1 font-black`}
                >
                  {menu}
                </button>
              )}
            </Tab>
          ))}
          {hideMenu && (<Tab className="hidden" />)}
        </Tab.List>
        <Tab.Panels className="w-full m-0 p-5">
          {children}
        </Tab.Panels>
      </div>
    </Tab.Group>
  );
}

HorizontalTab.propTypes = {
  children: PropTypes.node,
  menus: PropTypes.arrayOf(PropTypes.string),
  hideMenu: PropTypes.bool,
  defaultIndex: PropTypes.number
};

HorizontalTab.defaultProps = {
  children: '',
  menus: [''],
  defaultIndex: 0,
  hideMenu: false
};

export default HorizontalTab;
