import React from 'react';
import PropTypes from 'prop-types';

import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function PhoneNoInput({
  value, onChange, disabled, label, name, className
}) {
  return (
    <div className={`${className} relative`}>
      <label htmlFor={name} className="block text-xs text-text-color">
        {label}
      </label>
      <ReactPhoneInput
        country="ng"
        value={value}
        inputClass="!h-10 !w-full !rounded-none !text-text-color"
        containerClass="phone-input h-10"
        buttonClass="!rounded-none"
        autoFormat={false}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
}

PhoneNoInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string
};

PhoneNoInput.defaultProps = {
  value: '',
  onChange: () => { },
  disabled: false,
  label: '',
  name: '',
  className: ''
};

export default PhoneNoInput;
