/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

function CountDown({ initialMinute, initialSeconds, childFunc }) {
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  function resetTimer() {
    setMinutes(initialMinute);
    setSeconds(initialSeconds);
  }

  useEffect(() => {
    childFunc.current = resetTimer;
  }, []);

  return (
    <div className="text-text-color text-xs">
      { minutes === 0 && seconds === 0
        ? (<span>time expired</span>)
        : (
          <span>
            {' '}
            {minutes}
            :
            {seconds < 10 ? `0${seconds}` : seconds}
            {' '}
            {minutes < 1 ? 'seconds left' : 'minutes left'}
          </span>
        )}
    </div>
  );
}

CountDown.propTypes = {
  initialMinute: PropTypes.number,
  initialSeconds: PropTypes.number
};

CountDown.defaultProps = {
  initialMinute: 2,
  initialSeconds: 0
};

export default CountDown;
