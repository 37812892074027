/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextInput from '../../components/inputs/TextInput';
import Button from '../../components/layouts/Button';
import SelectInput from '../../components/inputs/SelectInput';
import CheckBox from '../../components/inputs/CheckBox';
import PhoneNoInput from '../../components/inputs/PhoneNoInput';

import states from '../../data/nigeriaStates';
import getLga from '../../data/lgaData';
import businessType from '../../data/businessType';
import businessCategory from '../../data/businessCategory';

function BusinessForm({
  form, handleChange, handleSubmit, handleSelect, handleOtherCategory,
  otherCategory
}) {
  const [checked, setChecked] = useState(false);
  const [lgas, setLgas] = useState([]);

  const handleSelectState = (value, name) => {
    handleSelect(value, name);
    handleSelect({ label: '', value: '' }, 'lga');
    setLgas(getLga(value?.value)?.map((item) => ({ label: item, value: item })));
  };

  return (
    <>
      <div className="flex justify-center mb-10 text-title-color">
        <h1 className="font-extrabold text-lg border-b-4 border-yellow-200">Business Profile</h1>
      </div>

      <form className=" mb-5" onSubmit={handleSubmit}>
        <TextInput
          className="mb-5"
          label="First Name"
          name="firstName"
          value={form?.firstName}
          onChange={handleChange}
        />
        <TextInput
          className="mb-5"
          label="Middle Name"
          name="middleName"
          value={form?.middleName}
          onChange={handleChange}
        />
        <TextInput
          className="mb-5"
          label="Last Name"
          name="lastName"
          value={form?.lastName}
          onChange={handleChange}
        />
        <TextInput
          className="mb-5"
          label="Business Name"
          name="businessname"
          value={form?.businessname}
          onChange={handleChange}
        />
        <TextInput
          className="mb-5"
          label="RC Number"
          name="rcNumber"
          value={form?.rcNumber}
          onChange={handleChange}
        />
        <SelectInput
          className="mb-5"
          label="Business Category"
          name="businessCategory"
          value={form?.businessCategory}
          options={businessCategory}
          onChange={(e) => handleSelect(e, 'businessCategory')}
        />
        {form?.businessCategory?.value == 'Others' && (
          <TextInput
            className="mb-5"
            label="Other Business Category"
            name="businessCategory"
            value={otherCategory?.value}
            onChange={(e) => handleOtherCategory({ label: e.target.value, value: e.target.value }, 'businessCategory')}
          />
        )}
        <SelectInput
          className="mb-5"
          label="Business Type"
          name="businessType"
          value={form?.businessType}
          options={businessType}
          onChange={(e) => handleSelect(e, 'businessType')}
        />
        <PhoneNoInput
          className="mb-5"
          label="Phone Number"
          name="phoneNumber"
          value={form?.phoneNumber}
          onChange={(e) => handleChange({ target: { value: e, name: 'phoneNumber' } })}
        />
        <TextInput
          className="mb-5"
          label="Contact Address"
          name="contactAddress"
          value={form?.contactAddress}
          onChange={handleChange}
        />
        <SelectInput
          className="mb-5"
          label="State"
          name="state"
          value={form?.state}
          options={states || []}
          onChange={(e) => handleSelectState(e, 'state')}
        />
        <SelectInput
          className="mb-5"
          label="L.G.A"
          name="lga"
          value={form?.lga}
          options={lgas || []}
          onChange={(e) => handleSelect(e, 'lga')}
        />
        <CheckBox
          className=""
          label="Terms of Use and Privacy policy"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />

        <Button
          type="submit"
          paddingY="py-3"
          className="w-full mt-10"
          disabled={!checked}
        >
          Sign Up
        </Button>
      </form>
    </>
  );
}

BusinessForm.propTypes = {
  form: PropTypes.shape({
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    businessname: PropTypes.string,
    rcNumber: PropTypes.string,
    businessCategory: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
    businessType: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
    phoneNumber: PropTypes.string,
    contactAddress: PropTypes.string,
    state: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
    lga: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  }).isRequired,
  otherCategory: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleOtherCategory: PropTypes.func.isRequired
};

export default BusinessForm;
