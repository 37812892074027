import React from 'react';
import PropTypes from 'prop-types';

function StatsCard({
  title, children, stats, iconBg, statColor
}) {
  return (
    <div className="bg-white rounded-lg shadow-card w-64 p-4">
      <div className="flex gap-4 items-center">
        <div className={`icon w-1/5 h-10 ${iconBg} rounded-lg flex justify-center items-center`}>
          {children}
        </div>
        <div className="w-5/6">
          <span className="text-text-color text-exs capitalize">{ title }</span>
          <div className="flex justify-between w-full">
            <span className="font-black text-lg">{ stats[0]}</span>
            <span className={`text-exs ${statColor}`}>{ stats[1]}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

StatsCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  stats: PropTypes.arrayOf(PropTypes.string),
  iconBg: PropTypes.string,
  statColor: PropTypes.string
};

StatsCard.defaultProps = {
  title: 'Stats',
  children: '',
  stats: ['0', '0'],
  iconBg: 'bg-card-lightblue',
  statColor: 'text-card-red'
};

export default StatsCard;
