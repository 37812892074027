import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { toggleScroll } from '../../utils/general';
import Footer from '../common/Footer';
import Button from './Button';

import Clap from '../../assets/svgs/clap.svg';

function SignupSuccess({ name }) {
  const navigate = useNavigate();

  useEffect(() => {
    toggleScroll();

    return (() => toggleScroll());
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/');
  };

  return (
    <div className="w-full bg-main-bg fixed top-0 left-0 overlay">
      <div style={{ minHeight: 'calc(100vh - 72px)' }} className="w-full flex justify-center items-center p-5">
        <center className="w-full max-w-sm p-10 bg-white rounded-lg">
          <img src={Clap} alt="" className="w-20 h-auto mb-3" />

          <div className="flex justify-center mb-5 text-title-color">
            <h1 className="font-extrabold text-lg border-b-4 border-yellow-200">{`Hello ${name}`}</h1>
          </div>

          <form className="text-center" onSubmit={handleSubmit}>
            <p className="mb-5 text-sm">Your account has been successfully created. Kindly login with your email and password.</p>
            <Button type="submit" paddingY="py-3" className="w-full">Continue</Button>
          </form>
        </center>
      </div>

      <Footer />
    </div>
  );
}

SignupSuccess.propTypes = {
  name: PropTypes.string
};

SignupSuccess.defaultProps = {
  name: ''
};

export default SignupSuccess;
