/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import { AiOutlinePlus, AiOutlineUpload, AiOutlineWarning } from 'react-icons/ai';
import { BsFillCloudUploadFill } from 'react-icons/bs';
import { VscLayersActive } from 'react-icons/vsc';
import handleFetch from '../../api/handleFetch';
import Footer from '../../components/common/Footer';
import SectionHeader from '../../components/common/SectionHeader';
import StatsCard from '../../components/common/StatsCard';
import StatsCardCharts from '../../components/common/StatsCardCharts';
import { formatCurrency } from '../../utils/general';
import notification from '../../utils/notification';
import AddBusiness from '../AddBusiness';
import Loading from '../../components/common/Loading';

function Dashboard() {
  const [showAddForm, setShowAddForm] = useState(false);
  const [cookies] = useCookies(['data']);
  const [walletData, setWalletData] = useState({
    totalSales: 0,
    totalBalance: 0,
    totalInflow: 0,
    totalCount: 0,
    totalTransaction: 0,
    wallets: []
  });

  const getWalletsMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        setWalletData(res?.data);
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const resendVerificationEmailMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      notification({
        title: 'Success',
        message: res?.message,
        type: 'success'
      });

      /*
      setCookies('data', {
        ...cookies?.data,
        userDetail: { ...cookies?.data?.userDetail, isEmailVerified: true }
      }); */
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  useEffect(() => {
    getWalletsMutation.mutate({
      endpoint: 'getWallets',
      auth: true,
      method: 'GET'
    });
  }, []);

  const resendVerificationEmail = () => {
    resendVerificationEmailMutation.mutate({
      endpoint: 'resendVerificationEmail',
      method: 'POST',
      auth: true
    });
  };

  return (
    <>
      {(getWalletsMutation.isLoading || resendVerificationEmailMutation.isLoading) && (
        <Loading />
      )}
      <div className="py-10 px-5 w-full md:px-10">
        <SectionHeader title="" />
        <div style={{ minHeight: 'calc(70vh - 72px)' }} className="mt-10">
          {cookies?.data?.userDetail?.isEmailVerified === false && (
            <div className="border border-1 border-card-red px-2 py-3 w-full bg-card-lightred">
              <div className="flex flex-col justify-between gap-4 sm:flex-row">
                <div className="flex justify-center items-center gap-5">
                  <AiOutlineWarning className="text-bg-red text-2xl" />
                  <span>
                    Hello
                    {' '}
                    <span className="font-black">
                      {cookies?.data?.userDetail?.businessname}
                    </span>
                    , kindly verify your email
                  </span>
                </div>
                <button
                  type="button"
                  className="px-3 py-4 bg-bg-red rounded-sm text-white sm:py-2"
                  onClick={resendVerificationEmail}
                >
                  <div className="flex justify-center items-center gap-2">
                    <AiOutlinePlus className="text-white" />
                    {' '}
                    <span className="text-exs">Verify Email</span>
                  </div>
                </button>
              </div>
            </div>
          )}
          {cookies?.data?.userDetail?.profileStatus === 'Pending' && (
            <div className="border border-1 border-card-yellow px-2 py-3 w-full bg-card-lightyellow mt-4">
              <div className="flex flex-col justify-between gap-4 sm:flex-row">
                <div className="flex justify-center items-center gap-5">
                  <BsFillCloudUploadFill className="text-primary text-2xl" />
                  <span>
                    Hello
                    {' '}
                    <span className="font-black">
                      {cookies?.data?.userDetail?.businessname}
                    </span>
                    , kindly upload all necessary document which will enable us verify your account
                  </span>
                </div>
                <Link
                  type="button"
                  className="px-3 py-4 bg-primary rounded-sm text-white sm:py-2"
                  to="/admin/settings-documents"
                >
                  <div className="flex justify-center items-center gap-2">
                    <AiOutlineUpload />
                    {' '}
                    <span className="text-exs">Upload Document</span>
                  </div>
                </Link>
              </div>
            </div>
          )}
          <div className="border border-1 border-card-red px-2 py-3 w-full bg-card-lightred mt-5 hidden">
            <div className="flex justify-between">
              <div className="flex justify-center items-center gap-5">
                <AiOutlineWarning className="text-bg-red text-2xl" />
                <span>
                  Hello
                  {' '}
                  <span className="font-black">
                    {cookies?.data?.userDetail?.businessname}
                  </span>
                  , kindly complete your account setup before you can continue
                </span>
              </div>
              <button
                type="button"
                className="px-3 py-2 bg-bg-red rounded-sm text-white"
              >
                <div className="flex justify-center items-center gap-2">
                  <AiOutlinePlus className="text-white" />
                  {' '}
                  <span className="text-exs">Continue Setup</span>
                </div>
              </button>
            </div>
          </div>
          <div className="mt-6">
            <div className="flex flex-wrap justify-center gap-4">
              <StatsCard
                title="Wallet Count"
                stats={[walletData?.wallets.length, '']}
                statColor="text-card-green"
                iconBg="bg-card-lightgreen"
              >
                <VscLayersActive className="text-xl text-card-green" />
              </StatsCard>
              <StatsCardCharts
                title="Account Number"
                stats={[cookies?.data?.userDetail?.accountNumber, cookies?.data?.userDetail?.bankName]}
                statColor="text-card-green"
              />
              <StatsCardCharts
                title="Total sales"
                stats={[formatCurrency(walletData?.totalSales), '']}
              />
              <StatsCardCharts
                title="Total Inflow"
                stats={[formatCurrency(walletData?.totalInflow), '']}
              />
              <StatsCardCharts
                title="Total balance"
                stats={[formatCurrency(walletData?.totalBalance), '']}
                statColor="text-card-green"
              />
              <StatsCardCharts
                title="Total transaction count"
                stats={[walletData?.totalTransaction.toString(), '']}
                statColor="text-card-green"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>

      {showAddForm && <AddBusiness onClose={() => setShowAddForm(false)} />}
    </>
  );
}

export default Dashboard;
