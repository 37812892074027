import React, {
  createContext, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

export const AdminSearchContext = createContext();

function AdminSearchContextProvider({ children }) {
  const location = useLocation();
  const [search, setSearch] = useState('');
  const searchVal = useMemo(() => ({ search, setSearch }), [search]);

  useEffect(() => {
    setSearch('');
  }, [location]);

  return (
    <AdminSearchContext.Provider value={searchVal}>
      {children}
    </AdminSearchContext.Provider>
  );
}

AdminSearchContextProvider.propTypes = {
  children: PropTypes.node
};

AdminSearchContextProvider.defaultProps = {
  children: ''
};

export default AdminSearchContextProvider;
