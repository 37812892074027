import React, {
  createContext, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

export const SelectedItemsContext = createContext();

function SelectedItemsContextProvider({ children }) {
  const location = useLocation();
  const [selectedItems, setSelectedItems] = useState([]);

  const addItem = (id) => {
    const find = selectedItems?.find((item) => item === id);
    if (!find?.id) {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const removeItem = (id) => {
    const filter = selectedItems?.filter((item) => item !== id);
    setSelectedItems(filter);
  };

  const clearList = () => {
    setSelectedItems([]);
  };

  const selectedItemsVal = useMemo(() => ({
    selectedItems,
    addItem,
    removeItem,
    clearList
  }), [selectedItems]);

  useEffect(() => {
    setSelectedItems([]);
  }, [location]);

  return (
    <SelectedItemsContext.Provider value={selectedItemsVal}>
      {children}
    </SelectedItemsContext.Provider>
  );
}

SelectedItemsContextProvider.propTypes = {
  children: PropTypes.node
};

SelectedItemsContextProvider.defaultProps = {
  children: ''
};

export default SelectedItemsContextProvider;
