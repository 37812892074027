import React from 'react';
import Modal from 'react-responsive-modal';
import PropTypes from 'prop-types';

import Preloader from '../../assets/svgs/preloader.svg';

function Loading({ message }) {
  return (
    <Modal
      classNames={{ modal: 'rounded-md no-padding' }}
      open
      showCloseIcon={false}
      onClose={() => {}}
      center
    >
      <center className="p-3 pb-5 rounded bg-white">
        <div className="">
          <img src={Preloader} className="w-40 h-auto" alt="circle" />
        </div>
        <p className="text-base">{message}</p>
      </center>
    </Modal>
  );
}

export default Loading;

Loading.propTypes = {
  message: PropTypes.string
};

Loading.defaultProps = {
  message: 'Loading...'
};
