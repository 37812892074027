import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useCookies } from 'react-cookie';
import TextInput from '../components/inputs/TextInput';
import Button from '../components/layouts/Button';
import Footer from '../components/common/Footer';
import notification from '../utils/notification';
import handleFetch from '../api/handleFetch';
import Loading from '../components/common/Loading';

function Homepage({ setAuth, setSuperAdmin }) {
  const [, setCookie] = useCookies();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Financial Manager - Login';

    // setCookie('data', '', { path: '/admin' });
    // Cookies.remove('data', { path: '/admin' });
  }, []);

  const loginMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        setCookie('data', res?.data);
      }

      notification({
        title: 'Successful Login',
        message: `Welcome back ${
          res?.data?.userDetail?.profileType === 'Admin'
            ? res?.data?.userDetail?.lastName
            : res?.data?.userDetail?.accountName
        }`,
        type: 'success'
      });

      if (res?.data?.userDetail?.profileType === 'Admin') {
        sessionStorage.setItem('isSuperAdmin', true);
        setSuperAdmin();
        navigate('/superadmin/dashboard');
      } else {
        localStorage.setItem('isAuth', true);
        setAuth();
        navigate('/admin/dashboard');
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!(email || password)) {
      notification({
        title: 'Form Error',
        message: 'Enter your email and password',
        type: 'danger'
      });
      return;
    }

    const body = { username: email, password };

    loginMutation.mutate({
      endpoint: 'login',
      body,
      method: 'POST',
      auth: false
    });
  };

  const { isLoading } = loginMutation;

  return (
    <div className="w-full bg-main-bg">
      {isLoading && <Loading />}

      <div
        style={{ minHeight: 'calc(100vh - 72px)' }}
        className="w-full flex justify-center items-center"
      >
        <div className="w-full max-w-5xl">
          <div className="w-full flex justify-center p-5">
            <div className="bg-primary w-2/3 mr-5 rounded-lg hidden md:block" />
            <div className="max-w-sm w-full p-5 bg-white border rounded-lg">
              <div className="flex justify-center mb-10 text-title-color">
                <h1 className="font-extrabold text-lg border-b-4 border-yellow-200">
                  FinanceManager
                </h1>
              </div>

              <form className=" mb-5" onSubmit={handleSubmit}>
                <TextInput
                  className="mb-5"
                  label="Email Address"
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextInput
                  className="mb-5"
                  label="Password"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button type="submit" paddingY="py-3" className="w-full">
                  Login
                </Button>
              </form>

              <div className="flex justify-between text-xs text-text-color">
                <Link to="/signup">New to this Admin Portal?</Link>
                <Link to="/forget-password">Forgot Password?</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

Homepage.propTypes = {
  setAuth: PropTypes.func,
  setSuperAdmin: PropTypes.func
};

Homepage.defaultProps = {
  setAuth: () => {},
  setSuperAdmin: () => {}
};

export default Homepage;
