import React from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';

function SectionHeader({ title, hideTitle }) {
  const [cookies] = useCookies(['data']);

  return (
    <div className="flex flex-col justify-between items-center border-b border-bg-light m-0 mb-4 md:flex-row">
      <h1
        className={`font-arial font-semibold text-text-color text-base md:text-lg ${
          hideTitle && 'hidden md:block'
        }`}
      >
        {title}
      </h1>
      <div className="border-b-4 border-bg-light h-auto py-3 font-black">
        {cookies?.data?.userDetail?.profileType === 'Admin'
          ? cookies?.data?.userDetail?.lastName
          : cookies?.data?.userDetail?.businessname}
      </div>
    </div>
  );
}

SectionHeader.propTypes = {
  title: PropTypes.string,
  hideTitle: PropTypes.bool
};

SectionHeader.defaultProps = {
  title: 'Welcome',
  hideTitle: false
};

export default SectionHeader;
