import React, { useState } from 'react';
import { useMutation } from 'react-query';
import Footer from '../../../components/common/Footer';
import SectionHeader from '../../../components/common/SectionHeader';
import SettingsOutline from '../../../components/common/SettingsOutline';
import ProfilePic from '../../../assets/images/placeholder.png';
import CustomFileUpload from '../../../components/inputs/CustomFileUpload';
import handleFetch from '../../../api/handleFetch';
import notification from '../../../utils/notification';
import Loading from '../../../components/common/Loading';

function SettingsDocuments() {
  const [file, setFile] = useState([]);

  const uploadDocumentMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        notification({
          title: 'Success',
          message: `${res?.message}`,
          type: 'success'
        });

        setFile([]);
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const uploadDocument = (e, val, name) => {
    e.preventDefault();

    if (file.length < 1) {
      notification({
        title: 'Form Error',
        message: 'Please upload the required document',
        type: 'danger'
      });
      return;
    }

    const formData = new FormData();
    formData.append('Document', file[0]);
    formData.append('DocumentType', val);

    uploadDocumentMutation.mutate({
      endpoint: 'uploadDocument',
      body: formData,
      method: 'POST',
      multipart: true,
      auth: true
    });

    document.getElementById(name).value = null;
  };

  return (
    <div className="p-10 w-full">
      {uploadDocumentMutation.isLoading && (
        <Loading />
      )}
      <SectionHeader title="Documents Settings" />
      <SettingsOutline header="Documents Settings" imgSrc={ProfilePic}>
        <div>
          <CustomFileUpload
            label="Certificate of Incorporation"
            name="certificateIncorporation"
            onChange={(e) => setFile(e.target.files)}
            onSubmit={(e) => uploadDocument(e, 1, 'certificateIncorporation')}
            isRequired
          />
          <CustomFileUpload
            label="MEMART"
            name="memart"
            onChange={(e) => setFile(e.target.files)}
            onSubmit={(e) => uploadDocument(e, 2, 'memart')}
          />
          <CustomFileUpload
            label="ID Card of Business Owner (Government Issued)"
            name="issuedId"
            isRequired
            onChange={(e) => setFile(e.target.files)}
            onSubmit={(e) => uploadDocument(e, 3, 'issuedId')}
          />
          <CustomFileUpload
            label="Supporting Document"
            name="supportingDocument"
            onChange={(e) => setFile(e.target.files)}
            onSubmit={(e) => uploadDocument(e, 4, 'supportingDocument')}
          />
        </div>
      </SettingsOutline>
      <div className="text-center my-3 text-text-color">
        <p>Please note that your documents will be verified</p>
      </div>
      <Footer />
    </div>
  );
}

export default SettingsDocuments;
