import React from 'react';
import PropTypes from 'prop-types';

function Table({ children, headers, stats }) {
  return (
    <table className="border-collapse table-auto w-full overflow-x-auto overflow-x-contain">
      <thead className="bg-light-gray">
        <tr className="text-black text-left font-black">
          {headers.map((header) => (
            <th className="border-b py-3 pl-4">{ header }</th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white dark:bg-slate-800">
        {children}
        <tr className="text-text-color">
          <td
            colSpan="5"
            className="p-3 pl-4"
          >
            Showing
            {' '}
            <span>{ stats?.count }</span>
            {' '}
            to
            {' '}
            <span>{ stats?.currentCount }</span>
            {' '}
            of
            {' '}
            <span>{ stats?.totalCount}</span>
            {' '}
            entries
          </td>
        </tr>
      </tbody>
    </table>
  );
}

Table.propTypes = {
  children: PropTypes.node,
  headers: PropTypes.arrayOf(PropTypes.string),
  stats: PropTypes.shape({
    count: PropTypes.number,
    currentCount: PropTypes.number,
    totalCount: PropTypes.number
  })
};

Table.defaultProps = {
  children: 'Table',
  headers: '',
  stats: { count: 1, currentCount: 1, totalCount: 1 }
};

export default Table;
