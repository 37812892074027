import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import { useMutation } from 'react-query';
import {
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
  BsFillCaretLeftFill
} from 'react-icons/bs';
import { AiOutlineUpload } from 'react-icons/ai';
import Footer from '../../components/common/Footer';
import SectionHeader from '../../components/common/SectionHeader';
import FormSubheader from '../../components/layouts/FormSubheader';
import ProfilePic from '../../assets/images/placeholder.png';
import HorizontalTab from '../../components/common/HorizontalTab';
import handleFetch from '../../api/handleFetch';
import notification from '../../utils/notification';
import Loading from '../../components/common/Loading';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import nigeriaStates from '../../data/nigeriaStates';
import lgaData from '../../data/lgaData';
import {
  formatCurrency,
  formatCurrencyNoNaira,
  formatDateToYMD
} from '../../utils/general';
import TableLight from '../../components/common/TableLight';
import { formatDateTime } from '../../utils/dateTime';
import StatsCardCharts from '../../components/common/StatsCardCharts';

const menus = [
  'User Profile',
  'Directors',
  'Documentations',
  'Wallet Details',
  'Transactions'
];

const transactionHeader = [
  'S/N',
  'Date/Time',
  'Category',
  'Reference',
  'Narration',
  'Amount (₦)',
  'Balance (₦)'
];

function SingleMerchant() {
  const navigate = useNavigate();
  const { merchantId } = useParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [lgas, setLgas] = useState([]);
  const [merchant, setMerchant] = useState({});
  const [originData, setOriginData] = useState({
    state: { label: '', value: '' },
    lga: { label: '', value: '' }
  });

  const [stats, setStats] = useState({
    totalSales: 0,
    totalInflow: 0,
    totalBalance: 0,
    totalCount: 0
  });

  const selectOrigin = (value, name) => {
    setOriginData((state) => ({ ...state, [name]: value }));
  };

  const selectState = (value, name) => {
    selectOrigin(value, name);
    selectOrigin({ label: '', value: '' }, 'lga');
    setLgas(
      lgaData(value?.value)?.map((item) => ({ label: item, value: item }))
    );
  };

  const getMerchantMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        setMerchant(res?.data);

        setOriginData({
          ...originData,
          state: {
            label: res?.data?.profileDetail?.state,
            value: res?.data?.profileDetail?.state
          },
          lga: {
            label: res?.data?.profileDetail?.lga,
            value: res?.data?.profileDetail?.lga
          }
        });
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const getTransactionMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        setTransactions(res?.data?.transactions);
        setTotalCount(res?.data?.totalCount);
        setTotalPage(res?.data?.totalPage);
        setStats({
          ...stats,
          totalBalance: res?.data?.totalBalance,
          totalCount: res?.data?.totalCount,
          totalInflow: res?.data?.totalInflow,
          totalSales: res?.data?.totalSales
        });
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  useEffect(() => {
    getMerchantMutation.mutate({
      endpoint: 'adminGetMerchant',
      auth: true,
      method: 'GET',
      param: merchantId
    });
  }, []);

  useEffect(() => {
    getTransactionMutation.mutate({
      endpoint: 'adminGetTransactions',
      param: merchantId,
      auth: true,
      method: 'GET',
      pQuery: { pageNumber, pageSize }
    });
  }, [pageNumber]);

  return (
    <div className="py-10 px-5 w-full md:px-10">
      {(getMerchantMutation.isLoading || getTransactionMutation.isLoading) && (
        <Loading />
      )}
      <SectionHeader title={merchant?.profileDetail?.businessName} />
      <div style={{ minHeight: 'calc(60vh - 72px)' }} className="mt-5 bg-white">
        <button
          type="button"
          className="m-4 px-4 py-2 border border-1 border-primary rounded-sm text-black items-center transition ease-in-out hover:bg-opacity-70 disabled:bg-opacity-30"
          onClick={() => navigate(-1)}
        >
          <BsFillCaretLeftFill className="text-primary" />
        </button>
        <div className="flex justify-center px-5 py-6 mb-2">
          <div className="w-44 h-44 bg-light-gray rounded-full overflow-hidden">
            <img src={ProfilePic} alt="" className="w-44 h-44 rounded-full" />
          </div>
        </div>
        <div className="bg-white">
          <HorizontalTab menus={menus}>
            <Tab.Panel
              className="px-5 py-4 userProfile"
              style={{ minHeight: 'calc(70vh - 60px)' }}
            >
              <form>
                <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                  <TextInput
                    className="w-full lg:w-1/3 disabled: bg-white"
                    label="Business Name"
                    name="businessName"
                    value={merchant?.profileDetail?.businessName}
                    disabled
                  />
                  <TextInput
                    className="w-full lg:w-1/3 disabled: bg-white"
                    label="RC Number"
                    name="rcNumber"
                    value={merchant?.profileDetail?.rcNumber}
                    disabled
                  />
                  <TextInput
                    className="w-full lg:w-1/3 disabled: bg-white"
                    label="Business Category"
                    name="businessCategory"
                    value={merchant?.profileDetail?.businessCategory}
                    disabled
                  />
                </div>
                <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                  <TextInput
                    className="w-full lg:w-1/3 disabled: bg-white"
                    label="Business Type"
                    name="businessType"
                    value={merchant?.profileDetail?.businessType}
                    disabled
                  />
                  <TextInput
                    className="w-full lg:w-1/3 disabled: bg-white"
                    label="Phone Number"
                    name="phoneNumber"
                    value={merchant?.profileDetail?.phoneNumber}
                    disabled
                  />
                  <TextInput
                    className="w-full lg:w-1/3 disabled: bg-white"
                    label="Alt Phone Number"
                    name="altPhoneNumber"
                  />
                </div>
                <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                  <SelectInput
                    className="w-full lg:w-1/3"
                    label="State"
                    name="state"
                    options={nigeriaStates || []}
                    value={originData.state}
                    onChange={(e) => selectState(e, 'state')}
                    disabled
                  />
                  <SelectInput
                    className="w-full lg:w-1/3"
                    label="LGA"
                    name="lga"
                    value={originData.lga}
                    options={lgas || []}
                    onChange={(e) => selectOrigin(e, 'lga')}
                    disabled
                  />
                  <TextInput
                    className="w-full lg:w-1/3 disabled: bg-white"
                    label="#"
                    name="merchantId"
                  />
                </div>
                <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                  <TextInput
                    className="w-full"
                    label="Address"
                    name="address"
                    value={merchant?.profileDetail?.contactAddress}
                    disabled
                  />
                </div>
                <section id="businessRepresentative">
                  <FormSubheader header="Business Representative" />
                  <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                    <TextInput
                      className="w-full lg:w-1/3 disabled: bg-white"
                      label="First Name"
                      name="firstName"
                    />
                    <TextInput
                      className="w-full lg:w-1/3 disabled: bg-white"
                      label="Last Name"
                      name="lastName"
                    />
                    <TextInput
                      className="w-full lg:w-1/3 disabled: bg-white"
                      label="Phone Number"
                      name="phoneNumber"
                    />
                  </div>
                  <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                    <TextInput
                      className="w-full lg:w-2/3 disabled: bg-white"
                      label="Email Address"
                      name="emailAddress"
                    />
                    <TextInput
                      className="w-full lg:w-1/3 disabled: bg-white"
                      label="Alt Phone Number"
                      name="altphoneNumber"
                    />
                  </div>
                </section>
                <section id="businessDetails">
                  <FormSubheader header="Business Details" />
                  <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                    <TextInput
                      className="w-full lg:w-1/3 disabled:bg-white"
                      label="Bank Name"
                      name="bankName"
                    />
                    <TextInput
                      className="w-full lg:w-1/3"
                      label="BVN"
                      name="bvn"
                      value={merchant?.walletDetail?.bvn}
                      disabled
                    />
                    <TextInput
                      className="w-full lg:w-1/3 disabled: bg-white"
                      label="TIN"
                      name="tin"
                    />
                  </div>
                  <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                    <TextInput
                      className="w-full lg:w-1/3 disabled: bg-white"
                      label="Account Name"
                      name="accountName"
                    />
                    <TextInput
                      className="w-full lg:w-1/3 disabled: bg-white"
                      label="Account Number"
                      name="accountNumber"
                    />
                    <TextInput
                      className="w-full lg:w-1/3 disabled: bg-white"
                      label="#"
                      name=""
                    />
                  </div>
                </section>
              </form>
            </Tab.Panel>
            <Tab.Panel
              className="px-5 py-4 directors"
              style={{ minHeight: 'calc(70vh - 60px)' }}
            >
              <section className="director">
                {merchant?.directors && merchant?.directors.length > 0 ? (
                  <form>
                    {merchant?.directors.map((director, index) => (
                      <>
                        <FormSubheader header={`Director ${index + 1}`} />
                        <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                          <TextInput
                            className="w-full lg:w-1/3 disabled: bg-white"
                            label="First Name"
                            name="directorFirstName"
                            value={director.firstName}
                          />
                          <TextInput
                            className="w-full lg:w-1/3 disabled: bg-white"
                            label="Last Name"
                            name="directorLastName"
                            value={director.lastName}
                          />
                          <TextInput
                            className="w-full lg:w-1/3 disabled: bg-white"
                            label="Other Names"
                            name="directorOtherName"
                            value={director.otherName}
                          />
                        </div>
                        <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                          <TextInput
                            className="w-full lg:w-1/3 disabled: bg-white"
                            label="BVN"
                            name="bvn"
                            value={director.bvn}
                          />
                          <TextInput
                            className="w-full lg:w-1/3 disabled: bg-white"
                            label="Gender"
                            name="gender"
                            value={director.gender}
                          />
                          <TextInput
                            className="w-full lg:w-1/2"
                            label="Date Of Birth"
                            name="dob"
                            value={formatDateToYMD(director.dateOfBirth)}
                            type="date"
                            disabled
                          />
                        </div>
                        <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                          <TextInput
                            className="w-full lg:w-1/3 disabled: bg-white"
                            label="Phone Number"
                            name="phoneNumber"
                            value={director.phoneNumber}
                          />
                          <TextInput
                            className="w-full lg:w-1/3 disabled: bg-white"
                            label="Email Address"
                            name="emailAddress"
                            value={director.email}
                          />
                          <SelectInput
                            className="w-full lg:w-1/3"
                            label="State"
                            name="state"
                            options={nigeriaStates || []}
                            value={originData.state}
                            onChange={(e) => selectState(e, 'state')}
                            disabled
                          />
                        </div>
                        <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                          <SelectInput
                            className="w-full lg:w-1/3"
                            label="LGA"
                            name="lga"
                            value={originData.lga}
                            options={lgas || []}
                            onChange={(e) => selectOrigin(e, 'lga')}
                            disabled
                          />
                          <TextInput
                            className="w-full lg:w-2/3 disabled: bg-white"
                            label="Address"
                            name="address"
                            value={director.address}
                          />
                        </div>
                      </>
                    ))}
                  </form>
                ) : (
                  <>
                    <FormSubheader header="Directors" />
                    <p>No Director provided yet</p>
                  </>
                )}
              </section>
            </Tab.Panel>
            <Tab.Panel
              className="px-5 py-4 documentations"
              style={{ minHeight: 'calc(70vh - 60px)' }}
            >
              {merchant && merchant?.documents?.length > 0 ? (
                <Tab.Group vertical as={Fragment}>
                  <div
                    className="flex flex-col gap-5 justify-between md:flex-row
                "
                  >
                    <Tab.List className="w-full md:w-1/3 flex flex-row items-start gap-5 mb-4 md:mb-0 md:flex-col no-scrollbar overflow-x-auto overflow-x-contain">
                      {merchant
                        && merchant?.documents?.map((document, index) => (
                          <Tab index={index} as={Fragment}>
                            {({ selected }) => (
                              <div className="w-full">
                                <label
                                  htmlFor="document"
                                  className="block text-xs text-text-color border-b border-border-color pb-1 mb-2 font-black"
                                >
                                  {document?.documentType}
                                </label>
                                <button
                                  type="button"
                                  className={`${
                                    selected
                                      ? 'border border-primary'
                                      : 'border-main-bg'
                                  } px-3 py-1 bg-light-primary border border-primary rounded-sm text-text-color`}
                                >
                                  <div className="flex justify-center items-center gap-2">
                                    <AiOutlineUpload className="text-primary text-lg" />
                                    {' '}
                                    <span className="text-exs">
                                      View Document
                                    </span>
                                  </div>
                                </button>
                              </div>
                            )}
                          </Tab>
                        ))}
                    </Tab.List>
                    <Tab.Panels
                      className="w-full md:w-2/3"
                      style={{ minHeight: 'calc(70vh - 60px)', height: 'auto' }}
                    >
                      {merchant && merchant?.documents?.length > 0 ? (
                        merchant?.documents?.map((document) => (
                          <Tab.Panel as={Fragment}>
                            {document?.documentPath.includes('pdf') ? (
                              <a
                                href={document.documentPath}
                                target="_blank"
                                rel="noopener noreferrer"
                                download={document.documentPath}
                              >
                                <object
                                  data={document.documentPath}
                                  width="100%"
                                  height="100%"
                                  type="application/pdf"
                                >
                                  <p>
                                    <a href={document.documentPath}>
                                      View Document -
                                      {' '}
                                      {document.documentPath}
                                    </a>
                                  </p>
                                </object>
                              </a>
                            ) : (
                              <a
                                href={document.documentPath}
                                target="_blank"
                                rel="noopener noreferrer"
                                download
                              >
                                <img
                                  src={document.documentPath}
                                  alt={document.documentPath}
                                />
                              </a>
                            )}
                          </Tab.Panel>
                        ))
                      ) : (
                        <Tab.Panel>
                          <p>No Document</p>
                        </Tab.Panel>
                      )}
                    </Tab.Panels>
                  </div>
                </Tab.Group>
              ) : (
                <p>No document has been uploaded by merchant</p>
              )}
            </Tab.Panel>
            <Tab.Panel
              className="px-5 py-4 walletDetails"
              style={{ minHeight: 'calc(70vh - 60px)' }}
            >
              <form>
                <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                  <TextInput
                    className="w-full lg:w-1/3 disabled: bg-white"
                    label="Wallet Title"
                    name="walletTitle"
                    value={merchant?.walletDetail?.walletTitle}
                    disabled
                  />
                  <TextInput
                    className="w-full lg:w-1/3 disabled: bg-white"
                    label="BVN"
                    name="bvn"
                    value={merchant?.walletDetail?.bvn}
                    disabled
                  />
                  <TextInput
                    className="w-full lg:w-1/3 disabled: bg-white"
                    label="Wallet Tier"
                    name="walletTier"
                    value={merchant?.walletDetail?.walletTier}
                    disabled
                  />
                </div>
                <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                  <TextInput
                    className="w-full lg:w-1/3 disabled: bg-white"
                    label="Wallet ID"
                    name="walletID"
                    value={merchant?.walletDetail?.walletID}
                    disabled
                  />
                  <TextInput
                    className="w-full lg:w-1/3 disabled: bg-white"
                    type="number"
                    label="Wallet Limit"
                    name="walletLimit"
                    value={merchant?.walletDetail?.limit}
                    disabled
                  />
                  <TextInput
                    className="w-full lg:w-1/3 disabled: bg-white"
                    label="#"
                    name=""
                    disabled
                  />
                </div>
                <div className="mt-10 flex flex-col gap-5 justify-center w-full sm:flex-row">
                  <button
                    type="button"
                    className="bg-primary text-white font-black w-full rounded-sm shadow-md py-3 hover:bg-opacity-70 focus:bg-opacity-70 disabled:bg-opacity-30 disabled:text-white sm:w-1/4"
                    disabled
                  >
                    Update Wallet
                  </button>
                  <button
                    type="button"
                    className="bg-primary text-white font-black w-full rounded-sm shadow-md py-3 hover:bg-opacity-70 focus:bg-opacity-70 disabled:bg-opacity-30 disabled:text-white sm:w-1/4"
                    disabled
                  >
                    Update Wallet Details
                  </button>
                </div>
              </form>
              <div className="mt-10 flex justify-center">
                <form className="w-full md:w-1/2">
                  <FormSubheader header="Developers Key" />

                  <TextInput
                    className="w-full mb-5"
                    label="Public Key"
                    name="publicKey"
                    value={merchant?.apiCredential?.publicKey}
                    enableAction
                    callAction={() => navigator.clipboard.writeText(
                      merchant?.apiCredential?.publicKey
                    )}
                    actionName="Copy"
                    disabled
                  />

                  <TextInput
                    className="w-full mb-5"
                    label="Secret Key"
                    name="secretKey"
                    value={merchant?.apiCredential?.secretKey}
                    enableAction
                    callAction={() => navigator.clipboard.writeText(
                      merchant?.apiCredential?.secretKey
                    )}
                    actionName="Copy"
                    disabled
                  />

                  <TextInput
                    className="w-full mb-5"
                    label="Web Hook"
                    name="webHook"
                    value={merchant?.apiCredential?.webHook}
                    enableAction
                    actionName="Update"
                    disabled
                  />
                </form>
              </div>
            </Tab.Panel>
            <Tab.Panel
              className="px-5 transactions"
              style={{ minHeight: 'calc(70vh - 60px)' }}
            >
              <div className="border bg-light-primary my-10 py-12 px-2 flex flex-wrap justify-center gap-4">
                <StatsCardCharts
                  title="Total sales"
                  stats={[formatCurrency(stats.totalSales), '']}
                />
                <StatsCardCharts
                  title="Total Inflow"
                  stats={[formatCurrency(stats.totalInflow), '']}
                />
                <StatsCardCharts
                  title="Total balance"
                  stats={[formatCurrency(stats.totalBalance), '']}
                  statColor="text-card-green"
                />
                <StatsCardCharts
                  title="Total transaction count"
                  stats={[stats.totalCount, '']}
                  statColor="text-card-green"
                />
              </div>
              <div className="mt-4 no-scrollbar overflow-hidden overflow-x-auto">
                <TableLight
                  headers={transactionHeader}
                  stats={{
                    count: totalCount,
                    currentCount: transactions.length,
                    totalCount
                  }}
                  headerStyle="pl-0 border-t-primary border-t-4 border-b-border-color border-b-4"
                >
                  {transactions.length > 0 ? (
                    transactions.map((transaction, index) => (
                      <tr className="text-text-color" key={transaction.id}>
                        <td className="border-b border-b-card-lightblue py-4 pr-4">
                          {(pageNumber - 1) * pageSize + index + 1}
                        </td>
                        <td className="border-b border-b-card-lightblue py-4 pr-4">
                          {formatDateTime(transaction.transactionDate)}
                        </td>
                        <td className="border-b border-b-card-lightblue py-4 pr-4">
                          {transaction.transactionType}
                        </td>
                        <td className="border-b border-b-card-lightblue py-4 pr-4">
                          {transaction.transactionReference}
                        </td>
                        <td className="border-b border-b-card-lightblue py-4 pr-4">
                          {transaction.narration}
                        </td>
                        <td
                          className={`border-b border-b-card-lightblue py-4 pr-4 font-black ${
                            (transaction?.isInflow)
                            && 'text-card-green'
                          } ${
                            (transaction?.isOutflow)
                            && 'text-card-red'
                          }`}
                        >
                          {formatCurrencyNoNaira(transaction.amount)}
                        </td>
                        <td
                          className={`border-b border-b-card-lightblue py-4 pr-4 font-black ${
                            (transaction?.isInflow)
                            && 'text-card-green'
                          } ${
                            (transaction?.isOutflow)
                            && 'text-card-red'
                          }`}
                        >
                          {formatCurrencyNoNaira(transaction.balance)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-text-color">
                      <td
                        className="border-b border-b-card-lightblue py-4 pr-4"
                        colSpan={transactionHeader.length}
                      >
                        No transaction
                      </td>
                    </tr>
                  )}
                  {transactions.length > 0 && (
                    <tr className="text-text-color">
                      <td
                        colSpan={transactionHeader.length - 2}
                        className="p-3 pl-4"
                      >
                        Showing
                        {' '}
                        <span>{pageNumber}</span>
                        {' '}
                        to
                        {' '}
                        <span>{totalPage}</span>
                        {' '}
                        of
                        {' '}
                        <span>{totalCount}</span>
                        {' '}
                        entries
                      </td>
                      <td
                        colSpan={2}
                        className="border-b border-b-card-lightblue py-4 px-4"
                      >
                        <div className="flex justify-center gap-2">
                          <button
                            type="button"
                            className="px-4 py-2 bg-card-lightyellow border border-card-yellow rounded-sm text-black items-center"
                            disabled={pageNumber === 1}
                            onClick={() => setPageNumber(pageNumber - 1)}
                          >
                            <BsChevronDoubleLeft />
                          </button>
                          <button
                            type="button"
                            className="px-4 py-2 bg-card-lightblue border border-card-blue rounded-sm text-black"
                            disabled={pageNumber === totalPage}
                            onClick={() => setPageNumber(pageNumber + 1)}
                          >
                            <BsChevronDoubleRight />
                            {' '}
                          </button>
                        </div>
                      </td>
                    </tr>
                  )}
                </TableLight>
              </div>
            </Tab.Panel>
          </HorizontalTab>
        </div>
        <div className="w-full flex justify-center">
          <button
            type="button"
            className="m-4 px-4 py-2 border border-1 border-primary rounded-sm text-black items-center transition ease-in-out hover:bg-opacity-70 disabled:bg-opacity-30"
            onClick={() => navigate(-1)}
          >
            <BsFillCaretLeftFill className="text-primary inline" />
            {' '}
            <span className="text-primary">Back</span>
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SingleMerchant;
