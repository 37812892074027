import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { BsFillCaretLeftFill, BsFillCaretRightFill } from 'react-icons/bs';
import Footer from '../../components/common/Footer';
import SectionHeader from '../../components/common/SectionHeader';
import ProfilePic from '../../assets/images/placeholder.png';
import notification from '../../utils/notification';
import handleFetch from '../../api/handleFetch';
import Loading from '../../components/common/Loading';
import { formatDateTime } from '../../utils/dateTime';

function Merchants() {
  const [pageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [merchants, setMerchants] = useState([]);
  // Mutations

  const getMerchantsMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        setMerchants(res?.data?.merchants);
        setTotalPage(res?.data?.totalPage);
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const updateStatusMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        notification({
          title: 'Success',
          message: res?.message,
          type: 'success'
        });

        getMerchantsMutation.mutate({
          endpoint: 'adminGetMerchants',
          auth: true,
          method: 'GET',
          pQuery: { pageNumber, pageSize }
        });
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const updateStatus = (e, merchantId, profileStatus) => {
    e.preventDefault();

    updateStatusMutation.mutate({
      endpoint: 'adminUpdateStatus',
      body: {
        profileStatus
      },
      method: 'POST',
      param: merchantId,
      auth: true
    });
  };

  useEffect(() => {
    getMerchantsMutation.mutate({
      endpoint: 'adminGetMerchants',
      auth: true,
      method: 'GET',
      pQuery: { pageNumber, pageSize }
    });
  }, [pageNumber]);

  return (
    <div className="py-10 px-5 w-full md:px-10">
      {(getMerchantsMutation.isLoading || updateStatusMutation.isLoading) && (
        <Loading />
      )}
      <SectionHeader title="Manage Merchant List" />
      <div style={{ minHeight: 'calc(60vh - 72px)' }} className="mt-10">
        {merchants.length > 0 ? (
          merchants.map((merchant) => (
            <div
              className="merchant-box bg-white px-4 py-3 flex flex-col mb-5 justify-between items-center md:flex-row gap-5"
              index={merchant.merchantId}
            >
              <div className="flex justify-between gap-5 items-center">
                <Link
                  to={`/superadmin/merchants/${merchant?.merchantId}`}
                  className="w-full bg-light-gray rounded-full overflow-hidden shadow-md w-16 h-16"
                >
                  <img src={ProfilePic} alt="" className="" />
                </Link>
                <div>
                  <Link
                    to={`/superadmin/merchants/${merchant?.merchantId}`}
                    className="font-black capitalize text-center md:text-left"
                  >
                    {merchant?.businessName}
                  </Link>
                  <div className="my-1 text-center md:text-left">
                    {merchant?.profileStatus === 'Pending' && (
                      <span className="text-exs border border-card-blue py-0.5 px-2.5 mr-2 bg-card-lightblue">
                        pending
                      </span>
                    )}
                    {merchant?.profileStatus === 'Verified' && (
                      <span className="text-exs border border-card-green py-0.5 px-2.5 mr-2 text-card-green bg-card-lightgreen">
                        verified
                      </span>
                    )}
                    {merchant?.profileStatus === 'Suspended' && (
                      <span className="text-exs border border-card-yellow py-0.5 px-2.5 bg-card-lightyellow mr-2">
                        suspended
                      </span>
                    )}
                    {merchant?.profileStatus === 'Rejected' && (
                      <span className="text-exs border border-card-red py-0.5 px-2.5 mr-2 bg-card-lightred text-card-red">
                        rejected
                      </span>
                    )}
                    <span className="text-xs block my-0.5 md:inline my:0">
                      {formatDateTime(merchant?.verifiedDate)}
                    </span>
                  </div>
                  <p className="text-xs text-text-color">{merchant?.email}</p>
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <button
                  type="button"
                  className="bg-card-lightgreen border border-card-green border-2 rounded-sm px-4 py-1.5 shadow-dashboard-card hover:bg-opacity-70 focus:bg-opacity-70 disabled:border-border-color disabled:text-text-color transition ease-in-out hover:bg-opacity-70"
                  disabled={merchant?.profileStatus === 'Verified'}
                  onClick={(e) => updateStatus(e, merchant?.merchantId, 1)}
                >
                  Verify
                </button>
                <button
                  type="button"
                  className="bg-card-lightyellow border border-card-yellow border-2 rounded-sm px-4 py-1.5 shadow-dashboard-card hover:bg-opacity-70 focus:bg-opacity-70 disabled:border-border-color disabled:text-text-color transition ease-in-out hover:bg-opacity-70"
                  disabled={merchant?.profileStatus === 'Suspended'}
                  onClick={(e) => updateStatus(e, merchant?.merchantId, 2)}
                >
                  Suspend
                </button>
                <button
                  type="button"
                  className="bg-card-lightred border border-card-red border-2 rounded-sm px-4 py-1.5 shadow-dashboard-card hover:bg-opacity-70 focus:bg-opacity-70 disabled:border-border-color disabled:text-text-color transition ease-in-out hover:bg-opacity-70"
                  disabled={
                    merchant?.profileStatus === 'Rejected'
                  }
                  onClick={(e) => updateStatus(e, merchant?.merchantId, 4)}
                >
                  Reject
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No merchants</p>
        )}

        {merchants.length > 1 && (
          <div className="mt-8">
            <div className="border-t border-t-1 border-border-color flex justify-end py-4 gap-2">
              <button
                type="button"
                className="px-4 py-2 bg-primary border border-card-yellow rounded-sm text-black items-center transition ease-in-out hover:bg-opacity-70 disabled:bg-opacity-30"
                disabled={pageNumber === 1}
                onClick={() => setPageNumber(pageNumber - 1)}
              >
                <BsFillCaretLeftFill className="text-white" />
              </button>
              <input
                className="p-2 w-14 outline-none border border-primary rounded-sm text-black items-center text-text-color"
                value={`${pageNumber} of ${totalPage}`}
                onChange={(e) => setPageNumber(e.value)}
                disabled
              />
              <button
                type="button"
                className="px-4 py-2 bg-primary border border-primary rounded-sm text-black transition ease-in-out disabled:bg-opacity-30 hover:bg-opacity-70"
                disabled={pageNumber === totalPage}
                onClick={() => setPageNumber(pageNumber + 1)}
              >
                <BsFillCaretRightFill className="text-white" />
                {' '}
              </button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Merchants;
