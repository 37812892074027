/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CookiesProvider, useCookies } from 'react-cookie';
import { ReactNotifications } from 'react-notifications-component';

import Homepage from './pages/Homepage';
import Signup from './pages/signup/Index';
import LostPage from './pages/404Page';

import AdminRoutes from './routes/AdminRoutes';
import UnprotectedRoute from './routes/UnprotectedRoute';
import ResetPassword from './pages/reset-password/ResetPassword';
import ForgetPassword from './pages/forget-password/ForgetPassword';
import { GlobalDebug } from './utils/remove-consoles';
import AdminProtectedRoute from './routes/AdminProtectedRoute';
import SuperAdminProtectedRoute from './routes/SuperAdminProtectedRoute';
import SuperAdminRoutes from './routes/SuperAdminRoutes';
import VerifyEmail from './pages/verify-email/VerifyEmail';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  const [, setCookie] = useCookies(['data']);

  const [isAuth, setIsAuth] = useState(
    localStorage.getItem('isAuth') === 'true'
  );

  const [isSuperAdmin, setIsSuperAdmin] = useState(
    sessionStorage.getItem('isSuperAdmin') === 'true'
  );

  const Logout = async () => {
    setCookie('data', '');
    setIsAuth(false);
    setIsSuperAdmin(false);
    localStorage.clear();
    sessionStorage.clear();
  };

  useEffect(() => {
    const auth = localStorage.getItem('isAuth');

    if (auth === 'true') {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);

  useEffect(() => {
    const superadminAuth = sessionStorage.getItem('isSuperAdmin');

    if (superadminAuth === 'true') {
      setIsSuperAdmin(true);
    } else {
      setIsSuperAdmin(false);
    }
  }, []);

  useEffect(() => {
    (process.env.NODE_ENV === 'production'
      || process.env.REACT_APP_ENV === 'STAGING')
      && GlobalDebug(false);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <BrowserRouter>
          <ReactNotifications />
          <Routes>
            <Route element={<UnprotectedRoute isAuth={isAuth} />}>
              <Route
                path="/"
                exact
                element={(
                  <Homepage
                    setAuth={() => setIsAuth(true)}
                    setSuperAdmin={() => setIsSuperAdmin(true)}
                  />
                )}
              />
            </Route>

            <Route element={<UnprotectedRoute isAuth={isAuth} />}>
              <Route path="/signup" exact element={<Signup />} />
            </Route>

            <Route element={<UnprotectedRoute isAuth={isAuth} />}>
              <Route
                path="/reset-password/:email"
                exact
                element={<ResetPassword />}
              />
            </Route>

            <Route
              path="/verify-email/:userId"
              exact
              element={<VerifyEmail />}
            />

            <Route element={<UnprotectedRoute isAuth={isAuth} />}>
              <Route
                path="/forget-password"
                exact
                element={<ForgetPassword />}
              />
            </Route>

            <Route element={<AdminProtectedRoute isAuth={isAuth} />}>
              <Route
                path="/admin/*"
                element={<AdminRoutes Logout={() => Logout()} />}
              />
            </Route>

            <Route
              element={<SuperAdminProtectedRoute isSuperAdmin={isSuperAdmin} />}
            >
              <Route
                path="/superadmin/*"
                element={<SuperAdminRoutes Logout={() => Logout()} />}
              />
            </Route>

            <Route path="*" element={<LostPage />} />
          </Routes>
        </BrowserRouter>
      </CookiesProvider>
    </QueryClientProvider>
  );
}
export default App;
