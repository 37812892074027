/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable eol-last */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const UnprotectedRoute = ({
  isAuth,
  redirectPath = '/admin/dashboard',
  children
}) => {
  if (isAuth) {
    return <Navigate to={redirectPath} replace />;
  }

  return children || <Outlet />;
};

export default UnprotectedRoute;
