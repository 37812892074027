/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import { useMutation } from 'react-query';
import { useCookies } from 'react-cookie';
import notification from '../../utils/notification';
import handleFetch from '../../api/handleFetch';
import Loading from '../../components/common/Loading';
import SectionHeader from '../../components/common/SectionHeader';
import CustomTab from '../../components/common/CustomTab';
import FormHeader from '../../components/layouts/FormHeader';
import TextInput from '../../components/inputs/TextInput';
import Button from '../../components/layouts/Button';
// import { formatCurrency } from '../../utils/general';
import SelectInput from '../../components/inputs/SelectInput';
import Footer from '../../components/common/Footer';
import SuccessModal from '../../components/common/SuccessModal';

const menus = [
  'To a OneFinance Contact',
  'To Multiple OneFinance Contacts',
  'To a Bank Account',
  'To Multiple Bank Accounts'
];

function SendMoney() {
  const [cookies] = useCookies();
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [merchantList, setMerchantList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [confirmMerchant, setConfirmMerchant] = useState('');
  const [walletAccount, setWalletAccount] = useState('');

  const [transferMerchantWalletForm, setTransferMerchantWalletForm] = useState({
    merchantId: '',
    accountNumber: '',
    amount: 0,
    pin: ''
  });

  const [transferToBankForm, setTransferToBankForm] = useState({
    pin: '',
    destinationAccountNumber: '',
    bankCode: '',
    amount: 0,
    narration: ''
  });

  // FormChange

  const confirmMerchantChange = (value) => {
    setConfirmMerchant(merchantList.filter((w) => w.value === value)[0]?.label);
  };

  const confirmWalletAccountChange = (value) => {
    const accountValue = merchantList.filter((w) => w.value === value)[0]
      ?.label;
    setWalletAccount(accountValue?.split('-')[1]);
  };

  const transferMerchantWalletFormChange = (e) => {
    const { name, value } = e.target;
    setTransferMerchantWalletForm((state) => ({ ...state, [name]: value }));

    if (name === 'merchantId') {
      confirmMerchantChange(value);
      confirmWalletAccountChange(value);
    }
  };

  const handleTransferToBankFormChange = (e) => {
    const { name, value } = e.target;
    setTransferToBankForm((state) => ({ ...state, [name]: value }));
  };

  const handleBankSelect = (value, name) => {
    setTransferToBankForm((state) => ({ ...state, [name]: value }));
  };

  // Validations
  const validateTransferMerchantWalletForm = () => {
    if (
      !transferMerchantWalletForm?.amount
      || transferMerchantWalletForm?.amount < 1
    ) {
      return 'Amount is required';
    }
    if (!transferMerchantWalletForm?.pin) return 'Pin is required';
    return false;
  };

  const validateTransferToBankForm = () => {
    if (!transferToBankForm?.bankCode) return 'Please select a Bank';
    if (!transferToBankForm?.destinationAccountNumber) {
      return 'Account Number is required';
    }
    if (!transferToBankForm?.pin) return 'Pin is required';
    if (!transferToBankForm?.amount || transferToBankForm?.amount < 1) {
      return 'Amount is required';
    }

    return false;
  };

  // Mutations

  const getAllMerchantsMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        const merchants = res?.data?.merchants;

        if (merchants?.length > 0) {
          const merchantsBuild = [];
          const accountBuild = [];

          merchants.map((y) => {
            merchantsBuild.push({
              label: `${y.businessName?.toUpperCase()}-${y.accountNumber}-${
                y.profileStatus
              }`,
              value: y.merchantId
            });
            accountBuild.push({
              label: `${y.businessName?.toUpperCase()}-${y.profileStatus}`,
              value: y.accountNumber
            });
          });

          setMerchantList(merchantsBuild);
          setAccountList(accountBuild);
        }
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const getBanksMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        const banks = res?.data;

        if (banks?.length > 0) {
          const bankBuild = [];

          banks.map((y) => bankBuild.push({
            label: `${y.name}`,
            value: y.code
          }));

          setBankList(bankBuild);
        }
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const transferMerchantWalletMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        setIsOpen(!isOpen);

        setMessage(res?.message);

        setTransferMerchantWalletForm({
          accountNumber: '',
          amount: 0,
          pin: ''
        });

        setConfirmMerchant('');
        setWalletAccount('');
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const transferToBankMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        setIsOpen(!isOpen);

        setMessage(res?.message);

        setTransferToBankForm({
          amount: 0,
          bankCode: '',
          destinationAccountNumber: '',
          narration: '',
          pin: ''
        });
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  // Submit actions
  const transferMerchantWallet = (e) => {
    e.preventDefault();

    const error = validateTransferMerchantWalletForm();

    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }

    transferMerchantWalletMutation.mutate({
      endpoint: 'adminTransferToMerchantWallet',
      body: {
        accountNumber: walletAccount,
        amount: +transferMerchantWalletForm.amount,
        pin: transferMerchantWalletForm.pin
      },
      pQuery: {
        merchantId: encodeURIComponent(transferMerchantWalletForm.merchantId)
      },
      method: 'POST',
      auth: true
    });
  };

  console.log(walletAccount);

  const transferToBank = (e) => {
    e.preventDefault();

    const error = validateTransferToBankForm();

    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }

    transferToBankMutation.mutate({
      endpoint: 'adminTransferToBank',
      body: {
        ...transferToBankForm,
        amount: +transferToBankForm.amount,
        bankCode: transferToBankForm?.bankCode?.value
      },
      method: 'POST',
      auth: true
    });
  };

  // Use Effects
  useEffect(() => {
    getAllMerchantsMutation.mutate({
      endpoint: 'adminGetMerchants',
      auth: true,
      method: 'GET',
      pQuery: { pageNumber: 0, pageSize: 0 }
    });
  }, []);

  useEffect(() => {
    getBanksMutation.mutate({
      endpoint: 'adminGetBanks',
      auth: true,
      method: 'GET'
    });
  }, []);

  return (
    <>
      {(getAllMerchantsMutation.isLoading
        || getBanksMutation.isLoading
        || transferMerchantWalletMutation.isLoading
        || transferToBankMutation.isLoading) && <Loading />}
      <div className="py-10 px-5 w-full md:px-10">
        <SectionHeader title="Send Money" />
        <div style={{ minHeight: 'calc(70vh - 72px)' }} className="mt-10">
          <CustomTab menus={menus}>
            <Tab.Panel
              className="w-full h-full m-0 flex justify-center items-start"
              style={{ minHeight: 'calc(70vh - 60px)' }}
            >
              <div className="w-full max-w-sm p-5 bg-white rounded-lg h-full">
                <FormHeader header="Send to a OneFinance Contact" />
                <form onSubmit={transferMerchantWallet} autoComplete="off">
                  <TextInput
                    className="mb-5"
                    label="Merchant ID"
                    name="merchantId"
                    enableDataList
                    listName="merchantList"
                    dataLists={merchantList}
                    value={transferMerchantWalletForm.merchantId}
                    onChange={transferMerchantWalletFormChange}
                  />
                  <TextInput
                    className="mb-5"
                    label="Confirm Merchant"
                    name="confirmMerchant"
                    value={confirmMerchant}
                    disabled
                  />
                  <TextInput
                    className="mb-5"
                    label="Wallet/Account Number"
                    name="accountNumber"
                    value={walletAccount}
                    disabled
                  />
                  <TextInput
                    className="mb-5"
                    label="Enter Amount"
                    name="amount"
                    type="number"
                    value={transferMerchantWalletForm.amount}
                    onChange={transferMerchantWalletFormChange}
                  />
                  <TextInput
                    className="mb-5"
                    label="Enter Pin"
                    name="pin"
                    value={transferMerchantWalletForm.pin}
                    onChange={transferMerchantWalletFormChange}
                  />
                  <Button type="submit" paddingY="py-3" className="w-full">
                    Continue
                  </Button>
                  <p className="w-full text-primary text-xs text-center pt-3">
                    {/* formatCurrency('0.00') */}
                  </p>
                </form>
              </div>
            </Tab.Panel>
            <Tab.Panel
              className="w-full h-full m-0 flex justify-center items-start"
              style={{ minHeight: 'calc(70vh - 60px)' }}
            >
              <div className="w-full max-w-sm p-5 bg-white rounded-lg h-full">
                <FormHeader header="Multiple OneFinance Contacts" />
                <p className="text-center">
                  <span className="text-card-red">**</span>
                  {' '}
                  Sorry this
                  functionality does not exist yet. You will be notified when
                  this feature is ready for use. Thanks for your consideration
                </p>
                {/*
              <form>
                <SelectInput
                  className="mb-5"
                  label="Wallet/Account Number"
                  name="account_no"
                  options={[
                  ]}
                />
                <TextInput
                  className="mb-5"
                  label="Confirm Name"
                  name="name"
                  value=""
                />
                <TextInput
                  className="mb-5"
                  label="Enter Amount"
                  name="amount"
                  value=""
                />
                <TextInput
                  className="mb-5"
                  label="Add Note"
                  name="note"
                  value=""
                />
                <Button type="submit" paddingY="py-3" className="w-full">Continue</Button>
                <p className="w-full text-primary text-xs text-center pt-3">
                    {formatCurrency(walletData?.totalBalance)}
                </p>
              </form>
                */}
              </div>
            </Tab.Panel>
            <Tab.Panel
              className="w-full h-full m-0 flex justify-center items-start"
              style={{ minHeight: 'calc(70vh - 60px)' }}
            >
              <div className="w-full max-w-sm p-5 bg-white rounded-lg h-full">
                <FormHeader header="Send to a Bank Account" />
                <form autoComplete="off" onSubmit={transferToBank}>
                  <SelectInput
                    className="mb-5"
                    label="Select Bank"
                    name="bankCode"
                    options={bankList}
                    value={transferToBankForm?.bankCode}
                    onChange={(e) => handleBankSelect(e, 'bankCode')}
                  />
                  <TextInput
                    className="mb-5"
                    label="Account Number"
                    name="destinationAccountNumber"
                    enableDataList
                    listName="accountList"
                    dataLists={accountList}
                    value={transferToBankForm?.destinationAccountNumber}
                    onChange={handleTransferToBankFormChange}
                  />
                  <TextInput
                    className="mb-5"
                    label="Amount"
                    type="number"
                    name="amount"
                    value={transferToBankForm?.amount}
                    onChange={handleTransferToBankFormChange}
                  />
                  <TextInput
                    className="mb-5"
                    label="Pin"
                    name="pin"
                    value={transferToBankForm?.pin}
                    onChange={handleTransferToBankFormChange}
                  />
                  <TextInput
                    className="mb-5"
                    label="Add Note"
                    name="narration"
                    value={transferToBankForm?.narration}
                    onChange={handleTransferToBankFormChange}
                  />
                  <Button type="submit" paddingY="py-3" className="w-full">
                    Continue
                  </Button>
                  <p className="w-full text-primary text-xs text-center pt-3">
                    {/* formatCurrency(0) */}
                  </p>
                </form>
              </div>
            </Tab.Panel>
            <Tab.Panel
              className="w-full h-full m-0 flex justify-center items-start"
              style={{ minHeight: 'calc(70vh - 60px)' }}
            >
              <div className="w-full max-w-sm p-5 bg-white rounded-lg h-full">
                <FormHeader header="Send to Multiple Bank Accounts" />
                <p className="text-center">
                  <span className="text-card-red">**</span>
                  {' '}
                  Sorry this
                  functionality does not exist yet. You will be notified when
                  this feature is ready for use. Thanks for your consideration
                </p>
                {/*
              <form>
                <SelectInput
                  className="mb-5"
                  label="Wallet/Account Number"
                  name="account_no"
                  options={[
                  ]}
                />
                <TextInput
                  className="mb-5"
                  label="Confirm Name"
                  name="name"
                  value=""
                />
                <TextInput
                  className="mb-5"
                  label="Enter Amount"
                  name="amount"
                  value=""
                />
                <TextInput
                  className="mb-5"
                  label="Add Note"
                  name="note"
                  value=""
                />
                <Button type="submit" paddingY="py-3" className="w-full">Continue</Button>
                <p className="w-full text-primary text-xs text-center pt-3">
                    {formatCurrency(walletData?.totalBalance)}
                </p>
              </form>
                */}
              </div>
            </Tab.Panel>
          </CustomTab>
        </div>
        <Footer />
        {isOpen && (
          <SuccessModal
            onClose={() => setIsOpen(!isOpen)}
            title={cookies?.data?.userDetail?.lastName}
            message={message}
          />
        )}
      </div>
    </>
  );
}

export default SendMoney;
