/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { BsChevronDoubleRight, BsChevronDoubleLeft } from 'react-icons/bs';
import handleFetch from '../../api/handleFetch';
import notification from '../../utils/notification';
import SectionHeader from '../../components/common/SectionHeader';
import StatsCardCharts from '../../components/common/StatsCardCharts';
import { formatCurrency, formatCurrencyNoNaira } from '../../utils/general';
import TableLight from '../../components/common/TableLight';
import { formatDateTime } from '../../utils/dateTime';
import Footer from '../../components/common/Footer';
import Loading from '../../components/common/Loading';

const transactionHeader = [
  'S/N',
  'Date/Time',
  'Category',
  'Reference',
  'Narration',
  'Amount (₦)',
  'Balance (₦)'
];

function Transactions() {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [stats, setStats] = useState({
    totalSales: 0,
    totalInflow: 0,
    totalBalance: 0,
    totalCount: 0
  });

  // Mutations
  const getTransactionMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        setTransactions(res?.data?.transactions);
        setTotalCount(res?.data?.totalCount);
        setTotalPage(res?.data?.totalPage);
        setStats({
          ...stats,
          totalBalance: res?.data?.totalBalance,
          totalCount: res?.data?.totalCount,
          totalInflow: res?.data?.totalInflow,
          totalSales: res?.data?.totalSales
        });
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  useEffect(() => {
    getTransactionMutation.mutate({
      endpoint: 'adminGetAllTransactions',
      auth: true,
      method: 'GET',
      pQuery: { pageNumber, pageSize }
    });
  }, [pageNumber]);

  return (
    <>
      {getTransactionMutation.isLoading && <Loading />}
      <div className="py-10 px-5 w-full md:px-10">
        <SectionHeader title="Manage Transactions" />
        <div style={{ minHeight: 'calc(70vh - 72px)' }} className="mt-10">
          <div className="flex flex-wrap justify-center gap-4">
            <StatsCardCharts
              title="Total sales"
              stats={[formatCurrency(stats.totalSales), '']}
            />
            <StatsCardCharts
              title="Total Inflow"
              stats={[formatCurrency(stats.totalInflow), '']}
            />
            <StatsCardCharts
              title="Total balance"
              stats={[formatCurrency(stats.totalBalance), '']}
              statColor="text-card-green"
            />
            <StatsCardCharts
              title="Total transaction count"
              stats={[stats.totalCount, '']}
              statColor="text-card-green"
            />
          </div>
          <div className="mt-14 mb-3">
            <div className="flex justify-between items-center">
              <h1 className="text-base font-semibold text-text-color font-arial md:text-lg">
                Transaction History
              </h1>
              <button
                type="button"
                className="hidden bg-white border border-primary border-2 rounded-sm px-6 py-1.5 shadow-dashboard-card hover:bg-opacity-70 focus:bg-opacity-70 disabled:bg-opacity-30 disabled:text-text-color"
              >
                View More
              </button>
            </div>
          </div>
          <div className="no-scrollbar overflow-hidden w-full overflow-x-auto">
            <TableLight headers={transactionHeader}>
              {transactions.length > 0 ? (
                transactions.map((transaction, index) => (
                  <tr className="text-text-color" key={transaction.id}>
                    <td className="border-b border-b-card-lightblue py-4 px-4">
                      {(pageNumber - 1) * pageSize + index + 1}
                    </td>
                    <td className="border-b border-b-card-lightblue py-4 px-4">
                      {formatDateTime(transaction.transactionDate)}
                    </td>
                    <td className="border-b border-b-card-lightblue py-4 px-4">
                      {transaction.transactionType}
                    </td>
                    <td className="border-b border-b-card-lightblue py-4 px-4">
                      {transaction.transactionReference}
                    </td>
                    <td className="border-b border-b-card-lightblue py-4 px-4">
                      {transaction.narration}
                    </td>
                    <td
                      className={`border-b border-b-card-lightblue py-4 px-4 font-black ${
                        (transaction?.isInflow)
                        && 'text-card-green'
                      } ${
                        (transaction?.isOutflow)
                        && 'text-card-red'
                      }`}
                    >
                      {formatCurrencyNoNaira(transaction.amount)}
                    </td>
                    <td
                      className={`border-b border-b-card-lightblue py-4 px-4 font-black  ${
                        (transaction?.isInflow)
                        && 'text-card-green'
                      } ${
                        (transaction?.isOutflow)
                        && 'text-card-red'
                      }`}
                    >
                      {formatCurrencyNoNaira(transaction.balance)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-text-color">
                  <td
                    className="border-b border-b-card-lightblue py-4 px-4"
                    colSpan={transactionHeader.length}
                  >
                    No transaction
                  </td>
                </tr>
              )}
              {transactions.length > 0 && (
                <tr className="text-text-color">
                  <td
                    colSpan={transactionHeader.length - 2}
                    className="p-3 pl-4"
                  >
                    Showing
                    {' '}
                    <span>{pageNumber}</span>
                    {' '}
                    to
                    {' '}
                    <span>{totalPage}</span>
                    {' '}
                    of
                    {' '}
                    <span>{totalCount}</span>
                    {' '}
                    entries
                  </td>
                  <td
                    colSpan={2}
                    className="border-b border-b-card-lightblue py-4 px-4"
                  >
                    <div className="flex justify-center gap-2">
                      <button
                        type="button"
                        className="px-4 py-2 bg-card-lightyellow border border-card-yellow rounded-sm text-black items-center"
                        disabled={pageNumber === 1}
                        onClick={() => setPageNumber(pageNumber - 1)}
                      >
                        <BsChevronDoubleLeft />
                      </button>
                      {/*
                    <input className="p-2 w-12 outline-none border border-border-color rounded-sm text-black items-center" value={pageNumber} onChange={(e) => setPageNumber(e.value)} />
                    */}
                      <button
                        type="button"
                        className="px-4 py-2 bg-card-lightblue border border-card-blue rounded-sm text-black"
                        disabled={pageNumber === totalPage}
                        onClick={() => setPageNumber(pageNumber + 1)}
                      >
                        <BsChevronDoubleRight />
                        {' '}
                      </button>
                    </div>
                  </td>
                </tr>
              )}
            </TableLight>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

// TODO: Transaction Status string determine value
// TODO: Send back response if request was forbidden
// TODO: Calculate last transactions based on date
// TODO: Color change for transaction operations

export default Transactions;
