import React from 'react';
import PropTypes from 'prop-types';

function CheckBox({
  name, value, checked, onChange, label, className
}) {
  return (
    <div className={`${className || ''} custom-checkbox relative text-left`}>
      <input
        className="absolute w-full h-full top-0 left-0 z-10"
        type="checkbox"
        name={name}
        value={value}
        id={name}
        checked={checked}
        onChange={onChange}
      />
      <label
        htmlFor={name}
        className="cursor-pointer flex items-center"
      >
        {label || ''}
      </label>
    </div>
  );
}

CheckBox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.node,
  value: PropTypes.bool
};

CheckBox.defaultProps = {
  className: '',
  name: 'check',
  onChange: () => { },
  checked: false,
  label: '',
  value: false
};

export default CheckBox;
