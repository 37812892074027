/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { BiUserCircle } from 'react-icons/bi';
import { MdDomainDisabled } from 'react-icons/md';
import { VscLayersActive } from 'react-icons/vsc';
import { RiFolderUserFill } from 'react-icons/ri';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import handleFetch from '../../api/handleFetch';
import Footer from '../../components/common/Footer';
import Loading from '../../components/common/Loading';
import SectionHeader from '../../components/common/SectionHeader';
import StatsCard from '../../components/common/StatsCard';
import StatsCardCharts from '../../components/common/StatsCardCharts';
import { formatCurrency } from '../../utils/general';
import notification from '../../utils/notification';

function Dashboard() {
  const [cookies] = useCookies(['data']);
  const navigate = useNavigate();

  if (cookies?.data?.userDetail?.profileType !== 'Admin') {
    navigate('/');
  }

  const [stats, setStats] = useState({
    activeWalletAccount: 0,
    registeredMerchant: 0,
    inActiveWalletAccount: 0,
    totalTransaction: 0,
    totalSale: 0,
    totalBalance: 0,
    totalUser: 0
  });

  const getDashboardStatMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        setStats(res?.data);
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  useEffect(() => {
    getDashboardStatMutation.mutate({
      endpoint: 'adminDashboardStats',
      auth: true,
      method: 'GET'
    });
  }, []);

  return (
    <div className="py-10 px-5 w-full md:px-10">
      {getDashboardStatMutation.isLoading && <Loading />}
      <SectionHeader title="" />
      <div style={{ minHeight: 'calc(60vh - 72px)' }} className="mt-10">
        <div className="mt-6">
          <div className="flex flex-wrap justify-center gap-4">
            <StatsCard
              title="Active Wallet Count"
              stats={[stats.activeWalletAccount, '']}
              statColor="text-card-green"
              iconBg="bg-card-lightgreen"
            >
              <VscLayersActive className="text-xl text-card-green" />
            </StatsCard>
            <StatsCard
              title="Inactive Wallet Account"
              stats={[stats.inActiveWalletAccount, '']}
              statColor="text-card-red"
              iconBg="bg-card-lightred"
            >
              <MdDomainDisabled className="text-xl text-card-red" />
            </StatsCard>
            <StatsCard
              title="Registered Merchant"
              iconBg="bg-card-lightyellow"
              stats={[stats.registeredMerchant, '']}
            >
              <RiFolderUserFill className="text-xl text-card-yellow" />
            </StatsCard>
            <StatsCardCharts
              title="Total User"
              stats={[stats.totalUser, '']}
              statColor="text-card-green"
            >
              <BiUserCircle className="text-3xl text-card-green" />
            </StatsCardCharts>
            <StatsCardCharts
              title="Total Transaction"
              stats={[formatCurrency(stats.totalTransaction), '']}
            />
            <StatsCardCharts
              title="Total Sales"
              stats={[formatCurrency(stats.totalSale), '']}
            />
            <StatsCardCharts
              title="Total balance"
              stats={[formatCurrency(stats.totalBalance), '']}
              statColor="text-card-green"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Dashboard;
