import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import Footer from '../../../components/common/Footer';
import SectionHeader from '../../../components/common/SectionHeader';
import SettingsOutline from '../../../components/common/SettingsOutline';
import ProfilePic from '../../../assets/images/placeholder.png';
import CustomCheckBox from '../../../components/inputs/CustomCheckBox';
import businessType from '../../../data/businessType';

function SettingsBusiness() {
  const [cookies] = useCookies();

  const [businesstype, setBusinessType] = useState(cookies?.data?.userDetail?.businessType);

  return (
    <div className="p-10 w-full">
      <SectionHeader title="Business Settings" />
      <SettingsOutline header="Business Settings" imgSrc={ProfilePic}>
        <form>
          <CustomCheckBox
            name="businessTransfer"
            label="Business Transfer"
            sublabel="Allow wallet customers send you money from their app"
            value=""
          />

          <div className="mb-5">
            <label
              htmlFor="businessType"
              className="block text-xs text-text-color"
            >
              Business Type
            </label>
            {businessType.map((type) => (
              <CustomCheckBox
                key={type.value}
                className="mb-2"
                name="businessType"
                type="radio"
                sublabel={type.label}
                value={type.value}
                checked={businesstype === type.value}
                onChange={(e) => setBusinessType(e.target.value)}
              />
            ))}
          </div>
        </form>
      </SettingsOutline>
      <Footer />
    </div>
  );
}

export default SettingsBusiness;
