/* eslint-disable prefer-regex-literals */
import moment from 'moment-timezone';

export const formatCurrency = (value) => {
  if (value) {
    let val = value;
    val = val ? parseFloat(val).toFixed(2) : 0.00;
    return val === 0 ? '₦0.00' : `₦${Number(val).toLocaleString('en-US')}`;
  }
  return '₦0.00';
};

export const formatCurrencyNoNaira = (value) => {
  if (value) {
    let val = value;
    val = val ? parseFloat(val).toFixed(2) : 0.00;
    return val === 0 ? '0.00' : `${Number(val).toLocaleString('en-US')}`;
  }
  return '0.00';
};

export const concealEmail = (email = '') => email.replace(/(?!^).(?=[^@]+@)/g, '*');
export const concealValue = (value = '') => (value ? value.replace(/\w/g, '*') : '');

export const logger = (...logs) => (process.env.NODE_ENV === 'development' ? console.log(...logs, `(Log time - ${moment().format('LLL')})`) : undefined);

export const format2Digits = (num) => {
  if (typeof num !== 'number' || Number.isNaN(num)) return 0;

  return num.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) || 0;
};

export const formatFileUrl = (path) => {
  if (path) return `${process.env.REACT_APP_API_BASE_URL}/${path}`;
  return null;
};

export const toggleScroll = () => {
  const overlays = document.querySelectorAll('.overlay');

  if (overlays.length > 0) {
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
  }
};

export const isNumber = (value) => {
  const reg = new RegExp('^[0-9]+$');

  return reg.test(value);
};

export const formatDateToYMD = (date) => date.split('-').reverse().join('-');

export const formatDateToDMY = (date) => date.split('-').reverse().join('-');
