import React from 'react';

import { BiHome } from 'react-icons/bi';
import { BsFileEarmarkText } from 'react-icons/bs';
import { GiReceiveMoney, GiTakeMyMoney } from 'react-icons/gi';
import { VscGraph } from 'react-icons/vsc';

const superAdminMenu = [
  {
    title: 'Dashboard',
    link: '/superadmin/dashboard',
    icon: <BiHome className="icon" />
  },
  {
    title: 'Merchant List',
    link: '/superadmin/merchants',
    icon: <VscGraph className="icon" />
  },
  {
    title: 'Manage Transactions',
    link: '/superadmin/transactions',
    icon: <BsFileEarmarkText className="icon" />
  },
  {
    title: 'Bills Payment',
    link: '/superadmin/bills-payment',
    icon: <GiReceiveMoney className="icon" />
  },
  {
    title: 'Send Money',
    link: '/superadmin/send-money',
    icon: <GiTakeMyMoney className="icon" />
  }
];

export default superAdminMenu;
