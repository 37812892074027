import React from 'react';
import PropTypes from 'prop-types';

function SettingsOutline({
  children, header, imgSrc
}) {
  return (
    <div style={{ minHeight: 'calc(70vh - 72px)' }} className="mt-10 w-full flex justify-center justify-items-center">
      <div className="w-full md:w-2/3 p-5 bg-white rounded-lg">
        <div className="flex flex-col lg:flex-row gap-5">
          <div className="p-5 flex justify-center md:justify-start">
            <div className="w-28 h-28 bg-light-gray rounded-full overflow-hidden">
              <img
                src={imgSrc}
                alt=""
                className="w-28 h-28 rounded-full"
              />
            </div>
          </div>
          <div className="w-full p-3">
            <div className="flex justify-center md:justify-start text-sm mb-10 text-title-color">
              <h1
                className="border-bg-yellow pb-1 border-b-4 font-extrabold"
              >
                {header}
              </h1>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

SettingsOutline.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string,
  imgSrc: PropTypes.string
};

SettingsOutline.defaultProps = {
  children: '',
  header: '',
  imgSrc: ''
};

export default SettingsOutline;
