import React from 'react';
import PropTypes from 'prop-types';

function ModalButton({
  children, onClick,
  disabled, type, open
}) {
  return (
    <button
      className={`${
        open ? 'flex' : 'hidden'
      } rounded-sm bg-white text-xs text-black font-black px-2 py-1 justify-center transition ease-in-out border border-primary items-center hover:bg-opacity-70 focus:bg-opacity-70 disabled:bg-opacity-30 disabled:text-white`}
      type={type === 'submit' ? 'submit' : 'button'}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

ModalButton.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  open: PropTypes.bool
};

ModalButton.defaultProps = {
  children: 'Button',
  type: 'button',
  onClick: () => { },
  disabled: false,
  open: false
};

export default ModalButton;
