import React from 'react';

function Footer() {
  return (
    <footer className="w-full inset-x-0 bottom-0 mt-4">
      <div className="max-w-7xl mx-auto">
        <div className="w-full py-3 px-0 text-center text-xs text-footer-color">
          <p className="">
            <b>SourceOne</b>
            &nbsp;Finance Manager
          </p>
          <p className="">
            support@onefinancemanager.com | +234 706 7035 892
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
