/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
// import { GrCatalog } from 'react-icons/gr';
import { BsChevronDoubleLeft, BsChevronDoubleRight } from 'react-icons/bs';
import { Tab } from '@headlessui/react';
import { VscLayersActive } from 'react-icons/vsc';
import Footer from '../../../components/common/Footer';
import SectionHeader from '../../../components/common/SectionHeader';
import StatsCard from '../../../components/common/StatsCard';
// import BarChart from '../../../assets/images/barchart.png';
import ProfilePic from '../../../assets/images/placeholder.png';
import UserIcon from '../../../assets/images/user.png';
import StatsCardCharts from '../../../components/common/StatsCardCharts';
import TableLight from '../../../components/common/TableLight';
import Modal from '../../../components/common/Modal';
import HorizontalTab from '../../../components/common/HorizontalTab';
import TextInput from '../../../components/inputs/TextInput';
// import states from '../../../data/nigeriaStates';
// import getLga from '../../../data/lgaData';
// import SelectInput from '../../../components/inputs/SelectInput';
import handleFetch from '../../../api/handleFetch';
import notification from '../../../utils/notification';
import Loading from '../../../components/common/Loading';
import {
  formatCurrency,
  formatCurrencyNoNaira,
  formatDateToYMD
} from '../../../utils/general';
import { formatDateTime } from '../../../utils/dateTime';
import { Tooltip } from '../../../components/common/Tooltip';

const tHeader = [
  'S/N',
  'Account No',
  'Customer Name',
  'Email Address',
  'Phone Number',
  'DOB',
  'View'
];

const transactionHeader = [
  'S/N',
  'Date/Time',
  'Category',
  'Reference',
  'Narration',
  'Amount (₦)',
  'Balance (₦)'
];

const menus = ['User Profile', 'Wallet Details', 'Transactions'];

function WalletsManagement() {
  const [isOpen, setIsOpen] = useState(false);
  // const [lgas, setLgas] = useState([]);

  const [singleWallet, setSingleWallet] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [defaultIndex, setDefaultIndex] = useState(0);
  const [singleWalletTransaction, setSingleWalletTransaction] = useState([]);

  const [walletData, setWalletData] = useState({
    totalSales: 0,
    totalBalance: 0,
    totalInflow: 0,
    totalCount: 0,
    totalTransaction: 0,
    wallets: []
  });

  // Form Change
  /*
  const handleSelectState = (value) => {
    setLgas(
      getLga(value?.value)?.map((item) => ({ label: item, value: item }))
    );
  }; */

  // Mutations
  const getWalletsMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        setWalletData(res?.data);
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const getTransactionMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (res?.status) {
        setSingleWalletTransaction(res?.data?.transactions);
        setTotalCount(res?.data?.totalCount);
        setTotalPage(res?.data?.totalPage);
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  // Use Effects
  useEffect(() => {
    getWalletsMutation.mutate({
      endpoint: 'getWallets',
      auth: true,
      method: 'GET'
    });
  }, []);

  // Submit actions
  const openWalletDetail = (accountName, accountNumber) => {
    setSingleWallet(
      walletData?.wallets.filter(
        (el) => el.accountName === accountName && el.accountNumber === accountNumber
      )
    );

    setPageNumber(1);

    getTransactionMutation.mutate({
      endpoint: 'getTransactions',
      param: accountNumber,
      auth: true,
      method: 'GET',
      pQuery: { pageNumber, pageSize }
    });

    setIsOpen(true);
  };

  const updateWalletProfile = (e) => {
    e.preventDefault();

    notification({
      title: '',
      message:
        'Sorry this functionality does not exist yet. You will be notified when this feature is ready for use.Thanks for your consideration',
      type: 'warning'
    });
  };

  const loadPrev = () => {
    setPageNumber(pageNumber - 1);

    getTransactionMutation.mutate({
      endpoint: 'getTransactions',
      param: singleWallet[0]?.accountNumber,
      auth: true,
      method: 'GET',
      pQuery: { pageNumber, pageSize }
    });
  };

  const loadNext = () => {
    setPageNumber(pageNumber + 1);

    getTransactionMutation.mutate({
      endpoint: 'getTransactions',
      param: singleWallet[0]?.accountNumber,
      auth: true,
      method: 'GET',
      pQuery: { pageNumber, pageSize }
    });
  };

  return (
    <>
      {(getWalletsMutation.isLoading || getTransactionMutation.isLoading) && (
        <Loading />
      )}
      <div className="py-10 px-5 w-full md:px-10">
        <SectionHeader title="Manage Wallet" />
        <div style={{ minHeight: 'calc(70vh - 72px)' }} className="mt-10">
          <div className="flex flex-wrap justify-center gap-4">
            {/*
            <StatsCard title="Active Wallet Count" stats={['34', '-25%']}>
              <GrCatalog className="text-xl" />
            </StatsCard>
            <StatsCard title="Inactive Wallet Count" iconBg="bg-card-lightyellow" statColor="text-card-green" stats={['14', '+10%']}>
              <GrCatalog className="text-xl" />
            </StatsCard>
            <StatsCard title="Funded Wallet" iconBg="bg-card-lightgreen" statColor="text-card-green" stats={['7', '+20%']}>
              <GrCatalog className="text-xl" />
            </StatsCard>
            <StatsCard title="Incomplete Wallet Profile" iconBg="bg-card-lightred" statColor="text-card-red" stats={['7', '+20%']}>
              <GrCatalog className="text-xl" />
            </StatsCard>
               <StatsCardCharts
              title="Total balance"
              stats={[formatCurrency(walletData?.totalBalance), '+7%']}
              statColor="text-card-green"
            >
              <img src={BarChart} alt="" className="w-5 h-5" />
            </StatsCardCharts>
            */}
            <StatsCard
              title="Wallet Count"
              stats={[walletData?.wallets.length, '']}
              statColor="text-card-green"
              iconBg="bg-card-lightgreen"
            >
              <VscLayersActive className="text-xl text-card-green" />
            </StatsCard>
            <StatsCardCharts
              title="Total sales"
              stats={[formatCurrency(walletData?.totalSales), '']}
            />
            <StatsCardCharts
              title="Total Inflow"
              stats={[formatCurrency(walletData?.totalInflow), '']}
            />
            <StatsCardCharts
              title="Total balance"
              stats={[formatCurrency(walletData?.totalBalance), '']}
              statColor="text-card-green"
            />
            <StatsCardCharts
              title="Total transaction count"
              stats={[walletData?.totalTransaction.toString(), '']}
              statColor="text-card-green"
            />
          </div>
          <div className="mt-14">
            <h1 className="text-base font-semibold text-text-color font-arial mb-3 md:text-lg">
              Wallet Log
            </h1>
            <div className="no-scrollbar overflow-hidden overflow-x-auto">
              <TableLight headers={tHeader}>
                {walletData?.wallets.length > 0 ? (
                  walletData.wallets.map((log, index) => (
                    <tr className="text-text-color" key={log.id}>
                      <td className="border-b border-b-card-lightblue py-1 px-4">
                        {index + 1}
                      </td>
                      <td className="border-b border-b-card-lightblue py-1 px-4">
                        {log.accountNumber}
                      </td>
                      <td className="border-b border-b-card-lightblue py-1 px-4">
                        <div className="flex gap-2 items-center">
                          <img src={UserIcon} alt="" />
                          {log.accountName}
                        </div>
                      </td>
                      <td className="border-b border-b-card-lightblue py-1 px-4">
                        {log.email}
                      </td>
                      <td className="border-b border-b-card-lightblue py-1 px-4">
                        {log.phoneNumber}
                      </td>
                      <td className="border-b border-b-card-lightblue py-1 px-4">
                        {log.dateOfBirth}
                      </td>
                      <td className="border-b border-b-card-lightblue py-1 px-4">
                        <div className="flex gap-1.5 items-center">
                          <Tooltip message="view profile">
                            <button
                              type="button"
                              className="w-7 h-7 bg-card-lightyellow border border-card-yellow rounded-sm text-black"
                              onClick={() => {
                                openWalletDetail(
                                  log.accountName,
                                  log.accountNumber
                                );
                                setDefaultIndex(0);
                              }}
                            >
                              P
                            </button>

                          </Tooltip>
                          <Tooltip message="view transactions">
                            <button
                              type="button"
                              className="w-7 h-7 bg-card-lightblue border border-card-blue rounded-sm text-black"
                              onClick={() => {
                                openWalletDetail(
                                  log.accountName,
                                  log.accountNumber
                                );
                                setDefaultIndex(2);
                              }}
                            >
                              T
                            </button>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-text-color">
                    <td
                      className="border-b border-b-card-lightblue py-4 px-4"
                      colSpan={tHeader.length}
                    >
                      No Wallet
                    </td>
                  </tr>
                )}
              </TableLight>
            </div>
          </div>
        </div>
        <Footer />
        {isOpen && (
          <Modal
            onClose={() => setIsOpen(false)}
            modalBodyStyle="w-5/6 md:w-4/6"
          >
            <div className="flex justify-center px-5 py-6 mb-2">
              <div className="w-44 h-44 bg-light-gray rounded-full overflow-hidden">
                <img
                  src={ProfilePic}
                  alt=""
                  className="w-44 h-44 rounded-full"
                />
              </div>
            </div>
            <div>
              <HorizontalTab menus={menus} defaultIndex={defaultIndex}>
                <Tab.Panel
                  className="px-5 py-4"
                  style={{ minHeight: 'calc(70vh - 60px)' }}
                >
                  <form autoComplete="off" onSubmit={updateWalletProfile}>
                    <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                      <TextInput
                        className="w-full lg:w-1/3"
                        label="First Name"
                        name="firstname"
                        value={singleWallet[0].firstName}
                        disabled
                      />
                      <TextInput
                        className="w-full lg:w-1/3"
                        label="Last Name (Surname)"
                        name="lastname"
                        value={singleWallet[0].lastNameName}
                        disabled
                      />
                      <TextInput
                        className="w-full lg:w-1/3"
                        label="Email"
                        name="email"
                        value={singleWallet[0].email}
                        disabled
                      />
                    </div>
                    <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                      <TextInput
                        className="w-full lg:w-1/2"
                        label="Date Of Birth"
                        name="dob"
                        value={formatDateToYMD(singleWallet[0].dateOfBirth)}
                        type="date"
                        disabled
                      />
                      <TextInput
                        className="w-full lg:w-1/2"
                        label="Phone Number"
                        name="phoneNumber"
                        value={singleWallet[0].phoneNumber}
                        disabled
                      />
                    </div>
                    {/*
                    <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                      <TextInput
                        className="w-full lg:w-1/3"
                        label="Nationality"
                        name="nationality"
                        value=""
                      />
                      <SelectInput
                        className="w-full lg:w-1/3"
                        label="State"
                        name="state"
                        options={states || []}
                        onChange={(e) => handleSelectState(e, 'state')}
                      />
                      <SelectInput
                        className="w-full lg:w-1/3"
                        label="LGA"
                        name="lga"
                        options={lgas || []}
                      />
                    </div>
                    <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                      <TextInput
                        className="w-full mt-1 lg:w-1/3"
                        label="Hostel"
                        name="hostel"
                        value=""
                      />
                      <TextInput
                        className="w-full lg:w-2.3"
                        label="Hostel Address"
                        name="hostelAddress"
                        value=""
                      />
                    </div>
                    */}
                    {/*
                    <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                      <SelectInput
                        className="w-full lg:w-2/3"
                        label="Security Question"
                        name="securityQuestion"
                        value=""
                        options={[
                          { label: 'Who is your father\'s best friend', value: 'Who is your father\'s best friend' },
                          { label: 'Who is your favourite author', value: 'Who is your favourite author' },
                          { label: 'Who is your favourite musician', value: 'Who is your favourite musician' }
                        ]}
                      />
                      <TextInput
                        className="w-full mt-1 lg:w-1/3"
                        label="Answer"
                        name="securityAnswer"
                        value=""
                      />
                    </div>
                      */}
                    <div className="flex flex-col w-full justify-between items-end gap-6 mb-5 lg:flex-row">
                      {/*
                      <div className="w-full lg:w-3/6">
                        <label htmlFor="id" className="block text-xs text-text-color">School ID</label>
                        <div className="mt-4 w-full bg-light-gray rounded-lg overflow-hidden shadow-md h-72 lg:h-64">
                          <img src={ProfilePic} alt="" className="" />
                        </div>
                      </div>
                      <div className="w-full text-center lg:w-2/6">
                        <button
                          type="submit"
                          className="bg-primary text-white font-black w-full rounded-sm shadow-md py-2 hover:bg-opacity-70 focus:bg-opacity-70 disabled:bg-opacity-30 disabled:text-white"
                        >
                          Update Profile
                        </button>
                      </div>
                    */}
                    </div>
                  </form>
                </Tab.Panel>
                <Tab.Panel
                  className="px-5 py-4"
                  style={{ minHeight: 'calc(70vh - 60px)' }}
                >
                  <form>
                    <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                      <TextInput
                        className="w-full lg:w-1/3 disabled: bg-white"
                        label="Account Number"
                        name="accountNumber"
                        value={singleWallet[0].accountNumber}
                        disabled
                      />
                      <TextInput
                        className="w-full lg:w-1/3"
                        label="BVN"
                        name="bvn"
                        value={singleWallet[0]?.bvn}
                        disabled
                      />
                    </div>
                    {/*
                    <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                      <TextInput
                        className="w-full lg:w-2/3"
                        label="Wallet Limit"
                        name="limit"
                        value=""
                      />
                      <TextInput
                        className="w-full lg:w-1/3"
                        label="Wallet #ID"
                        name="idnumber"
                        value=""
                      />
                    </div>
                    <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                      <TextInput
                        className="w-full lg:w-1/3"
                        label="PIN"
                        type="password"
                        name="pin"
                        value=""
                      />
                      <TextInput
                        className="w-full lg:w-1/3"
                        label="OTP"
                        name="otp"
                        value=""
                        type="password"
                      />
                      <TextInput
                        className="w-full lg:w-1/3"
                        label="Documentations"
                        name="documentations"
                        value=""
                      />
                    </div>
                    <div className="mt-14 w-full lg:w-2/3">
                      <h1 className="text-primary font-arial font-black uppercase">User Request:</h1>
                      <div>
                        <div className="py-4 border-b-2 border-b-light-gray">
                          <p className="font-black text-black">May 14, 2022 | Inability to Reset Wallet PIN</p>
                          <p>
                            Many desktop publishing packages and web page editors now use a different api.
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi tenetur natus est veritatis ab delectus quis similique, laboriosam ratione cum.
                          </p>
                        </div>
                        <div className="py-4 border-b-2 border-b-light-gray">
                          <p className="font-black text-black">May 14, 2022 | Inability to Reset Wallet PIN</p>
                          <p>
                            Many desktop publishing packages and web page editors now use a different api.
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi tenetur natus est veritatis ab delectus quis similique, laboriosam ratione cum.
                          </p>
                        </div>
                      </div>
                    </div>
                  */}
                    {/*
                    <div className="flex gap-6 mt-14 w-full lg:w-2/3">
                      <button
                        type="button"
                        className="bg-primary text-white font-black w-1/2 rounded-sm shadow-md py-2 hover:bg-opacity-70 focus:bg-opacity-70 disabled:bg-opacity-30 disabled:text-white"
                        disabled
                      >
                        Update Wallet
                      </button>
                      <button
                        type="button"
                        className="bg-primary text-white font-black w-1/2 rounded-sm shadow-md py-2 hover:bg-opacity-70 focus:bg-opacity-70 disabled:bg-opacity-30 disabled:text-white"
                        disabled
                      >
                        Update Wallet Details
                      </button>
                    </div>
                    */}
                  </form>
                </Tab.Panel>
                <Tab.Panel
                  className="px-5 py-4"
                  style={{ minHeight: 'calc(70vh - 60px)' }}
                >
                  <div className="flex flex-col gap-6 mb-5 lg:flex-row">
                    <TextInput
                      className="w-full lg:w-1/3"
                      label="Account Balance"
                      name="accountBalance"
                      value={singleWallet[0].accountBalance}
                      disabled
                    />
                    {/*
                    <TextInput
                      className="w-full lg:w-1/3"
                      label="Available Balance"
                      name="availableBalance"
                      value=""
                    />
                    <TextInput
                      className="w-full lg:w-1/3"
                      label="Account Limit"
                      name="accountLimit"
                      value=""
                    />
                    */}
                  </div>
                  <div className="mt-14 w-full">
                    <h1 className="text-primary font-arial font-black uppercase">
                      TRANSACTION HISTORY
                    </h1>
                    <div className="mt-4 no-scrollbar overflow-hidden overflow-x-auto">
                      <TableLight
                        headers={transactionHeader}
                        stats={{
                          count: singleWalletTransaction.length,
                          currentCount: singleWalletTransaction.length,
                          totalCount: singleWalletTransaction.length
                        }}
                        headerStyle="pl-0 border-b-primary border-b-4 border-t-light-gray border-t-2.5"
                      >
                        {singleWalletTransaction.length > 0 ? (
                          singleWalletTransaction.map((transaction, index) => (
                            <tr
                              className="text-text-color"
                              key={transaction.id}
                            >
                              <td className="border-b border-b-card-lightblue py-4 pr-4">
                                {(pageNumber - 1) * pageSize + index + 1}
                              </td>
                              <td className="border-b border-b-card-lightblue py-4 pr-4">
                                {formatDateTime(transaction.transactionDate)}
                              </td>
                              <td className="border-b border-b-card-lightblue py-4 pr-4">
                                {transaction.transactionType}
                              </td>
                              <td className="border-b border-b-card-lightblue py-4 pr-4">
                                {transaction.transactionReference}
                              </td>
                              <td className="border-b border-b-card-lightblue py-4 pr-4">
                                {transaction.narration}
                              </td>
                              <td
                                className={`border-b border-b-card-lightblue py-4 pr-4 font-black ${
                                  (transaction?.isInflow)
                                  && 'text-card-green'
                                } ${
                                  (transaction?.isOutflow)
                                  && 'text-card-red'
                                }`}
                              >
                                {formatCurrencyNoNaira(transaction.amount)}
                              </td>
                              <td
                                className={`border-b border-b-card-lightblue py-4 pr-4 font-black ${
                                  (transaction?.isInflow)
                                  && 'text-card-green'
                                } ${
                                  (transaction?.isOutflow)
                                  && 'text-card-red'
                                }`}
                              >
                                {formatCurrencyNoNaira(transaction.balance)}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="text-text-color">
                            <td
                              className="border-b border-b-card-lightblue py-4 pr-4"
                              colSpan={transactionHeader.length}
                            >
                              No transaction
                            </td>
                          </tr>
                        )}
                        {singleWalletTransaction.length > 0 && (
                          <tr className="text-text-color">
                            <td
                              colSpan={transactionHeader.length - 2}
                              className="p-3 pl-4"
                            >
                              Showing
                              {' '}
                              <span>{pageNumber}</span>
                              {' '}
                              to
                              {' '}
                              <span>{totalPage}</span>
                              {' '}
                              of
                              {' '}
                              <span>{totalCount}</span>
                              {' '}
                              entries
                            </td>
                            <td
                              colSpan={2}
                              className="border-b border-b-card-lightblue py-4 px-4"
                            >
                              <div className="flex justify-center gap-2">
                                <button
                                  type="button"
                                  className="px-4 py-2 bg-card-lightyellow border border-card-yellow rounded-sm text-black items-center"
                                  disabled={pageNumber === 1}
                                  onClick={loadPrev}
                                >
                                  <BsChevronDoubleLeft />
                                </button>
                                <button
                                  type="button"
                                  className="px-4 py-2 bg-card-lightblue border border-card-blue rounded-sm text-black"
                                  disabled={pageNumber === totalPage}
                                  onClick={loadNext}
                                >
                                  <BsChevronDoubleRight />
                                  {' '}
                                </button>
                              </div>
                            </td>
                          </tr>
                        )}
                      </TableLight>
                    </div>
                  </div>
                </Tab.Panel>
              </HorizontalTab>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}

export default WalletsManagement;

// TODO: Select Tab by default
