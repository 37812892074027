import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { AiOutlineUpload } from 'react-icons/ai';

function TextInput({
  className, value, name, readOnly, onBlur, disabled, onKeyPress, onKeyDown,
  minDate, maxDate, type, onChange, label, placeholder, error, enableDataList,
  dataLists, listName, enableAction, callAction, actionName
}) {
  const [inputType, setInputType] = useState(type);

  return (
    <div className={`${className} relative`}>
      <label htmlFor={name} className="block text-xs text-text-color">
        {label}
      </label>

      <div className={`flex flex-col gap-2 md:flex-row ${enableAction ? 'border-b border-border-color' : ''}`}>
        <input
          className={`${error ? 'error-field' : ''} text-text-color h-10 outline-none w-full border-b border-border-color ${enableAction ? 'w-10/12 truncate border-b-0' : 'w-full'}`}
          type={inputType}
          name={name}
          id={name}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          placeholder={placeholder}
          onBlur={onBlur}
          disabled={disabled}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          min={minDate}
          max={maxDate}
          list={listName}
        />

        {error ? <p className="error-msg">{error}</p> : null}

        {type === 'password' && (
          <span className="absolute right-4 bottom-2 text-gray-700">
            {inputType === 'password' ? (
              <BsEye className="w-5 h-auto cursor-pointer" onClick={() => setInputType('text')} />
            ) : (
              <BsEyeSlash className="w-5 h-auto cursor-pointer" onClick={() => setInputType('password')} />
            )}
          </span>
        )}

        {enableAction && (
          <div className="w-2/12 h-10">
            <button type="button" className="w-20 absolute bottom-2 px-3 py-1 bg-light-primary border border-primary rounded-sm text-text-color" onClick={callAction}>
              <div className="flex justify-center items-center gap-2">
                <AiOutlineUpload className="text-primary text-lg" />
                {' '}
                <span className="text-exs">{actionName}</span>
              </div>
            </button>
          </div>
        )}

        {(enableDataList && dataLists.length > 0) && (
          <datalist id={listName}>
            {dataLists.map((data) => (
              <option value={data.value}>{data.label}</option>
            ))}
          </datalist>
        )}
      </div>

    </div>
  );
}

TextInput.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  callAction: PropTypes.func,
  value: PropTypes.string || PropTypes.number,
  name: PropTypes.string,
  listName: PropTypes.string,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  minDate: PropTypes.number,
  maxDate: PropTypes.number,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  actionName: PropTypes.string,
  enableDataList: PropTypes.bool,
  enableAction: PropTypes.bool,
  dataLists: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  )
};

TextInput.defaultProps = {
  className: '',
  type: 'text',
  onChange: () => { },
  callAction: () => { },
  value: '',
  name: '',
  listName: '',
  readOnly: false,
  onBlur: () => { },
  disabled: false,
  onKeyPress: () => { },
  onKeyDown: () => { },
  minDate: undefined,
  maxDate: undefined,
  label: '',
  placeholder: '',
  actionName: '',
  error: undefined,
  enableDataList: false,
  enableAction: false,
  dataLists: []
};

export default TextInput;
