import React from 'react';
import PropTypes from 'prop-types';

function FormHeader({
  header
}) {
  return (
    <div className="flex justify-center text-base mb-10 text-title-color">
      <h1
        className="border-bg-yellow pb-1 border-b-4 font-extrabold"
      >
        {header}
      </h1>
    </div>
  );
}

FormHeader.propTypes = {
  header: PropTypes.string
};

FormHeader.defaultProps = {
  header: ''
};

export default FormHeader;
