import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@headlessui/react';
import { BsArrowRight } from 'react-icons/bs';

function CustomTabWithAction({
  children, menus, hideMenu, clickAction
}) {
  return (
    <Tab.Group vertical>
      {!hideMenu && (
        <div className="text-text-color inline-block md:hidden mb-4">
          Scroll for more
          <BsArrowRight className="ml-2 inline" />
        </div>
      )}
      <div className="flex flex-col gap-2 md:flex-row  overflow-hidden">
        <Tab.List className="flex flex-row items-start w-full gap-2 mb-4 md:mb-0 md:flex-col md:w-1/2 xl:w-1/3 no-scrollbar overflow-x-auto overflow-x-contain">
          {!hideMenu && menus.map((menu, index) => (
            <Tab index={index} as={Fragment}>
              {({ selected }) => (
                menu?.enableAction === true ? (
                  <button
                    type="button"
                    name={menu.title}
                    className={`${selected ? 'border border-primary' : 'border-main-bg'} border-2 rounded-sm text-text-color px-2 py-2 min-w-fit md:auto md:py-1`}
                    onClick={clickAction}
                  >
                    {menu.title}
                  </button>
                ) : (
                  <button
                    type="button"
                    className={`${selected ? 'border border-primary' : 'border-main-bg'} border-2 rounded-sm text-text-color px-2 py-2 min-w-fit md:auto md:py-1`}
                  >
                    {menu.title}
                  </button>
                )
              )}
            </Tab>
          ))}
          {hideMenu && (<Tab className="hidden" />)}
        </Tab.List>
        <Tab.Panels className="w-full m-0 md:w-1/2 xl:w-1/3">
          {children}
        </Tab.Panels>
        <div className="md:w-0 xl:w-1/3">{ }</div>
      </div>
    </Tab.Group>
  );
}

CustomTabWithAction.propTypes = {
  children: PropTypes.node,
  clickAction: PropTypes.func,
  menus: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, enableAction: PropTypes.bool })
  ),
  hideMenu: PropTypes.bool
};

CustomTabWithAction.defaultProps = {
  children: '',
  clickAction: () => { },
  menus: [{ title: '', enableAction: false }],
  hideMenu: false
};

export default CustomTabWithAction;
